import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {ISavingSchedule, ISchedule} from '@tehzor/tools/interfaces/schedules';

/**
 * Редактирует расписание
 *
 * @param scheduleId id расписания
 * @param fields данные расписания
 */
export const editScheduleRequest = async (scheduleId: string, fields: ISavingSchedule) => {
	const {intervals, ...filteredFields} = fields;
	const response = await httpRequests.withToken.put<ISchedule>(`schedules/${scheduleId}`, {
		...filteredFields,
		timeIntervals: fields.intervals
	});

	return response.data;
};
