import {ITreeDataItem} from '@tehzor/ui-components';

export const getAllParents = <T extends ITreeDataItem>(id: string, treeData: T[]): string[] => {
	const item = treeData.find(data => data.id === id);

	if (!item?.parentId) {
		return [];
	}
	return [item.parentId].concat(getAllParents(item.parentId, treeData));
};
