import {IBreadcrumb} from '@tehzor/ui-components/src/components/navigation/Breadcrumbs';
import {useMemo} from 'react';
import {useParams} from 'react-router';

export const schedulesPageUrl = '/manage/schedules';

export const useSchedulesPageBreadcrumbs = (): IBreadcrumb[] => {
	const {scheduleId} = useParams<{scheduleId?: string}>();
	return useMemo(() => {
		const items = [
			{
				label: 'Расписания',
				url: schedulesPageUrl,
				nonClickable: !scheduleId
			}
		];
		if (scheduleId) {
			items.push({
				label: 'Настроить расписание',
				url: `${schedulesPageUrl}/${scheduleId}`,
				nonClickable: true
			});
		}
		return items;
	}, [scheduleId]);
};
