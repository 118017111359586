export const isTimeValueAfterFrom = (timeValue: Date, from: Date) => {
	const referenceTime = new Date(
		from.getFullYear(),
		from.getMonth(),
		from.getDate(),
		timeValue.getHours(),
		timeValue.getMinutes()
	);

	return referenceTime.getTime() >= from.getTime();
};
