import {useCallback} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import styles from './RepeatPeriodsSelect.module.less';
import classNames from 'classnames';
import {getIntervalField} from '../../utils/intervals/getIntervalField';
import {IntervalFields} from '../../types/IntervalFields';
import {RepeatVariants} from '../../constants/RepeatVariants';
import {IScheduleForm} from '../../types/IScheduleForm';
import {DropDownSelect} from '@/shared/ui/DropDownSelect/DropDownSelect';
import {FieldLabel} from '@/shared/ui/FieldLabel/FieldLabel';

interface IRepeatPeriodsrops {
	index: number;
}

export const RepeatPeriodsSelect = ({index}: IRepeatPeriodsrops) => {
	const {setValue, control} = useFormContext<IScheduleForm>();
	const repeatTypeValue = useWatch({
		control,
		name: getIntervalField(index, IntervalFields.REPEAT_TYPE)
	});
	const changeViewType = useCallback(
		(value: string) => {
			const valueToSet = RepeatVariants.find(item => String(item.id) === value);

			if (valueToSet) {
				setValue(getIntervalField(index, IntervalFields.REPEAT_TYPE), valueToSet);
			}
		},
		[setValue, index]
	);
	return (
		<div>
			<FieldLabel
				className={styles.header}
				label="Повторять"
				required
			/>
			<DropDownSelect
				className={{
					button: styles.button,
					label: styles.label,
					wrap: styles.wrap,
					buttonOpen: styles.buttonOpen,
					menu: styles.menu,
					menuItem: styles.menuItem
				}}
				value={repeatTypeValue.name}
				label={repeatTypeValue.name}
				onChange={changeViewType}
				items={RepeatVariants}
				isLoading={false}
				icon={<i className={classNames('tz-mark-24', styles.menuItemIcon)} />}
				isCustomFilterButton
			/>
		</div>
	);
};
