import './ExpandButton.less';
import ButtonBase from '../../../../buttons/ButtonBase';
import classNames from 'classnames';
import {CSSProperties, MouseEvent, ReactNode} from 'react';
import {convertClassNames} from '../../../../../utils/convertClassNames';

const expandIcon = <i className="tz-simple-arrow-24" />;

interface IExpandButtonProps {
	className?:
		| string
		| {
				root?: string;
				rightIconClassName?: string;
				labelClassName?: string;
		  };
	style?: CSSProperties;
	label?: ReactNode;
	expanded?: boolean;
	icon?: ReactNode;
	onClick?: (event: MouseEvent) => void;
}

const ExpandButton = (props: IExpandButtonProps) => {
	const {className, style, label, expanded, onClick, icon} = props;
	const classes = convertClassNames(className);

	return (
		<ButtonBase
			className={classNames({'expandable-plate-expand-btn_expanded': expanded}, classes.root)}
			style={style}
			rightIcon={icon || expandIcon}
			label={label}
			classNamePrefix="expandable-plate-expand-btn"
			outerTagType="button"
			onClick={onClick}
			rightIconClassName={classes.rightIconClassName}
			labelClassName={classes.labelClassName}
		/>
	);
};

export default ExpandButton;
