import {useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {DatePickerResult, InlineDateRangePicker, LinkButton} from '@tehzor/ui-components';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useRouting} from '@src/components/MobileEntitiesFilters/utils/pagesRouting';
import {isLocalStateEqual} from '@src/components/MobileEntitiesFilters/utils/isLocalStateEqual';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useTranslation} from 'react-i18next';
import {TranslatedFilterPage} from '@src/components/TranslatedFilterPage';
import {useDateFnsLocale} from '@src/core/hooks/translations/useDateFnsLocales';
import styles from './IntervalFilterPage.module.less';

interface IIntervalFilterPageProps {
	intervalFrom: string;
	intervalTo: string;
}

export const IntervalFilterPage = ({intervalFrom, intervalTo}: IIntervalFilterPageProps) => {
	const {t} = useTranslation();
	const {goBack} = useChangePath();
	const {entity} = useStrictParams<{entity: string}>();

	const {state, change} = useEntitiesFiltersCtx<Record<string, Date | undefined>>();

	const fromValue = state[intervalFrom];
	const toValue = state[intervalTo];

	const [from, setFrom] = useState(fromValue);
	const [to, setTo] = useState(toValue);
	const location = useLocation();
	const {canGoBack} = location.state as {canGoBack?: boolean};

	const handleApply = () => {
		change({[intervalFrom]: from, [intervalTo]: to});
		goBack();
	};

	const handleChange = (f: Date | null, t: Date | null) => {
		if (f) {
			setFrom(new Date(f.getFullYear(), f.getMonth(), f.getDate()));
		}
		if (t) {
			setTo(new Date(t.getFullYear(), t.getMonth(), t.getDate(), 23, 59, 59, 999));
		}
	};

	const handleClear = () => {
		setTo(undefined);
		setFrom(undefined);
	};

	useAppHeader(
		{
			title: t('entitiesFilters.dateFilter.startDate.label'),
			showBackBtn: true,
			mobileRightButtons:
				from && to ? (
					<LinkButton
						label={t('entitiesFilters.cancelButton.label')}
						onClick={handleClear}
					/>
				) : null
		},
		[from, to]
	);

	useRouting(!canGoBack, 'all', entity);

	const dateFnsLocale = useDateFnsLocale();
	const datesOptions = {locale: dateFnsLocale};

	return (
		<TranslatedFilterPage
			applyDisabled={isLocalStateEqual(fromValue, from) && isLocalStateEqual(toValue, to)}
			onApplyClick={handleApply}
		>
			<InlineDateRangePicker
				valueFrom={from}
				valueTo={to}
				onChange={handleChange}
				datesOptions={datesOptions}
			/>
			<DatePickerResult
				className={styles.datePickerResult}
				value1={from}
				value2={to}
				placeholder1={t('entitiesFilters.dateFilter.startDate.label')}
				placeholder2={t('entitiesFilters.dateFilter.completionDate.label')}
				isRange
				dateFormat="dd.MM.yyyy"
			/>
		</TranslatedFilterPage>
	);
};
