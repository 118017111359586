import {Button} from '@tehzor/ui-components';
import styles from './ActionsButtons.module.less';

interface IActionsButtonsProps {
	handleSubmit: () => Promise<void>;
	handleReset: () => void;
	disabled?: boolean;
}
export const ActionsButtons = ({
	handleSubmit,
	handleReset,
	disabled = false
}: IActionsButtonsProps) => (
	<div className={styles.container}>
		<Button
			label="Отменить"
			type="cancel"
			disabled={disabled}
			onClick={handleReset}
		/>
		<Button
			type="accent-blue"
			label="Сохранить"
			onClick={handleSubmit}
			disabled={disabled}
		/>
	</div>
);
