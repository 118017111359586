import EntitiesFilters, {
	ObjectsFilter,
	CreationDateFilter,
	StatusesFilter,
	CreatedByFilter,
	ModifiedDateFilter
} from '@src/components/EntitiesFilters';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {IWorkAcceptancesFiltersState} from '@src/store/modules/settings/pages/workAcceptances/reducers/byPage';
import {useExtractWorkAcceptanceFrontTypesAsArray} from '@src/core/hooks/queries/workAcceptanceFrontTypes/hooks';
import {useExtractWorkAcceptanceTypesAsArray} from '@src/core/hooks/queries/workAcceptanceTypes/hooks';
import {useExtractWorkAcceptanceStatusesAsArray} from '@src/core/hooks/queries/workAcceptanceStatuses/hooks';
import {WorkAcceptancesSearch} from '@src/pages/WorkAcceptancesPage/components/WorkAcceptancesSearch';
import {ExpiredFilter} from '@src/components/EntitiesFilters/components/ExpiredFilter';
import {CategoriesSetFilter} from '@src/pages/ProblemsPage/components/filters/CategoriesSetFilter';
import {expiredFilterOptions} from '@src/pages/WorkAcceptancesPage/components/filters/constants/expiredFilterOptions';
import {useTranslation} from 'react-i18next';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {AcceptorsFilter} from './AcceptorsFilter';
import {useObjectChildrenIds} from '@src/core/hooks/queries/objects/hooks';
import {NestedObjectsFilter} from '@src/components/EntitiesFilters/components/NestedObjectsFilter';
import {SubmittersFilter} from '@src/pages/WorkAcceptancesPage/components/filters/SubmittersFilter';
import {AcceptancesIntervalFilter} from './AcceptanceIntervalFilter';

interface IWorkAcceptancesFiltersProps {
	objectId?: string;
}

export const WorkAcceptancesFilters = ({objectId = 'all'}: IWorkAcceptancesFiltersProps) => {
	const {state} = useEntitiesFiltersCtx<IWorkAcceptancesFiltersState>();
	const {data: allFrontTypes} = useExtractWorkAcceptanceFrontTypesAsArray();
	const {data: allTypes} = useExtractWorkAcceptanceTypesAsArray();
	const {data: allStatuses} = useExtractWorkAcceptanceStatusesAsArray();
	const {t} = useTranslation();
	const nestedObjects = useObjectChildrenIds(objectId);

	return (
		<EntitiesFilters
			objectId={objectId}
			entity="work-acceptances"
			isDescendants={!!nestedObjects?.length}
		>
			<WorkAcceptancesSearch />

			{objectId === 'all' && (
				<ObjectsFilter
					value={state?.objects}
					label={t('entitiesFilters.objectsFilter.label')}
				/>
			)}

			{!!nestedObjects?.length ? (
				<NestedObjectsFilter
					label={t('problemsPage.objectsFilter.label')}
					value={state?.objects}
					objectId={objectId}
					excludeDescendantsValue={state?.excludeDescendantsObjects}
				/>
			) : null}

			{allStatuses && (
				<StatusesFilter
					label={t('entitiesFilters.statusesFilter.workAcceptanceStatuses.label')}
					filterName="statuses"
					statuses={state?.statuses}
					allStatuses={allStatuses}
					translationDictionaryKey={dictionaryKeys.workAcceptanceStatuses}
				/>
			)}

			{allTypes && (
				<StatusesFilter
					label={t('entitiesFilters.statusesFilter.workAcceptanceTypes.label')}
					filterName="types"
					statuses={state?.types}
					allStatuses={allTypes}
					translationDictionaryKey={dictionaryKeys.workAcceptanceTypes}
				/>
			)}

			{allFrontTypes && (
				<StatusesFilter
					label={t('entitiesFilters.statusesFilter.workAcceptanceFrontTypes.label')}
					filterName="frontTypes"
					statuses={state?.frontTypes}
					allStatuses={allFrontTypes}
					translationDictionaryKey={dictionaryKeys.workAcceptanceFrontTypes}
				/>
			)}

			<AcceptancesIntervalFilter
				from={state?.acceptanceIntervalStartFrom}
				to={state?.acceptanceIntervalStartTo}
				label={t('entitiesFilters.dateFilter.startDate.label')}
				filterNameFrom="acceptanceIntervalStartFrom"
				filterNameTo="acceptanceIntervalStartTo"
			/>

			<AcceptancesIntervalFilter
				from={state?.acceptanceIntervalEndFrom}
				to={state?.acceptanceIntervalEndTo}
				label={t('entitiesFilters.dateFilter.endDate.label')}
				filterNameFrom="acceptanceIntervalEndFrom"
				filterNameTo="acceptanceIntervalEndTo"
			/>

			<ExpiredFilter
				value={state.expired}
				customExpiredOptions={expiredFilterOptions}
				label={t('expiredFilterValues.expired')}
			/>

			<AcceptorsFilter
				acceptors={state?.acceptors}
				objectId={objectId}
				label={t('entitiesFilters.acceptors.label')}
			/>

			<SubmittersFilter
				submitters={state?.submitters}
				objectId={objectId}
				label={t('entitiesFilters.submitters.label')}
			/>

			<CategoriesSetFilter
				objectsIds={objectId === 'all' ? [] : [objectId]}
				categories={state.categories}
			/>

			<CreatedByFilter
				objectId={objectId}
				createdBy={state?.createdBy}
				label={t('entitiesFilters.CreatedByFilter.label')}
			/>

			<CreationDateFilter
				from={state?.createdAtFrom}
				to={state?.createdAtTo}
				label={t('entitiesFilters.CreationDateFilter.label')}
			/>

			<ModifiedDateFilter
				from={state?.modifiedAtFrom}
				to={state?.modifiedAtTo}
				label={t('entitiesFilters.ModifiedDateFilter.label')}
			/>
		</EntitiesFilters>
	);
};
