import {useCallback, useState} from 'react';
import * as React from 'react';
import {CheckListHistoryDialog} from '../CheckListHistoryDialog';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';

export function useCheckListHistoryDialog(
	objectId: string,
	stage?: ObjectStageIds,
	checkListId?: string,
	spaceId?: string,
	workAcceptanceId?: string
): [React.ReactNode, () => void] {
	const [isOpen, setOpen] = useState<boolean>(false);

	const open = useCallback(() => {
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const dialog =
		// isOpen добавлено для исключения запроса до нажатия кнопки истории чек-листа (EX-126)
		checkListId && stage && isOpen ? (
			<CheckListHistoryDialog
				objectId={objectId}
				checkListId={checkListId}
				stage={stage}
				spaceId={spaceId}
				workAcceptanceId={workAcceptanceId}
				isOpen={isOpen}
				onClose={close}
			/>
		) : null;

	return [dialog, open];
}
