import {dateFormat} from '@tehzor/tools/utils/dateFormats';
import {DatePicker, TextFieldWithForwardedRef} from '@tehzor/ui-components';
import {IDatePickerTriggerProps} from '@tehzor/ui-components/src/components/dates/DatePicker/DatePicker';
import {format} from 'date-fns';
import {useController, useFormContext} from 'react-hook-form';
import {useDateFnsLocale} from '@src/core/hooks/translations/useDateFnsLocales';
import {editableScheduleFields} from '../constants/editableScheduleFields';
import {useUpdateEffect} from 'react-use';
import {useTranslation} from 'react-i18next';
import {IScheduleFieldProps} from '../types/IScheduleFieldProps';
import {IScheduleForm} from '../types/IScheduleForm';

export const DateRangeTo = ({onSetValue, field, className}: IScheduleFieldProps) => {
	const {t} = useTranslation();
	const {control, watch} = useFormContext<IScheduleForm>();
	const {
		field: {value: dateTo}
	} = useController({
		name: field,
		control
	});
	const dateFnsLocale = useDateFnsLocale();
	const datesOptions = {locale: dateFnsLocale};
	const dateFrom = watch(editableScheduleFields.DATE_RANGE_FROM) as Date;
	const disabledDate = (date: Date) => date < dateFrom || date < new Date();

	useUpdateEffect(() => {
		if (dateTo && dateTo < dateFrom) {
			onSetValue(field, undefined);
		}
	}, [dateFrom, dateTo]);

	const handleChange = (v: Date) => {
		onSetValue(field, v);
	};

	const handleClearButton = () => onSetValue(field, undefined);

	const getTrigger = (triggerProps: IDatePickerTriggerProps) => (
		<TextFieldWithForwardedRef
			elementType="div"
			className={className}
			value={triggerProps?.value !== undefined ? format(triggerProps.value, dateFormat) : ''}
			onClick={triggerProps?.toggle}
			ref={triggerProps?.ref}
			cleanable={!!dateTo}
			onClearClick={handleClearButton}
		/>
	);

	return (
		<DatePicker
			value={dateTo as Date}
			datesOptions={datesOptions}
			onChange={handleChange}
			disabledDate={disabledDate}
			trigger={getTrigger}
			selectCancelBtnLabel={t('cancelBtn.label')}
			selectApplyBtnLabel={t('applyBtn.label')}
			useApplyButton
		/>
	);
};
