import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {FixDateTag} from '@src/components/FixDateTag';
import {format} from 'date-fns';
import {DeadlineIntervalDesktop} from '@src/components/DeadlineIntervalDesktop';
import {compareFixDate} from '@tehzor/tools/utils/compareFixDate';

interface IWorkAcceptanceDeadlineProps {
	workAcceptance: IWorkAcceptance;
}

export const WorkAcceptanceDeadlineDesktop = ({workAcceptance}: IWorkAcceptanceDeadlineProps) => {
	const {actualAcceptanceDate, acceptanceIntervalStart, acceptanceIntervalEnd} = workAcceptance;
	if (!acceptanceIntervalStart || !acceptanceIntervalEnd) {
		return null;
	}
	const formattedDateStart = format(acceptanceIntervalStart, dateTimeCommaSeparatedFormat);
	const formattedDateEnd = format(acceptanceIntervalEnd, dateTimeCommaSeparatedFormat);

	return (
		<>
			<DeadlineIntervalDesktop
				dateStart={formattedDateStart}
				dateEnd={formattedDateEnd}
			/>
			{compareFixDate(actualAcceptanceDate, acceptanceIntervalEnd) &&
				acceptanceIntervalEnd && (
					<FixDateTag
						className="fix-date-view-field__tag"
						plannedFixDate={acceptanceIntervalEnd}
						actualFixDate={actualAcceptanceDate}
					/>
				)}
		</>
	);
};
