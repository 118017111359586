import {memo, useCallback, useMemo, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import {makeFilterLabel} from '@src/components/EntitiesFilters/utils/makeFilterLabel';
import {FilterButton, TreeSelect} from '@tehzor/ui-components';
import {useEntitiesFiltersCtx} from '../utils/entitiesFiltersCtx';
import SelectSearch, {
	treeFilter
} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {useTranslation} from 'react-i18next';
import {TranslatedSelectPopup} from '@src/components/TranslatedSelectPopup';
import {useExtractStructureTypesAsArray} from '@src/core/hooks/queries/structureTypes/hooks';
import {useTranslatedDictionaryArray} from '@src/core/hooks/translations/useTranslatedDictionaryArray';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useExtractSpaceTypesAsArray} from '@src/core/hooks/queries/spaceTypes/hooks';
import {makeSpacesAndStructuresTreeData} from '@src/utils/makeSpacesAndStructuresTreeData';
import {useExtractWorkAcceptanceFrontTypesById} from '@src/core/hooks/queries/workAcceptanceFrontTypes/hooks';
import {WorkAcceptanceFrontTypeId} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceFrontType';

export interface IObjectsFilterProps {
	filterName?: string;
	value?: string[];
	className?: string;
}

export const SpacesAndStructuresFilter = memo((props: IObjectsFilterProps) => {
	const {value, className, filterName = 'spacesAndStructures'} = props;
	const {t} = useTranslation();
	const {dispatch} = useEntitiesFiltersCtx();

	const {data: allFrontTypes} = useExtractWorkAcceptanceFrontTypesById();
	const {data: structureTypes} = useExtractStructureTypesAsArray();
	const translatedStructureTypes = useTranslatedDictionaryArray(
		dictionaryKeys.structureTypes,
		structureTypes
	);
	const {data: spaceTypes} = useExtractSpaceTypesAsArray();
	const translatedSpaceTypes = useTranslatedDictionaryArray(
		dictionaryKeys.spaceTypes,
		spaceTypes
	);

	const [selectedSpacesAndStructures, setSelectedSpacesAndStructures] = useState<
		string[] | undefined
	>(value);
	const [expandedSpacesAndStructures, setExpandedSpacesAndStructures] = useState<
		string[] | undefined
	>([]);
	const [search, setSearch] = useState('');

	const clearSearch = useCallback(() => setSearch(''), []);

	const spacesTreeData = useMemo(() => {
		if (!translatedSpaceTypes || !allFrontTypes) return [];

		return makeSpacesAndStructuresTreeData(
			translatedSpaceTypes,
			allFrontTypes[WorkAcceptanceFrontTypeId.SPACES]
		);
	}, [allFrontTypes, translatedSpaceTypes]);

	const structuresTreeData = useMemo(() => {
		if (!translatedStructureTypes || !allFrontTypes) return [];

		return makeSpacesAndStructuresTreeData(
			translatedStructureTypes,
			allFrontTypes[WorkAcceptanceFrontTypeId.STRUCTURES]
		);
	}, [allFrontTypes, translatedStructureTypes]);

	const treeData = useMemo(
		() => [...spacesTreeData, ...structuresTreeData],
		[spacesTreeData, structuresTreeData]
	);

	const {filteredData, expanded} = useMemo(
		() => treeFilter(treeData, 'content', search),
		[search, treeData]
	);

	useUpdateEffect(() => {
		setExpandedSpacesAndStructures(expanded?.map(item => item.id));
	}, [expanded]);

	const handleApply = useCallback(() => {
		const changes = {
			[filterName]: selectedSpacesAndStructures
		};
		dispatch(changes);
		clearSearch();
	}, [selectedSpacesAndStructures, dispatch, clearSearch, filterName]);

	const handleExpand = useCallback((v?: string[]) => {
		setExpandedSpacesAndStructures(v);
	}, []);

	const handleClear = useCallback(() => {
		setSelectedSpacesAndStructures([]);
		clearSearch();
	}, [clearSearch]);

	const handleFullClear = useCallback(() => {
		dispatch({[filterName]: undefined});
		setSelectedSpacesAndStructures([]);
		setExpandedSpacesAndStructures([]);
		clearSearch();
	}, [dispatch, clearSearch, filterName]);

	const handleCancel = useCallback(() => {
		setSelectedSpacesAndStructures(value);
		clearSearch();
	}, [value, clearSearch]);

	const handleSelectAll = useCallback(() => {
		clearSearch();
		if (treeData?.length) {
			setSelectedSpacesAndStructures([...treeData.map(item => item.id)]);
		}
	}, [treeData, clearSearch]);

	useUpdateEffect(() => {
		setExpandedSpacesAndStructures([]);
		setSelectedSpacesAndStructures(value);
	}, [value]);

	return (
		<TranslatedSelectPopup
			onCancel={handleCancel}
			onApply={handleApply}
			onClear={handleClear}
			className={className}
			clearButton={!!selectedSpacesAndStructures?.length}
			footer
			count={Number(selectedSpacesAndStructures?.length)}
			onSelectAll={handleSelectAll}
			search={
				<SelectSearch
					value={search}
					placeholder={t('fullTextSearch.placeholder.desktop')}
					onChange={setSearch}
					type="popup"
				/>
			}
			trigger={
				<FilterButton
					className="entities-filters__item"
					label={makeFilterLabel(
						t('checkListsPage.filters.spacesAndStructuresType.label'),
						value,
						treeData
					)}
					active={!!value?.length}
					onClear={handleFullClear}
				/>
			}
		>
			<TreeSelect
				data={filteredData}
				multiple
				value={selectedSpacesAndStructures}
				onChange={setSelectedSpacesAndStructures}
				expandedValue={expandedSpacesAndStructures}
				onExpand={handleExpand}
			/>
		</TranslatedSelectPopup>
	);
});
