import {AcceptorsFilterPage} from '@src/pages/filters/AcceptorsFilterPage';
import CategoriesSetFilterPage from '@src/pages/filters/CategoriesSetFilterPage';
import CreatedByFilterPage from '@src/pages/filters/CreatedByFilterPage';
import CreationDateFilterPage from '@src/pages/filters/CreationDateFilterPage';
import {ObjectsFilterRoute} from '@src/pages/filters/ObjectsFilterPage';
import {WorkAcceptanceFrontTypeFilterPage} from '@src/pages/filters/WorkAcceptanceFrontTypeFilterPage';
import {WorkAcceptanceStatusesFilterPage} from '@src/pages/filters/WorkAcceptanceStatusesFilterPage';
import {WorkAcceptanceTypeFilterPage} from '@src/pages/filters/WorkAcceptanceTypeFilterPage';
import {WorkAcceptancesFiltersPage} from '@src/pages/filters/WorkAcceptancesFiltersPage/WorkAcceptancesFiltersPage';
import {SubmittersFilterPage} from '@src/pages/filters/SubmittersFilterPage';
import {ModifiedDateFilterPage} from '@src/pages/filters/ModifiedDateFilterPage';
import {AcceptancesIntervalStartFilterPage} from '@src/pages/filters/AcceptancesIntervalStartFilterPage';
import {AcceptancesIntervalEndFilterPage} from '@src/pages/filters/AcceptancesIntervalEndFilterPage';

export const workAcceptancesFilterRoutes = [
	{path: 'objects', element: <ObjectsFilterRoute />},
	{
		path: 'statuses',
		element: <WorkAcceptanceStatusesFilterPage />
	},
	{
		path: 'fronttypes',
		element: <WorkAcceptanceFrontTypeFilterPage />
	},
	{
		path: 'types',
		element: <WorkAcceptanceTypeFilterPage />
	},
	{
		path: 'creationdate',
		element: <CreationDateFilterPage />
	},
	{
		path: 'intervalstart',
		element: <AcceptancesIntervalStartFilterPage />
	},

	{
		path: 'intervalend',
		element: <AcceptancesIntervalEndFilterPage />
	},
	{
		path: 'acceptors',
		element: <AcceptorsFilterPage />
	},
	{
		path: 'submitters',
		element: <SubmittersFilterPage />
	},
	{
		path: 'createdby',
		element: <CreatedByFilterPage />
	},
	{
		path: 'categories',
		element: <CategoriesSetFilterPage />
	},
	{
		path: 'modifieddate',
		element: <ModifiedDateFilterPage />
	},
	{
		path: '',
		element: <WorkAcceptancesFiltersPage />
	}
];
