import {WorkAcceptanceFrontItem} from '@src/components/WorkAcceptanceFrontItem/WorkAcceptanceFrontItem';
import {Category} from '@src/components/viewFields/Category';
import Description from '@src/components/viewFields/Description';
import {useWorkAcceptancesPermissions} from '@src/core/hooks/permissions/useWorkAcceptancesPermissions';
import {useExtractWorkAcceptanceFrontTypesById} from '@src/core/hooks/queries/workAcceptanceFrontTypes/hooks';
import {useExtractWorkAcceptanceStatuses} from '@src/core/hooks/queries/workAcceptanceStatuses/hooks';
import {useExtractWorkAcceptanceTypesById} from '@src/core/hooks/queries/workAcceptanceTypes/hooks';
import {useTranslatedDictionary} from '@src/core/hooks/translations/useTranslatedDictionary';
import {useTranslatedDictionaryById} from '@src/core/hooks/translations/useTranslatedDictionaryById';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {IEntitySettings} from '@src/core/hooks/settings/useEntitySettings';
import {CustomField} from '@src/components/CustomField';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {editWorkAcceptanceStatus} from '@src/store/modules/entities/workAcceptance/actions';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {WorkAcceptanceFrontTypeId} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceFrontType';
import {WorkAcceptanceStatusIds} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceStatus';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {EditorInfo, EntityGrid, EntityGridItem, Plate, StatusSelect} from '@tehzor/ui-components';
import {format} from 'date-fns';
import {ru} from 'date-fns/locale';
import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {WorkAcceptanceAcceptors} from './WorkAcceptanceAcceptors';
import {WorkAcceptanceSubmitters} from './WorkAcceptanceSubmitters';
import {DeadlineIntervalDesktop} from '@src/components/DeadlineIntervalDesktop';
import {compareFixDate} from '@tehzor/tools/utils/compareFixDate';
import {FixDateTag} from '@src/components/FixDateTag';

const locationIcon = <i className="tz-location-20" />;
const calendarIcon = <i className="tz-calendar-20" />;
const typeIcon = <i className="tz-category-20" />;
const statusIcon = <i className="tz-status-20" />;
const contractorIcon = <i className="tz-contractor-20" />;

interface IDesktopInfoProps {
	objectId: string;
	workAcceptance: IWorkAcceptance;
	settings?: IEntitySettings;
}

export const DesktopDescription = ({objectId, workAcceptance, settings}: IDesktopInfoProps) => {
	const {
		acceptanceIntervalStart,
		acceptanceIntervalEnd,
		actualAcceptanceDate,
		type,
		frontType,
		structures,
		spaces,
		createdAt,
		modifiedAt,
		description,
		modifiedBy,
		createdBy,
		customFields,
		categoryId,
		contractorLegalEntity,
		acceptorsActiveGroup,
		submitters,
		status,
		submittersActiveGroup,
		acceptors
	} = workAcceptance;
	const {data: statuses} = useExtractWorkAcceptanceStatuses();
	const translatedStatuses = useTranslatedDictionary('workAcceptanceStatuses', statuses);
	const {t} = useTranslation();
	const custom = settings?.custom;
	const dispatch = useAppDispatch();
	const handleChange = useCallback(
		async (v: WorkAcceptanceStatusIds) => {
			if (workAcceptance) {
				await dispatch(editWorkAcceptanceStatus(objectId, workAcceptance.id, v));
			}
		},
		[objectId, workAcceptance, dispatch]
	);

	const formattedDateStart = acceptanceIntervalStart
		? format(acceptanceIntervalStart, dateTimeCommaSeparatedFormat, {locale: ru})
		: undefined;
	const formattedDateEnd = acceptanceIntervalEnd
		? format(acceptanceIntervalEnd, dateTimeCommaSeparatedFormat, {locale: ru})
		: undefined;

	const permissions = useWorkAcceptancesPermissions(objectId);
	const availableStatuses = permissions.canEditStatusRestrictions?.availableStatuses;

	const {data: typesMap} = useExtractWorkAcceptanceTypesById();

	const workAcceptanceType = type && typesMap && typesMap[type];
	const translatedAcceptanceType = useTranslatedDictionaryById(
		dictionaryKeys.workAcceptanceTypes,
		workAcceptanceType?.id
	);

	const {data: frontTypesMap} = useExtractWorkAcceptanceFrontTypesById();
	const workAcceptanceFrontType = frontType && frontTypesMap && frontTypesMap[frontType];
	const translatedAcceptanceFrontType = useTranslatedDictionaryById(
		dictionaryKeys.workAcceptanceFrontTypes,
		workAcceptanceFrontType?.id
	);
	const workAcceptanceFront =
		frontType === WorkAcceptanceFrontTypeId.STRUCTURES
			? structures
			: frontType === WorkAcceptanceFrontTypeId.SPACES
			? spaces
			: undefined;
	return (
		<Plate
			className={{
				content: 'work-acceptance-page__info-plate',
				root: 'work-acceptance-page__info-plate-container'
			}}
		>
			<EntityGrid withBorders>
				<EntityGridItem
					label={t('workAcceptancePage.description.status.label')}
					icon={statusIcon}
					fullRow
				>
					{translatedStatuses && (
						<StatusSelect
							className="work-acceptance-page__d-entities-status"
							statuses={translatedStatuses}
							availableStatuses={availableStatuses || translatedStatuses.allIds}
							disabled={!permissions.canEditStatus}
							value={status}
							onChange={handleChange}
						/>
					)}
				</EntityGridItem>

				{translatedAcceptanceType && (
					<EntityGridItem
						icon={typeIcon}
						label={t('workAcceptancePage.description.acceptanceType.label')}
						fullRow
					>
						<div>{translatedAcceptanceType}</div>
					</EntityGridItem>
				)}

				{description && <Description value={description} />}

				<EntityGridItem
					icon={calendarIcon}
					label={t('workAcceptancePage.description.acceptanceDeadline.label')}
					fullRow
				>
					{acceptanceIntervalStart && acceptanceIntervalEnd && (
						<>
							<DeadlineIntervalDesktop
								dateStart={formattedDateStart}
								dateEnd={formattedDateEnd}
							/>
							{compareFixDate(actualAcceptanceDate, acceptanceIntervalEnd) && (
								<FixDateTag
									className="fix-date-view-field__tag"
									plannedFixDate={acceptanceIntervalEnd}
									actualFixDate={actualAcceptanceDate}
								/>
							)}
						</>
					)}
				</EntityGridItem>

				{categoryId && (
					<Category
						categoryId={categoryId}
						fullRow
						label={t('workAcceptancePage.description.category.label')}
					/>
				)}

				<EntityGridItem
					icon={contractorIcon}
					label={t('workAcceptancePage.description.contractor.label')}
					fullRow
				>
					{contractorLegalEntity?.shortName}
				</EntityGridItem>

				{(acceptorsActiveGroup || acceptors?.length) && (
					<WorkAcceptanceAcceptors workAcceptance={workAcceptance} />
				)}

				{(submittersActiveGroup || submitters?.length) && (
					<WorkAcceptanceSubmitters workAcceptance={workAcceptance} />
				)}

				{translatedAcceptanceFrontType && (
					<EntityGridItem
						icon={locationIcon}
						label={t('workAcceptancePage.description.worksFrontType.label')}
						fullRow
					>
						<div>{translatedAcceptanceFrontType}</div>
					</EntityGridItem>
				)}

				<EntityGridItem
					icon={locationIcon}
					label={t('workAcceptancePage.description.workScope.label')}
					fullRow
				>
					{workAcceptanceFront?.map(item => (
						<WorkAcceptanceFrontItem
							item={item}
							objectId={objectId}
							frontType={frontType}
							key={item.id}
						/>
					))}
				</EntityGridItem>

				{custom &&
					Object.values(custom).map(customSetting => (
						<CustomField
							key={customSetting.fieldId}
							fields={customFields}
							setting={customSetting}
						/>
					))}

				<EditorInfo
					icon={calendarIcon}
					label={t('workAcceptancePage.description.created.label')}
					date={createdAt}
					user={createdBy}
					fullRow
				/>

				{createdAt !== modifiedAt && (
					<EditorInfo
						icon={calendarIcon}
						label={t('workAcceptancePage.description.updated.label')}
						date={modifiedAt}
						user={modifiedBy}
						fullRow
					/>
				)}
			</EntityGrid>
		</Plate>
	);
};
