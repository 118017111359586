import styles from './IntervalsDateRange.module.less';
import classNames from 'classnames';
import {FieldLabel} from '@/shared/ui/FieldLabel/FieldLabel';
import {IDateRangeKeys} from '../../types/IntervalFieldsKeys';
import {IntervalDateRangeFrom} from '../../ui/IntervalDateRangeFrom';
import {IntervalDateRangeTo} from '../../ui/IntervalDateRangeTo';
import {useFormContext, useWatch} from 'react-hook-form';
import {IScheduleForm} from '../../types/IScheduleForm';
import {editableScheduleFields} from '../../constants/editableScheduleFields';

const dashIcon = (element?: JSX.Element) =>
	element ?? <i className={classNames('tz-minus', styles.icon)} />;

interface IIntervalsDateRange {
	fieldDateFrom: IDateRangeKeys;
	fieldDateTo: IDateRangeKeys;
	className?: string;
	dashIconElement?: JSX.Element;
}

export const IntervalsDateRange = ({
	fieldDateFrom,
	fieldDateTo,
	className,
	dashIconElement
}: IIntervalsDateRange) => {
	const {control} = useFormContext<IScheduleForm>();

	const dateRangeTo = useWatch({
		control,
		name: editableScheduleFields.DATE_RANGE_TO
	});

	const generalDateTo = dateRangeTo ? new Date(dateRangeTo) : undefined;
	return (
		<div className={className}>
			<FieldLabel label="Диапазон дат" />
			<div className={styles.container}>
				<IntervalDateRangeFrom
					field={fieldDateFrom}
					className={styles.datePicker}
					generalDateTo={generalDateTo}
				/>
				{dashIcon(dashIconElement)}
				<IntervalDateRangeTo
					field={fieldDateTo}
					className={styles.datePicker}
					generalDateTo={generalDateTo}
				/>
			</div>
		</div>
	);
};
