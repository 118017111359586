import {ExternalObjectRoot} from '@src/containers/ExternalObjectRoot';
import {PrivateRoute} from '@src/containers/PrivateRoute';
import {ObjectsPage, SubObjectsPage} from '@src/pages/ObjectsPage';
import {categoriesSetsFilterRoutes} from './categoriesSetsFilterRoutes';
import {ObjectPage} from '@src/pages/ObjectPage';
import {objectSpacesRoutes} from './objectSpacesRoutes';
import {OwnerAcceptancePageWrap} from '@src/pages/OwnerAcceptancePage';
import {OwnerAcceptancesPageWrap} from '@src/pages/OwnerAcceptancesPage/OwnerAcceptancesPageWrap';
import {StructurePageWrap as StructurePage} from '@src/pages/StructurePage';
import {WarrantyClaimPageWrap} from '@src/pages/WarrantyClaimPage';
import {WarrantyClaimsPage} from '@src/pages/WarrantyClaimsPage';
import CategoriesSetsFiltersRoutingPage from '@src/pages/filters/CategoriesSetsFiltersRoutingPage/CategoriesSetsFiltersRoutingPage';
import {ObjectsFiltersRoutingPage} from '@src/pages/filters/ObjectsFiltersRoutingPage';
import {OwnerAcceptancesFiltersRoutingPage} from '@src/pages/filters/OwnerAcceptancesFiltersRoutingPage/OwnerAcceptancesFiltersRoutingPage';
import WarrantyClaimsFiltersRoutingPage from '@src/pages/filters/WarrantyClaimsFiltersRoutingPage';
import CategoriesSetPage from '@src/pages/manage/CategoriesSetPage/CategoriesSetPage';
import {RouteObject} from 'react-router-dom';
import {contractsRoutes} from './contractsRoutes';
import {objectChecksRoutes} from './objectChecksRoutes';
import {objectPresaleChecksRoutes} from './objectPresaleChecksRoutes';
import {objectInspectionsRoutes} from './objectInspectionsRoutes';
import {objectInternalAcceptancesRoutes} from './objectInternalAcceptancesRoutes';
import {objectPlansRoutes} from './objectPlansRoutes';
import {objectProblemsRoutes} from './objectProblemsRoutes';
import {objectsFilterRoutes} from './objectsFilterRoutes';
import {ownerAcceptancesFilterRoutes} from './ownerAcceptancesFilterRoutes';
import {structuresFilterRoutes} from './structuresFilterRoutes';
import {warrantyClaimsFilterRoutes} from './warrantyClaimsFilterRoutes';
import {CategoriesSetsPage} from '@src/pages/manage/CategoriesSetsPage';
import CategoriesSetAddingPage from '@src/pages/manage/CategoriesSetPage/CategoriesSetAddingPage';
import {objectWorkAcceptancesRoutes} from './objectWorkAcceptancesRoutes';
import {StructuresPage} from '@/pages/StructuresPage';
import {StructuresFiltersRoutingPage} from '@/pages/StructuresFiltersRoutingPage';
import {objectScheduledPlansRoutes} from './objectScheduledPlansRoutes';

export const objectsRoutes: RouteObject[] = [
	{
		index: true,
		element: <PrivateRoute element={<ObjectsPage />} />
	},
	{
		path: 'filters',
		element: <PrivateRoute element={<ObjectsFiltersRoutingPage />} />,
		children: objectsFilterRoutes
	},
	{
		path: ':objectId',
		element: <PrivateRoute element={<SubObjectsPage />} />
	},
	{
		path: ':objectId/view',
		element: <PrivateRoute element={<ObjectPage />} />
	},
	{
		path: ':objectId/problems',
		children: objectProblemsRoutes
	},
	{
		path: ':objectId/inspections',
		children: objectInspectionsRoutes
	},
	{
		path: ':objectId/checks',
		children: objectChecksRoutes
	},
	{
		path: ':objectId/presale-checks',
		children: objectPresaleChecksRoutes
	},
	{
		path: ':objectId/internal-acceptances',
		children: objectInternalAcceptancesRoutes
	},
	{
		path: ':objectId/plans',
		children: objectPlansRoutes
	},
	{
		path: ':objectId/spaces',
		children: objectSpacesRoutes
	},
	{
		path: ':objectId/contracts',
		children: contractsRoutes
	},
	{
		path: ':objectId/work-acceptances',
		children: objectWorkAcceptancesRoutes
	},
	// теперь уже вот тут я сдался
	{
		path: ':objectId/owner-acceptances',
		element: <PrivateRoute element={<OwnerAcceptancesPageWrap />} />
	},
	{
		path: ':objectId/owner-acceptances/:ownerAcceptanceId',
		element: <PrivateRoute element={<OwnerAcceptancePageWrap />} />
	},
	{
		path: ':objectId/owner-acceptances/filters',
		element: <PrivateRoute element={<OwnerAcceptancesFiltersRoutingPage />} />,
		children: ownerAcceptancesFilterRoutes
	},
	{
		path: ':objectId/warranty-claims',
		element: <PrivateRoute element={<WarrantyClaimsPage />} />
	},
	{
		path: ':objectId/warranty-claims/:warrantyClaimId',
		element: <PrivateRoute element={<WarrantyClaimPageWrap />} />
	},
	{
		path: ':objectId/warranty-claims/filters',
		element: <PrivateRoute element={<WarrantyClaimsFiltersRoutingPage />} />,
		children: warrantyClaimsFilterRoutes
	},
	{
		path: ':objectId/structures',
		element: <PrivateRoute element={<StructuresPage />} />
	},
	{
		path: ':objectId/structures/:structureId/*',
		element: <PrivateRoute element={<StructurePage />} />
	},
	{
		path: ':objectId/structures/filters',
		element: <PrivateRoute element={<StructuresFiltersRoutingPage />} />,
		children: structuresFilterRoutes
	},
	{
		path: 'external/:externalId',
		element: <PrivateRoute element={<ExternalObjectRoot />} />
	},
	{
		path: ':objectId/categories-sets',
		element: <PrivateRoute element={<CategoriesSetsPage />} />
	},
	{
		path: ':objectId/categories-sets/add',
		element: <PrivateRoute element={<CategoriesSetAddingPage />} />
	},
	{
		path: ':objectId/categories-sets/:categoriesSetId',
		element: <PrivateRoute element={<CategoriesSetPage />} />
	},
	{
		path: ':objectId/categories-sets/filters',
		element: <PrivateRoute element={<CategoriesSetsFiltersRoutingPage />} />,
		children: categoriesSetsFilterRoutes
	},
	{
		path: ':objectId/scheduled-plans',
		children: objectScheduledPlansRoutes
	}
];
