import {IMobileFilterItem} from '@tehzor/ui-components/src/components/filters/MobileFilter';
import {checkIsIntersect} from './checkIsIntersect';
import {IIntervalFieldData} from '../../types/IIntervalFieldData';

interface Accumulator {
	otherIntervals: IIntervalFieldData[];
	currentInterval?: IIntervalFieldData;
}

export const getDisableDays = (
	days: IMobileFilterItem[],
	allIntervals: IIntervalFieldData[],
	currentIntervalId: string
) => {
	const {otherIntervals, currentInterval} = allIntervals.reduce<Accumulator>(
		(acc, item) => {
			if (item.id === currentIntervalId) {
				acc.currentInterval = item;
			} else {
				acc.otherIntervals.push(item);
			}
			return acc;
		},
		{otherIntervals: []}
	);

	if (!currentInterval) {
		return [];
	}

	const disabledDaysIds = otherIntervals.map(item => {
		const isIntersect = checkIsIntersect(currentInterval, item);
		if (isIntersect && item.selectedDays.length) {
			return item.selectedDays;
		}
		return [];
	});

	const unionArray = [...new Set(disabledDaysIds.flat())];
	const disabledDays = days
		.filter(item => unionArray.includes(item.id))
		.map(item => ({...item, isDisable: true}));

	return disabledDays;
};
