import {FilterItems} from '@/shared/ui/FilterItems/FilterItems';
import {useController, useFormContext, useWatch} from 'react-hook-form';
import {useCallback} from 'react';
import styles from './SelectedDays.module.less';
import {getIntervalField} from '../../utils/intervals/getIntervalField';
import {IntervalFields} from '../../types/IntervalFields';
import {ErrorMessages} from '../../constants/ErrorMessages';
import {WeekDays} from '../../constants/WeekDays';
import {MonthDays} from '../../constants/MonthDays';
import {getDisableDays} from '../../utils/intervals/getDisableDays';
import {FrequencyIds} from '@tehzor/tools/interfaces/schedules';
import {IScheduleForm} from '../../types/IScheduleForm';

interface ISelectedDaysProps {
	index: number;
}

export const SelectedDays = ({index}: ISelectedDaysProps) => {
	const {control, setValue} = useFormContext<IScheduleForm>();
	const {
		fieldState: {error}
	} = useController({
		name: getIntervalField(index, IntervalFields.SELECTED_DAYS),
		control,
		rules: {
			required: ErrorMessages.requiredFieldSelectedDays
		}
	});
	const repeatTypeValue = useWatch({
		control,
		name: getIntervalField(index, IntervalFields.REPEAT_TYPE)
	});
	const allIntervals = useWatch({control, name: 'intervals'});
	const {selectedDays, id: currentIntervalId} = useWatch({control, name: `intervals.${index}`});

	const days = repeatTypeValue.id === FrequencyIds.EVERY_WEEK ? WeekDays : MonthDays;

	const disableDays = getDisableDays(days, allIntervals || [], currentIntervalId);

	const daysWithStatus = days.map(day => ({
		...day,
		isDisable: disableDays.some(disabledDay => disabledDay.id === day.id)
	}));

	const handleChangePeriod = useCallback(
		(newSelected: string[] | undefined) => {
			setValue(getIntervalField(index, IntervalFields.SELECTED_DAYS), newSelected || []);
		},
		[index, setValue]
	);
	return (
		<>
			<FilterItems
				className={{
					btnWrap: styles.buttonsWrap,
					label: styles.label,
					labelWrap: styles.labelWrap,
					button: styles.buttonDisable,
					cancelAllBtn: styles.cancelAllBtn
				}}
				label={repeatTypeValue.id === FrequencyIds.EVERY_WEEK ? 'Дни недели' : 'Дни месяца'}
				elements={daysWithStatus}
				selected={selectedDays}
				onChange={handleChangePeriod}
				selectAllBtnLabel="Выбрать все"
				cancelAllBtnLabel={selectedDays.length ? 'Сбросить' : undefined}
				noOptions={false}
				noSorted
			/>
			<span className={styles.errorMessage}>{!selectedDays.length && error?.message}</span>
		</>
	);
};
