export enum HistoryTypeId {
	STATUS = 'status',
	PLANNED_FIX_DATE = 'plannedFixDate',
	CATEGORY = 'category',
	LOCATION = 'location',
	REASON = 'reason',
	DESCRIPTION = 'description',
	PRESCRIPTION = 'prescription',
	ATTACHMENTS = 'attachments',
	RESP_USERS = 'respUsers',
	CRITICAL = 'critical',
	COMMENT = 'comment',
	TAG = 'tag',
	MARKER_COMMENT = 'markerComment',
	SPACES = 'spaces',
	DATE_INTERVAL = 'dateInterval'
}
