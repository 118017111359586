import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {useTranslation} from 'react-i18next';
import {IConfirmDialogProps} from '@tehzor/ui-components';

interface IUseTranslatedConfirmDialogProps {
	title?: string;
	message?: string;
	props?: Omit<IConfirmDialogProps, 'title' | 'message' | 'description'>;
	description?: string;
}

export const useTranslatedConfirmDialog = (options?: IUseTranslatedConfirmDialogProps) => {
	const {title, message, props, description} = options ?? {};
	const {t} = useTranslation();
	return useConfirmDialog(
		title ?? t('useConfirmDialog.close.title'),
		message ?? t('useConfirmDialog.close.message'),
		{
			acceptBtnLabel: t('useConfirmDialog.acceptBtnLabel'),
			rejectBtnLabel: t('useConfirmDialog.rejectBtnLabel'),
			acceptBtnProps: {type: 'accent-red'},
			...props
		},
		description
	);
};
