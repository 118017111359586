import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {useEditableResultWorkAcceptanceDialog} from '@src/components/EditableResultWorkAcceptanceDialog/hooks/useEditableResultWorkAcceptanceDialog';
import {TranslatedLocationSelect} from '@src/components/TranslatedLocationSelect';
import {Category} from '@src/components/viewFields/Category';
import Description from '@src/components/viewFields/Description';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useWorkAcceptancesPermissions} from '@src/core/hooks/permissions/useWorkAcceptancesPermissions';
import {usePlansAsArray} from '@src/core/hooks/queries/plans/hooks';
import {useExtractSpaceTypesById} from '@src/core/hooks/queries/spaceTypes/hooks';
import {makeUnitStr} from '@src/core/hooks/queries/units/hook';
import {useExtractWorkAcceptanceStatuses} from '@src/core/hooks/queries/workAcceptanceStatuses/hooks';
import {useExtractWorkAcceptanceTypesById} from '@src/core/hooks/queries/workAcceptanceTypes/hooks';
import {useTranslatedDictionary} from '@src/core/hooks/translations/useTranslatedDictionary';
import {useTranslatedDictionaryById} from '@src/core/hooks/translations/useTranslatedDictionaryById';
import {useTranslatedDictionaryMap} from '@src/core/hooks/translations/useTranslatedDictionaryMap';
import {useTranslatedUnitsArray} from '@src/core/hooks/translations/useTranslatedUnitsArray';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {MobileInfo} from '@src/pages/WorkAcceptancePage/components/info/Info.mobile';
import {WorkAcptAttachments} from '@src/pages/WorkAcceptancePage/components/workAcptAttachments/WorkAcptAttachments';
import {editWorkAcceptanceStatus} from '@src/store/modules/entities/workAcceptance/actions';
import {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {WorkAcceptanceFrontTypeId} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceFrontType';
import {WorkAcceptanceStatusIds} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceStatus';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {findPermission} from '@tehzor/tools/utils/findPermission';
import {formSpaceTitle} from '@tehzor/tools/utils/formSpaceTitle';
import {formSpaceLink, formStructureLink} from '@tehzor/tools/utils/links';
import {
	EditorInfo,
	EntityGrid,
	EntityGridItem,
	LinkButton,
	Plate,
	StatusSelect
} from '@tehzor/ui-components';
import {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {IEntitySettings} from '@src/core/hooks/settings/useEntitySettings';
import {CustomField} from '@src/components/CustomField';
import {MobileControls} from '../controls/Controls.mobile';
import {WorkAcceptanceAcceptors} from './WorkAcceptanceAcceptors';
import {WorkAcceptanceTask} from '../tasks/WorkAcceptanceTask';
import {WorkAcceptanceSubmitters} from './WorkAcceptanceSubmitters';
import {DeadlineIntervalMobile} from '@src/components/DeadlineIntervalMobile';
import {format} from 'date-fns';
import {ru} from 'date-fns/locale';
import {compareFixDate} from '@tehzor/tools/utils/compareFixDate';
import {FixDateTag} from '@src/components/FixDateTag';

const locationIcon = <i className="tz-location-20" />;
const calendarIcon = <i className="tz-calendar-20" />;
const statusIcon = <i className="tz-status-20" />;
const typeIcon = <i className="tz-category-20" />;
const structureIcon = <i className="tz-structure" />;
const spaceIcon = <i className="tz-space-16" />;

interface IMobileInfoProps {
	objectId: string;
	workAcceptance: IWorkAcceptance;
	checkLists: ICheckList[];
	settings?: IEntitySettings;
}

export const MobileDescription = ({
	objectId,
	workAcceptance,
	checkLists,
	settings
}: IMobileInfoProps) => {
	const {
		id: workAcceptanceId,
		objectId: workAcceptanceObjectId,
		stage,
		frontType,
		number,
		status,
		type,
		description,
		categoryId,
		acceptorsActiveGroup,
		acceptors,
		submittersActiveGroup,
		submitters,
		customFields,
		attachments,
		createdAt,
		modifiedAt,
		createdBy,
		modifiedBy,
		acceptanceIntervalStart,
		acceptanceIntervalEnd,
		planPhysicalWorkScope,
		physicalWorkScope,
		structures,
		spaces,
		actualAcceptanceDate
	} = workAcceptance;
	const {pushPath} = useChangePath();
	const {t} = useTranslation();
	const custom = settings?.custom;
	const {data: statuses} = useExtractWorkAcceptanceStatuses();
	const translatedStatuses = useTranslatedDictionary('workAcceptanceStatuses', statuses);
	const dispatch = useAppDispatch();
	const permissions = useWorkAcceptancesPermissions(objectId, workAcceptance);
	const {data: plans} = usePlansAsArray(objectId);

	const [resultWorkAcceptanceDialog, openEditResultDialog] =
		useEditableResultWorkAcceptanceDialog(workAcceptanceObjectId, stage, workAcceptance);

	const navigate = (itemId: string) => {
		if (frontType === WorkAcceptanceFrontTypeId.STRUCTURES) {
			pushPath(formStructureLink(objectId, itemId));
		}
		if (frontType === WorkAcceptanceFrontTypeId.SPACES) {
			pushPath(formSpaceLink(objectId, itemId, 'work-acceptances'));
		}
	};

	useAppHeader(
		{
			title: workAcceptance
				? `${t('workAcceptancePage.title')} №${number}`
				: t('workAcceptancePage.title'),
			mobileRightButtons: (
				<MobileControls
					objectId={objectId}
					workAcceptance={workAcceptance}
					checkLists={checkLists}
					permissions={permissions}
				/>
			),
			showBackBtn: true
		},
		[workAcceptance, objectId, checkLists, permissions, t]
	);

	const units = useTranslatedUnitsArray();
	const unitValue = physicalWorkScope?.value;
	const unitId = physicalWorkScope?.unitId;

	const finalUnitStr = useMemo(
		() => makeUnitStr(units, unitValue, unitId),
		[units, unitValue, unitId]
	);

	const handleChange = useCallback(
		async (v: WorkAcceptanceStatusIds) => {
			if (workAcceptance) {
				await dispatch(editWorkAcceptanceStatus(objectId, workAcceptance.id, v));
			}
		},
		[objectId, workAcceptance, dispatch]
	);

	const user = useAppSelector(s => s.auth.profile);
	const statusEditPermission =
		user &&
		user.roles &&
		findPermission(user.roles, 'work-acceptances-status-edit', UserRoleScopes.OBJECT, objectId);
	const canStatusEdit = !!statusEditPermission;
	const availableStatuses = statusEditPermission?.restrictions?.availableStatuses as
		| string[]
		| undefined;
	const {data: typesMap} = useExtractWorkAcceptanceTypesById();
	const workAcceptanceType = type && typesMap && typesMap[type];
	const translatedAcceptanceType = useTranslatedDictionaryById(
		dictionaryKeys.workAcceptanceTypes,
		workAcceptanceType?.id
	);
	const {data: spaceTypes} = useExtractSpaceTypesById();
	const translatedSpaceTypes = useTranslatedDictionaryMap(dictionaryKeys.spaceTypes, spaceTypes);

	const unitPlanValue = planPhysicalWorkScope?.value;
	const unitPlanId = planPhysicalWorkScope?.unitId;

	const finalUnitPlanStr = useMemo(
		() => makeUnitStr(units, unitPlanValue, unitPlanId),
		[units, unitPlanValue, unitPlanId]
	);

	const workAcceptanceFront =
		frontType === WorkAcceptanceFrontTypeId.STRUCTURES
			? structures
			: frontType === WorkAcceptanceFrontTypeId.SPACES
			? spaces
			: undefined;

	const workAcceptanceFrontIcon =
		frontType === WorkAcceptanceFrontTypeId.STRUCTURES
			? structureIcon
			: frontType === WorkAcceptanceFrontTypeId.SPACES
			? spaceIcon
			: null;

	const formattedDateStart = acceptanceIntervalStart
		? format(acceptanceIntervalStart, dateTimeCommaSeparatedFormat, {locale: ru})
		: undefined;
	const formattedDateEnd = acceptanceIntervalEnd
		? format(acceptanceIntervalEnd, dateTimeCommaSeparatedFormat, {locale: ru})
		: undefined;

	return (
		<>
			<MobileInfo
				openDialog={openEditResultDialog}
				workAcceptance={workAcceptance}
				unitString={finalUnitStr}
				unitPlanString={finalUnitPlanStr}
				permissions={permissions}
			/>
			<Plate
				className={{
					content: 'work-acceptance-page__info-plate',
					root: 'work-acceptance-page__info-content'
				}}
			>
				<EntityGrid withBorders>
					{translatedStatuses && (
						<EntityGridItem
							label={t('workAcceptancePage.description.status.label')}
							icon={statusIcon}
							fullRow
						>
							<StatusSelect
								className="work-acceptance-page__d-entities-status"
								statuses={translatedStatuses}
								availableStatuses={
									availableStatuses && availableStatuses.length
										? availableStatuses
										: translatedStatuses.allIds
								}
								disabled={!canStatusEdit}
								value={status}
								onChange={handleChange}
							/>
						</EntityGridItem>
					)}
					{translatedAcceptanceType && (
						<EntityGridItem
							icon={typeIcon}
							label={t('workAcceptancePage.description.acceptanceType.label')}
						>
							<div>{translatedAcceptanceType}</div>
						</EntityGridItem>
					)}
					{description && <Description value={description} />}

					<EntityGridItem
						className="fix-date-view-field"
						icon={calendarIcon}
						label={t('workAcceptancePage.description.acceptanceDeadline.label')}
						fullRow
					>
						{acceptanceIntervalStart && acceptanceIntervalEnd && (
							<>
								<DeadlineIntervalMobile
									dateStart={formattedDateStart}
									dateEnd={formattedDateEnd}
									className={{
										item: 'work-acceptance-page__m-entities-fix-date-item'
									}}
								/>
								{compareFixDate(actualAcceptanceDate, acceptanceIntervalEnd) && (
									<FixDateTag
										className="fix-date-view-field__tag"
										plannedFixDate={acceptanceIntervalEnd}
										actualFixDate={actualAcceptanceDate}
									/>
								)}
							</>
						)}
					</EntityGridItem>

					{categoryId && (
						<Category
							categoryId={categoryId}
							fullRow
						/>
					)}

					{!!(acceptorsActiveGroup || acceptors?.length) && (
						<WorkAcceptanceAcceptors workAcceptance={workAcceptance} />
					)}

					{!!(submittersActiveGroup || submitters?.length) && (
						<WorkAcceptanceSubmitters workAcceptance={workAcceptance} />
					)}

					<EntityGridItem
						icon={locationIcon}
						label={t('workAcceptancePage.description.workScope.label')}
						fullRow
					>
						{workAcceptanceFront?.map(item => {
							const onCklickHandler = () => {
								navigate(item.id);
							};

							return (
								<div
									key={item.id}
									className="semi-bold work-acceptance-page__structure"
								>
									<div className="work-acceptance-page__structure_name">
										<div
											role="presentation"
											onClick={onCklickHandler}
											className="work-acceptance-page__selected-structure "
										>
											<div className="work-acceptance-page__selected-structure_icon">
												{workAcceptanceFrontIcon}
											</div>
											{frontType === WorkAcceptanceFrontTypeId.SPACES &&
											spaceTypes
												? formSpaceTitle(
														item.name,
														undefined,
														translatedSpaceTypes[item.type],
														undefined,
														true
												  )
												: item.name}
										</div>
									</div>
									{item.location && item.planId && (
										<div className="work-acceptance-page__structure_markers">
											<TranslatedLocationSelect
												mode="view"
												planId={item.planId}
												location={item.location}
												plans={plans}
												path="entity"
												multiplePoints
											>
												{(displayLocation: string, open: () => void) => (
													<div className="problem-page__d-entities-grid-display-place">
														<LinkButton
															label={displayLocation}
															onClick={open}
														/>
													</div>
												)}
											</TranslatedLocationSelect>
										</div>
									)}
									<br />
								</div>
							);
						})}
					</EntityGridItem>

					{custom &&
						Object.values(custom).map(customSetting => (
							<CustomField
								fields={customFields}
								setting={customSetting}
							/>
						))}

					<WorkAcptAttachments
						workAcceptanceId={workAcceptanceId}
						objectId={workAcceptanceObjectId}
						attachments={attachments}
					/>
					<WorkAcceptanceTask
						objectId={objectId}
						workAcceptance={workAcceptance}
					/>

					<EditorInfo
						icon={calendarIcon}
						label={t('workAcceptancePage.description.created.label')}
						date={createdAt}
						user={createdBy}
						fullRow
					/>

					{createdAt !== modifiedAt && (
						<EditorInfo
							icon={calendarIcon}
							label={t('workAcceptancePage.description.updated.label')}
							date={modifiedAt}
							user={modifiedBy}
							fullRow
						/>
					)}
				</EntityGrid>
			</Plate>
			{permissions?.canEditResult && resultWorkAcceptanceDialog}
		</>
	);
};
