import {useCallback} from 'react';
import {Button, ISelectionRowProps} from '@tehzor/ui-components';
import {ISchedule} from '@tehzor/tools/interfaces/schedules/ISchedule';
import styles from './SelectionRow.module.less';

type ICustomSelectionRowProps = ISelectionRowProps<ISchedule>;
const deleteIcon = <i className="tz-delete" />;

export const SelectionRow = (props: ICustomSelectionRowProps) => {
	const {toggleAllRowsSelected, selectedFlatRows} = props;

	const clearSelection = useCallback(() => {
		toggleAllRowsSelected(false);
	}, [toggleAllRowsSelected]);

	return (
		<div className={styles.selectionRowWrapper}>
			<div className={styles.selectedEntityCounter}> {selectedFlatRows.length}</div>
			<div className={styles.icon}>{deleteIcon}</div>
			<Button
				label="Сбросить"
				type="cancel"
				onClick={clearSelection}
			/>
		</div>
	);
};
