import {IPreparedWorkAcceptance} from '@src/interfaces/IPreparedWorkAcceptance';
import {CheckListsCell} from '@src/pages/WorkAcceptancesPage/components/table/CheckListsCell';
import {CreatedCell} from '@src/pages/WorkAcceptancesPage/components/table/CreatedCell';
import {FixDateCell} from '@src/pages/WorkAcceptancesPage/components/table/FixDateCell';
import {ModifiedCell} from '@src/pages/WorkAcceptancesPage/components/table/ModifiedCell';
import {AcceptorsCell} from '@src/pages/WorkAcceptancesPage/components/table/AcceptorsCell';
import {StatusChangeableCell} from '@src/pages/WorkAcceptancesPage/components/table/StatusChangableCell';
import {WorkAcceptanceDescriptionCell} from '@src/pages/WorkAcceptancesPage/components/table/WorkAcceptanceDescriptionCell';
import {WorkAcceptanceScopeCell} from '@src/pages/WorkAcceptancesPage/components/table/WorkAcceptanceScopeCell';
import {TFunction} from 'i18next';
import {Column, CellProps} from 'react-table';
import {MenuCell} from './MenuCell';

export const getDesktopColumns = (
	tFunc: TFunction,
	withoutObject?: boolean
): Array<Column<IPreparedWorkAcceptance>> => [
	{
		Header: '№',
		accessor: 'number',
		Cell: WorkAcceptanceDescriptionCell,
		width: 160,
		minWidth: 160
	},
	{
		id: 'object',
		Header: tFunc('workAcceptancesPage.table.columns.object.header'),
		accessor: row => row.object?.name,
		width: 130,
		minWidth: 130
	},
	{
		id: 'type',
		Header: tFunc('workAcceptancesPage.table.columns.type.header'),
		accessor: item => item.type?.name,
		width: 130,
		minWidth: 130
	},
	{
		id: 'frontType',
		Header: tFunc('workAcceptancesPage.table.columns.frontType.header'),
		accessor: item => item.frontType?.name,
		width: 130,
		minWidth: 130
	},
	{
		id: 'status',
		Header: tFunc('workAcceptancesPage.table.columns.status.header'),
		Cell: StatusChangeableCell,
		width: 150,
		minWidth: 150
	},
	{
		id: 'acceptanceInterval',
		Header: tFunc('workAcceptancesPage.table.columns.plannedFixDate.header'),
		width: 150,
		minWidth: 150,
		Cell: FixDateCell
	},
	{
		id: 'acceptors',
		Header: tFunc('workAcceptancesPage.table.columns.acceptors.header'),
		Cell: AcceptorsCell,
		width: 130,
		minWidth: 130
	},
	{
		id: 'scope',
		Header: tFunc('workAcceptancesPage.table.columns.scope.header'),
		Cell: (props: CellProps<IPreparedWorkAcceptance>) =>
			WorkAcceptanceScopeCell({...props, withoutObject}),
		width: 150,
		minWidth: 150
	},
	{
		id: 'checkLists',
		Header: tFunc('workAcceptancesPage.table.columns.checkLists.header'),
		Cell: CheckListsCell,
		width: 150,
		minWidth: 150
	},

	{
		Header: tFunc('workAcceptancesPage.table.columns.createdAt.header'),
		accessor: 'createdAt',
		Cell: CreatedCell,
		width: 130,
		minWidth: 130,
		sortDescFirst: true
	},
	{
		Header: tFunc('workAcceptancesPage.table.columns.modifiedAt.header'),
		accessor: 'modifiedAt',
		Cell: ModifiedCell,
		width: 150,
		minWidth: 150,
		sortDescFirst: true
	},
	{
		id: '_menu',
		Cell: MenuCell,
		className: 'work-acceptances-page__d-table-menu',
		width: 50,
		minWidth: 50,
		defaultCanSort: false,
		disableResizing: true,
		isNonClickable: true
	}
];

export const getDesktopColumnsWithoutObject = (tFunc: TFunction, isNestedObjects?: boolean) =>
	getDesktopColumns(tFunc, !isNestedObjects).filter(item => item.id !== 'object');
