import {AppHeaderCtxOptions} from '@src/components/AppHeader/utils/AppHeaderCtx';
import {DependencyList} from 'react';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {useCurrentTreeObject} from '@src/core/hooks/queries/objects/hooks';
import {useObjectSectionsMenu} from '@src/core/hooks/useObjectSectionsMenu';

export const useConditionalAppHeader = (
	objectId: string,
	options?: AppHeaderCtxOptions,
	deps?: DependencyList
) => {
	const object = useCurrentTreeObject(objectId);
	const sectionsMenu = useObjectSectionsMenu(objectId);

	const headerOptions = objectId
		? {title: object?.name, showBackBtn: true, sectionsMenu, ...options}
		: options;

	const extendedDeps = [...(deps ?? []), ...(objectId ? [object] : [])];
	useAppHeader(headerOptions, extendedDeps);
};
