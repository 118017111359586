import {IPreparedScheduledPlan} from '@tehzor/tools/interfaces/scheduledPlans';
import {CellProps} from 'react-table';
import styles from './ScheduledPlanCreatedAtCell.module.less';

export const ScheduledPlanCreatedAtCell = ({row}: CellProps<IPreparedScheduledPlan>) => {
	const {createdAt, createdBy} = row.original;
	return (
		<>
			<div className={styles.cellLabel}>{createdAt}</div>
			<div className={styles.cellLabel}>{createdBy}</div>
		</>
	);
};
