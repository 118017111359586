export enum editableScheduleFields {
	CALENDAR_ID = 'calendarId',
	STAGE_ID = 'stageId',
	PROCESS_ID = 'processId',
	COMPANY_ID = 'companyId',
	OBJECT_IDS = 'objectIds',
	PARTICIPANT_IDS = 'participantIds',
	WORKING_GROUP_ID = 'workingGroupId',
	DATE_RANGE_FROM = 'dateRangeFrom',
	DATE_RANGE_TO = 'dateRangeTo',
	SIMULTANEOUS_SLOTS_COUNT = 'simultaneousSlotsCount',
	SLOT_DURATION = 'slotDuration',
	INTERVAL_BETWEEN_SLOTS = 'intervalBetweenSlots',
	MAXIMUM_PREREGISTRATION_PERIOD = 'maximumPreregistrationPeriod',
	MINIMUM_PREREGISTRATION_PERIOD = 'minimumPreregistrationPeriod',
	MAXIMUM_OCCUPIED_SLOTS_NUMBER = 'maximumOccupiedSlotsNumber'
}
