import {IPreparedTimeInterval} from '@tehzor/tools/interfaces/schedules/IPreparedTimeInterval';
import {IIntervalFieldData} from '../../types/IIntervalFieldData';
import {timeStrToMs} from './timeStrToMs';

export const convertIntervalsFormData = (formData: IIntervalFieldData[]): IPreparedTimeInterval[] =>
	formData
		.filter(item => item.id.length < 36)
		.map(item => ({
			timeRangeFrom: timeStrToMs(item.timeRangeFrom),
			timeRangeTo: timeStrToMs(item.timeRangeTo),
			dateRangeFrom: item.dateRangeFrom?.getTime(),
			dateRangeTo: item.dateRangeTo?.getTime(),
			simultaneousSlotsCount: Number(item.simultaneousSlots),
			frequency: {
				id: item.repeatType.id,
				values: item.selectedDays.map(day => Number(day))
			}
		}));
