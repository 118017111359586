import {DependencyList, ReactNode} from 'react';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import {
	EntitiesFiltersProvider,
	IEntitiesFiltersProviderProps
} from '@/shared/lib/EntitiesFilters/context/EntitiesFiltersProvider';
import {AppHeaderCtxOptions} from '@src/components/AppHeader/utils/AppHeaderCtx';
import {useConditionalAppHeader} from '@src/fsd/src/shared/hooks/useConditionalAppHeader';

interface IEntitiesPageProps<F extends Record<string, unknown> = Record<string, unknown>> {
	objectId: string;
	appHeaderOptions?: AppHeaderCtxOptions;
	appHeaderDeps?: DependencyList;
	filtersProviderProps?: Omit<IEntitiesFiltersProviderProps<F>, 'objectId'>;
	children: ReactNode;
}

export const EntitiesPageWrapper = ({
	objectId,
	filtersProviderProps,
	appHeaderOptions,
	appHeaderDeps,
	children
}: IEntitiesPageProps) => {
	useScrollRestoration();
	useConditionalAppHeader(objectId, appHeaderOptions, appHeaderDeps);
	return filtersProviderProps ? (
		<EntitiesFiltersProvider
			objectId={objectId}
			{...filtersProviderProps}
		>
			{children}
		</EntitiesFiltersProvider>
	) : (
		<> {children}</>
	);
};
