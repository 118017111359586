import {IPreparedScheduledPlan} from '@tehzor/tools/interfaces/scheduledPlans';
import {CellProps} from 'react-table';
import styles from './ScheduledPlanModifiedAtCell.module.less';

export const ScheduledPlanModifiedAtCell = ({row}: CellProps<IPreparedScheduledPlan>) => {
	const {modifiedAt, modifiedBy} = row.original;
	return (
		<>
			<div className={styles.cellLabel}>{modifiedAt}</div>
			<div className={styles.cellLabel}>{modifiedBy}</div>
		</>
	);
};
