import {useEffect, useMemo, useState} from 'react';
import {useObjectsData, useObjectsMap} from '@src/core/hooks/queries/objects/hooks';
import {makeObjectsTreeData} from '@src/utils/makeObjectsTreeData';
import {useController, useFormContext} from 'react-hook-form';
import {IScheduleFieldProps} from '../types/IScheduleFieldProps';
import {ObjectsSelect as ObjectsSelectField} from '@/shared/ui/Form/ObjectsSelect/ObjectsSelect';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {editableScheduleFields} from '../constants/editableScheduleFields';
import {IScheduleForm} from '../types/IScheduleForm';
import {useParticipantsErrorHandler} from '../hooks/schedules/useParticipantsErrorHandling';

export const ObjectsSelect = ({field, onSetValue, className}: IScheduleFieldProps) => {
	const {control, watch} = useFormContext<IScheduleForm>();
	const {
		field: {value}
	} = useController({
		name: field,
		control
	});

	const objectsData = useObjectsData();
	const objectsMap = useObjectsMap();

	const companyId = watch(editableScheduleFields.COMPANY_ID) as string;

	const treeData = useMemo(() => {
		if (!objectsData) return [];
		const ids = objectsData.allIds;
		const objects = companyId
			? ids.reduce<IObject[]>((prev, id) => {
					if (objectsData.byId[id]?.companyId === companyId) {
						prev.push(objectsData.byId[id]);
					}
					return prev;
			  }, [])
			: ids.map(id => objectsData.byId[id]);
		return makeObjectsTreeData(objects);
	}, [objectsData, companyId]);

	const [selectedObjects, setSelectedObjects] = useState<string[]>(value as string[]);

	// необходимо для установки начальных значений после получения с бэка
	useEffect(() => {
		if (value !== undefined) {
			setSelectedObjects(value as string[]);
		}
	}, [value]);

	const handleClear = () => {
		setSelectedObjects([]);
	};

	const handleCancel = () => {
		setSelectedObjects(value as string[]);
	};

	const handleApply = () => {
		onSetValue(field, selectedObjects);
	};

	const {clearParticipantsOverlapError} = useParticipantsErrorHandler();

	const handleClearButton = () => {
		onSetValue(field, []);
		setSelectedObjects([]);
		clearParticipantsOverlapError();
	};

	const handleChange = (v: string[]) => {
		setSelectedObjects(v);
		clearParticipantsOverlapError();
	};

	return (
		<div className={className}>
			<ObjectsSelectField
				treeData={treeData}
				value={value as string[]}
				handleClear={handleClear}
				handleCancel={handleCancel}
				handleApply={handleApply}
				handleClearButton={handleClearButton}
				objectsMap={objectsMap}
				handleChange={handleChange}
				selectedObjects={selectedObjects}
				disabled={!companyId}
			/>
		</div>
	);
};
