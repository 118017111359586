import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {
	IScheduledPlansFilterState,
	IScheduledPlansPageSettingsState,
	IScheduledPlansPageSizePayload,
	IScheduledPlansPagesSettingsState,
	IScheduledPlansSortState
} from './interfaces';
import {IChangeSortPayload} from '@src/store/interfaces/IChangeSortPayload';
import {IChangeFiltersPayload} from '@src/store/interfaces/IChangeFiltersPayload';

export const getInitialStateForPage = (objectId: string): IScheduledPlansPageSettingsState => ({
	sort: {name: true},
	filters: {objectIds: objectId !== 'all' ? [objectId] : undefined},
	pageSize: 20,
	offset: 0,
	selectedRows: []
});

const initialState: IScheduledPlansPagesSettingsState = {};
export const scheduledPlansSlice = createSlice({
	name: 'scheduledPlans',
	initialState,
	reducers: {
		changeSort(state, action: PayloadAction<IChangeSortPayload<IScheduledPlansSortState>>) {
			const {objectId, sort} = action.payload;
			if (!state.hasOwnProperty(objectId)) {
				state[objectId] = getInitialStateForPage(objectId);
			}
			state[objectId].sort = sort;
		},
		changeSelectedRows(
			state,
			action: PayloadAction<{objectId: string; selectedRows: string[]}>
		) {
			const {objectId, selectedRows} = action.payload;

			if (!state.hasOwnProperty(objectId)) {
				state[objectId] = getInitialStateForPage(objectId);
			}
			state[objectId].selectedRows = selectedRows;
		},
		changePageSize(state, action: PayloadAction<IScheduledPlansPageSizePayload>) {
			const {objectId, pageSize} = action.payload;
			if (!state.hasOwnProperty(objectId)) {
				state[objectId] = getInitialStateForPage(objectId);
			}
			state[objectId].pageSize = pageSize;
		},
		changeOffset(state, action: PayloadAction<{objectId: string; offset: number}>) {
			const {objectId, offset} = action.payload;
			if (!state.hasOwnProperty(objectId)) {
				state[objectId] = getInitialStateForPage(objectId);
			}
			objectId;
			state[objectId].offset = offset;
		},
		changeFilters(
			state,
			action: PayloadAction<IChangeFiltersPayload<IScheduledPlansFilterState>>
		) {
			const {objectId, filters} = action.payload;
			if (!state.hasOwnProperty(objectId)) {
				state[objectId] = getInitialStateForPage(objectId);
			}

			state[objectId].filters = filters;
		},
		clearFilters(state, action: PayloadAction<{objectId: string}>) {
			const {objectId} = action.payload;

			state[objectId].filters = {};
		}
	}
});
export const {actions: scheduledPlansActions, reducer: scheduledPlansReducer} = scheduledPlansSlice;
