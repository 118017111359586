export enum durationMeasureKeys {
	MINUTE = 'minute',
	HOUR = 'hour',
	DAY = 'day'
}

export interface IDurationMeasure {
	value: string;
	key: durationMeasureKeys;
}

export type IDurationMeasures =
	| Record<durationMeasureKeys, IDurationMeasure>
	| {[k: string]: IDurationMeasure};
