import ILayer from '@tehzor/tools/interfaces/plans/ILayer';

const extractPolygonPoints = (polygonString: string): number[][] => {
	const parser = new DOMParser();
	const doc = parser.parseFromString(polygonString, 'image/svg+xml');
	const polygon = doc.querySelector('polygon');

	if (!polygon) {
		return [];
	}

	const points = polygon.getAttribute('points');

	if (!points) {
		return [];
	}

	const pointsArray = points
		.split(/[ ,]+/)
		.map(Number)
		.filter(val => !isNaN(val));

	const result: number[][] = [];
	for (let i = 0; i < pointsArray.length; i += 2) {
		result.push([pointsArray[i], pointsArray[i + 1]]);
	}
	return result;
};

const extractRectElement = (rectString: string): SVGRectElement | undefined => {
	const parser = new DOMParser();
	const doc = parser.parseFromString(rectString, 'image/svg+xml');
	const rectElement = doc.querySelector('rect');
	if (!rectElement) {
		return undefined;
	}
	return rectElement;
};

const extractElipseElement = (rectString: string): SVGEllipseElement | undefined => {
	const parser = new DOMParser();
	const doc = parser.parseFromString(rectString, 'image/svg+xml');
	const ellipseElement = doc.querySelector('ellipse');
	if (!ellipseElement) {
		return undefined;
	}
	return ellipseElement;
};

const isPointInPolygon = (x: number, y: number, polygon: number[][]): boolean => {
	let inside = false;
	for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
		const xi = polygon[i][0],
			yi = polygon[i][1];
		const xj = polygon[j][0],
			yj = polygon[j][1];

		const intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
		if (intersect) inside = !inside;
	}
	return inside;
};

// Функция для проверки точки в прямоугольнике
const isPointInRect = (x: number, y: number, rect: SVGRectElement): boolean => {
	const rx = parseFloat(rect.getAttribute('x') || '0');
	const ry = parseFloat(rect.getAttribute('y') || '0');
	const rHeight = parseFloat(rect.getAttribute('width') || '0');
	const rWidth = parseFloat(rect.getAttribute('height') || '0');
	const inRect =
		x >= rx && x <= rx + rWidth && y >= ry && y <= ry + rHeight;
	return inRect;
};

// Функция для проверки точки в эллипсе
const isPointInEllipse = (x: number, y: number, ellipse: SVGEllipseElement): boolean => {
	const cx = parseFloat(ellipse.getAttribute('cx') || '0');
	const cy = parseFloat(ellipse.getAttribute('cy') || '0');
	const rx = parseFloat(ellipse.getAttribute('rx') || '0');
	const ry = parseFloat(ellipse.getAttribute('ry') || '0');

	// Уравнение эллипса: ((x - cx)/rx)^2 + ((y - cy)/ry)^2 <= 1
	const InEllipse =  ((x - cx) / rx) ** 2 + ((y - cy) / ry) ** 2 <= 1;
	return InEllipse;
};

export const getPointAttributes = (x: number, y: number, layers?: ILayer[]) => {
	if (layers) {
		for (const layer of layers) {
			for (const shape of layer.shapes || []) {
				switch (shape.type) {
					case 'polygon': {
						const polygonPoints = extractPolygonPoints(shape.svg);
						if (isPointInPolygon(x, y, polygonPoints)) {
							return {sector: shape.id, name: shape.name};
						}
						break;
					}
					case 'rect': {
						const rect = extractRectElement(shape.svg); // Получаем bounding box
						if (rect && isPointInRect(x, y, rect)) {
							return {sector: shape.id, name: shape.name};
						}
						break;
					}
					case 'ellipse': {
						const ellipse = extractElipseElement(shape.svg); // Получаем bounding box
						if (ellipse && isPointInEllipse(x, y, ellipse)) {
							return {sector: shape.id, name: shape.name};
						}
						break;
					}
					default: {
						return {sector: undefined, name: 'Точка'};
					}
				}
			}
		}
	}
	return {sector: undefined, name: 'Точка'};
};
