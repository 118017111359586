import {Wrapper} from '@/shared/ui/EntitiesPage/Wrapper/Wrapper';
import {SchedulePageBreadcrumbs} from './ui/SchedulePageBreadcrumbs/SchedulePageBreadcrumbs';
import {ScheduleForm} from '@/features/EditableSchedule';

export const SchedulePage = () => (
	<Wrapper>
		<SchedulePageBreadcrumbs />
		<ScheduleForm />
	</Wrapper>
);
