import {EditableFieldLabel} from '@tehzor/ui-components';
import {CSSProperties, ReactNode} from 'react';

interface IEditableFieldLabelProps {
	label: string;
	required?: boolean;
	className?: string;
	style?: CSSProperties;
	children?: ReactNode;
	addon?: ReactNode;
}

export const FieldLabel = ({label, required, ...props}: IEditableFieldLabelProps) => (
	<EditableFieldLabel {...props}>
		{label}
		{required ? '*' : null}
	</EditableFieldLabel>
);
