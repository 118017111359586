import {IPreparedScheduledPlan} from '@tehzor/tools/interfaces/scheduledPlans';
import {IconButton} from '@tehzor/ui-components';
import {useCallback} from 'react';
import {CellProps} from 'react-table';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';
import styles from './DeleteCell.module.less';
import {useScheduledPlansPermissions} from '@/shared/hooks/permissions/useScheduledPlansPermissions';

const deleteIcon = <i className="tz-delete" />;

interface IDeleteCellProps {
	props: CellProps<IPreparedScheduledPlan>;
	className: string;
}
export const DeleteCell = ({props, className}: IDeleteCellProps) => {
	const {row} = props;
	const {canDeleteScheduledPlans} = useScheduledPlansPermissions();

	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: 'Удалить план-график',
		message: 'Вы действительно хотите удалить выбранный план-график?',
		props: {
			className: {root: styles.dialogRoot}
		},
		description: 'Все плановые работы этого план-графика будут также удалены'
	});
	const handleDelete = useCallback(async () => {
		if (await getDeleteConfirmation()) {
			console.log(row.original.name);
		}
	}, [row.original]);

	if (!canDeleteScheduledPlans) return null;
	return (
		<div className={className}>
			<IconButton
				type="inline-blue-accent"
				onClick={handleDelete}
			>
				{deleteIcon}
			</IconButton>
			{deleteDialog}
		</div>
	);
};
