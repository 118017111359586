import {useScheduledPlansPageBreadcrumb} from '@/features/ScheduledPlans';
import {TopBar} from '@/shared/ui/EntitiesPage/TopBar/TopBar';
import {useObjectBreadcrumbs} from '@src/core/hooks/breadcrumbs/useObjectBreadcrumbs';
import {Button} from '@tehzor/ui-components';

interface ITopBarProps {
	objectId: string;
}
const iconPlus = <i className="tz-plus-20" />;

export const ScheduledPlansTopBar = ({objectId}: ITopBarProps) => {
	const breadcrumbs = useObjectBreadcrumbs(objectId);

	const handleCLick = () => {
		console.log('add plan');
	};

	const scheduledPlansPageBreadcrumb = useScheduledPlansPageBreadcrumb();
	return (
		<TopBar breadcrumbs={objectId ? breadcrumbs : [scheduledPlansPageBreadcrumb]}>
			<Button
				type="accent-blue"
				leftIcon={iconPlus}
				label="Добавить план-график"
				onClick={handleCLick}
			/>
		</TopBar>
	);
};
