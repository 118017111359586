import {useFormContext} from 'react-hook-form';
import {editableScheduleFields} from '../../constants/editableScheduleFields';
import {ParticipantsErrorTypes} from '../../types/ParticipantsErrorTypes';

export const useParticipantsErrorHandler = () => {
	const {getFieldState, clearErrors} = useFormContext();

	const clearParticipantsOverlapError = () => {
		const {error} = getFieldState(editableScheduleFields.PARTICIPANT_IDS);
		if (error?.type === ParticipantsErrorTypes.OVERLAP) {
			clearErrors(editableScheduleFields.PARTICIPANT_IDS);
		}
	};

	return {clearParticipantsOverlapError};
};
