import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {Wrapper} from '@/shared/ui/EntitiesPage/Wrapper/Wrapper';
import {EntitiesPageWrapper} from '@/widgets/EntitiesPageWrapper/EntitiesPageWrapper';
import {
	extractScheduledPlansPageSettings,
	scheduledPlansActions,
	useScheduledPlansPaginateList
} from '@/entities/ScheduledPlans';
import {LoadingIndicator} from '@/shared/ui/LoadingIndicator/LoadingIndicator';
import {Controls} from '@/shared/ui/EntitiesPage/Controls/Controls';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useCallback} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IScheduledPlansFilterState} from '@/entities/ScheduledPlans/model/slice/interfaces';
import {ScheduledPlansTable} from '@/widgets/ScheduledPlansTable';
import {ScheduledPlansTopBar} from '../components/ScheduledPlansTopBar/ScheduledPlansTopBar';

export const ScheduledPlansPage = () => {
	const {objectId} = useStrictParams<{objectId: string}>();
	const {
		data: scheduledPlans,
		isFetching,
		refetch: refetchScheduledPlans
	} = useScheduledPlansPaginateList(objectId);
	const {changeOffset, changeFilters, clearFilters} = scheduledPlansActions;
	const {filters} = useAppSelector(s => extractScheduledPlansPageSettings(s, objectId));
	const dispatch = useAppDispatch();

	const handleApply = useCallback(
		(value: IScheduledPlansFilterState) => {
			dispatch(changeFilters({objectId, filters: value}));
			dispatch(changeOffset({objectId, offset: 0}));
		},
		[objectId, dispatch, changeFilters, changeOffset]
	);

	const handleClear = useCallback(() => {
		dispatch(clearFilters({objectId}));
	}, [objectId, dispatch, clearFilters]);

	return (
		<EntitiesPageWrapper
			objectId={objectId}
			appHeaderOptions={objectId ? undefined : {title: 'Планы-графики'}}
			filtersProviderProps={{
				filters,
				onApply: handleApply,
				onClear: handleClear
			}}
		>
			<Wrapper>
				<Controls>
					<ScheduledPlansTopBar objectId={objectId} />
				</Controls>
				<LoadingIndicator
					text={`Всего: `}
					isFetching={isFetching}
					totalEntities={scheduledPlans?.total || 0}
					onClick={refetchScheduledPlans}
				/>
				<ScheduledPlansTable
					objectId={objectId}
					total={scheduledPlans?.total || 0}
				/>
			</Wrapper>
		</EntitiesPageWrapper>
	);
};
