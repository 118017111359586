import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {
	CategoriesSetFilterMobile,
	CreatedByFilterMobile,
	CreationDateFilterMobile,
	CriticalFilterMobile,
	PlannedFixDateFilterMobile,
	ObjectsFilterMobile,
	PlansFilterMobile,
	ProblemStatusesFilterMobile,
	ActualFixDateFilterMobile,
	SpaceTypesFilterMobile
} from '@src/components/MobileEntitiesFilters';
import {ExpiredFilterMobile} from '@src/components/MobileEntitiesFilters/components/ExpiredFilterMobile';
import {InspectorsFilterMobile} from '@src/components/MobileEntitiesFilters/components/InspectorsFilterMobile';
import {ProblemTagsSetFilterMobile} from '@src/components/MobileEntitiesFilters/components/ProblemTagsSetFilterMobile';
import {StagesFilterMobile} from '@src/components/MobileEntitiesFilters/components/StagesFilterMobile';
import {isCleanable} from '@src/components/MobileEntitiesFilters/utils/isCleanable';
import {useProblemSettings} from '@src/core/hooks/settings/useProblemSettings';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {IProblemsFiltersState} from '@src/store/modules/settings/pages/problems/interfaces';
import {LinkButton} from '@tehzor/ui-components';
import {stringify} from 'query-string';
import {useCallback, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {TranslatedFilterPage} from '@src/components/TranslatedFilterPage';
import {PerformersFilterMobile} from '@src/components/MobileEntitiesFilters/components/PerformersFilterMobile';
import {CustomFiltersMobile} from '@src/components/CustomFilters/CustomFiltersMobile';
import {ReopenedTimesFilterMobile} from '@src/components/MobileEntitiesFilters/components/ReopenedTimesFilterMobile';
import {useObject, useObjectChildrenIds} from '@src/core/hooks/queries/objects/hooks';
import {NestedObjectsFilterMobile} from '@src/components/MobileEntitiesFilters/components/NestedObjectsFilterMobile';
import {AddFiltersPatternMobile} from '@src/components/FiltersPatterns/components/AddFiltersPatternMobile/AddFiltersPatternMobile';
import {problemsActions} from '@src/store/modules/settings/pages/problems/slice';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {getSectionsMenuItems} from '@src/pages/filters/ProblemsFiltersPage/utils/getSectionsMenuItems';
import {useExtractSpaceTypesAsArray} from '@src/core/hooks/queries/spaceTypes/hooks';

const ProblemsFilterPage = () => {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();

	const {pushPath} = useChangePath();
	const {objectId, entity} = useStrictParams<{objectId?: string; entity: string}>();
	const {data: spaceTypes} = useExtractSpaceTypesAsArray();
	const {data: object} = useObject(objectId);

	const currentObject = objectId || 'all';

	const settings = useProblemSettings(currentObject);
	const canGoBack = (useLocation().state as {canGoBack?: boolean | null})?.canGoBack;
	const {state, isLocal, isStateChanged, change, mobileClear, apply} =
		useEntitiesFiltersCtx<IProblemsFiltersState>();
	const [isCleared, setIsCleared] = useState(false);

	const {addFiltersPattern} = problemsActions;

	const nestedObjects = useObjectChildrenIds(objectId);

	const search = stringify(state, {arrayFormat: 'index'});

	const handleApply = useCallback(() => {
		if (canGoBack) {
			if (isLocal) {
				apply();
				pushPath(
					objectId ? `/objects/${objectId}/problems/?${search}` : `/problems/?${search}`
				);
			} else {
				apply();
				pushPath(objectId ? `/objects/${objectId}/problems` : '/problems');
			}
		}
	}, [canGoBack, isLocal, apply, pushPath, objectId, search]);

	const handleClear = () => {
		mobileClear();
		setIsCleared(true);
	};

	useAppHeader(
		{
			title: t('problemsFiltersPage.title'),
			showBackBtn: true,
			sectionsMenu: getSectionsMenuItems(objectId),
			mobileRightButtons: isCleanable<IProblemsFiltersState>(
				state,
				objectId,
				undefined,
				!!nestedObjects?.length
			) ? (
				<LinkButton
					label={t('problemsFiltersPage.resetFiltersBtn.label')}
					onClick={handleClear}
				/>
			) : null
		},
		[state, objectId, t, nestedObjects]
	);

	const onAddPattern = (name: string, filters: Record<string, unknown>) => {
		void dispatch(
			addFiltersPattern({objectId: currentObject, name, filters, activate: false})
		);
	};

	return (
		<TranslatedFilterPage
			applyDisabled={!isStateChanged}
			onApplyClick={handleApply}
			acceptBtnLabel={t('filterPage.acceptBtn.label')}
		>
			<AddFiltersPatternMobile
				isStateChanged={isStateChanged}
				filters={state}
				onAddPattern={onAddPattern}
			/>
			{objectId === undefined && (
				<ObjectsFilterMobile
					entity={entity}
					value={state?.objects}
				/>
			)}

			{!!nestedObjects?.length ? (
				<NestedObjectsFilterMobile
					entity={entity}
					value={state?.objects}
					pageObjectId={objectId}
				/>
			) : null}

			<ProblemStatusesFilterMobile
				objectId={objectId}
				value={state?.statuses}
				entity={entity}
				onChange={change}
			/>

			{settings.builtin.plannedFixDate && (
				<PlannedFixDateFilterMobile
					objectId={objectId}
					from={state.plannedFixDateFrom}
					to={state.plannedFixDateTo}
					entity={entity}
				/>
			)}
			<ActualFixDateFilterMobile
				objectId={objectId}
				from={state.actualFixDateFrom}
				to={state.actualFixDateTo}
				entity={entity}
			/>
			{settings.builtin.critical && (
				<CriticalFilterMobile
					value={state.critical}
					label={t('problemsPage.booleanFilter.label')}
					onChange={change}
				/>
			)}

			<ExpiredFilterMobile
				value={state.expired}
				label={t('problemsPage.expiredFilter.label')}
				onChange={change}
			/>
			<ProblemTagsSetFilterMobile
				objectsIds={!objectId ? state.objects : [objectId]}
				stages={state.stages}
				currentObjectId={objectId}
				label={t('problemsPage.problemTagsSetFilter.label')}
				value={state?.problemTags}
				entity={entity}
				onChange={change}
				isCleared={isCleared}
				setIsCleared={setIsCleared}
			/>

			<CreatedByFilterMobile
				objectId={objectId}
				label={t('problemsPage.createdByFilter.label')}
				value={state?.createdBy}
				entity={entity}
				onChange={change}
			/>

			<CreationDateFilterMobile
				objectId={objectId}
				label={t('problemsPage.creationDateFilter.label')}
				from={state.createdAtFrom}
				to={state.createdAtTo}
				entity={entity}
			/>

			{settings.builtin.categoryId && (
				<CategoriesSetFilterMobile
					objectsIds={!objectId ? state.objects : [objectId]}
					currentObject={objectId}
					stages={state.stages}
					label={t('problemsPage.categoriesSetFilter.label')}
					value={state?.categories}
					entity={entity}
					onChange={change}
				/>
			)}

			{objectId !== undefined && settings.builtin.location && (
				<PlansFilterMobile
					objectId={objectId}
					label={t('problemsPage.plansFilter.label')}
					value={state?.plans}
					entity={entity}
					onChange={change}
				/>
			)}

			{settings.builtin.performers && (
				<PerformersFilterMobile
					objectId={objectId ?? 'all'}
					label={t('entitiesFilters.performers.label')}
					value={state?.performers}
					entity={entity}
					onChange={change}
				/>
			)}

			{settings.builtin.inspectors && (
				<InspectorsFilterMobile
					objectId={objectId ?? 'all'}
					label={t('entitiesFilters.inspectors.label')}
					value={state?.inspectors}
					entity={entity}
					onChange={change}
				/>
			)}

			<StagesFilterMobile
				objectId={objectId}
				label={t('problemsPage.stagesFilter.label')}
				value={state?.stages}
				entity={entity}
				onChange={change}
			/>

			<ReopenedTimesFilterMobile
				objectId={objectId ?? 'all'}
				label={t('entitiesFilters.reopenedTimes.label')}
				entity={entity}
				onChange={change}
				value={state?.reopenedTimes}
			/>

			{settings.custom && (
				<CustomFiltersMobile
					objectId={objectId ?? 'all'}
					settings={settings.custom}
					filtersData={state.custom}
					entity={entity}
				/>
			)}

			{!!spaceTypes?.length && (currentObject === 'all' || object?.hasSpaces) && (
				<SpaceTypesFilterMobile
					objectId={objectId}
					value={state?.spaceTypes}
					entity={entity}
					onChange={change}
					filterName="spaceTypes"
				/>
			)}
		</TranslatedFilterPage>
	);
};

export default ProblemsFilterPage;
