export enum FrequencyIds {
	EVERY_WEEK = 'every-week',
	EVERY_MONTH = 'every-month'
}

export interface IFrequencyDB {
	id: FrequencyIds;
	name: string;
}

export interface ITimeIntervalFrequency {
	id: FrequencyIds;
	values: number[];
}
