import {useEffect, useMemo, useState} from 'react';
import {makeTree} from '@tehzor/tools/utils/tree/makeTree';
import {IRestrictedSelectionTreeDataItem} from './interfaces/IRestrictedSelectionTreeDataItem';
import {TreeCtx, TreeCtxType} from './utils/TreeCtxType';
import {RestrictedSelectionTreeItem} from './components/RestrictedSelectionTreeItem/RestrictedSelectionTreeItem';
import styles from './RestrictedSelectionTree.module.less';

interface IRestrictedSelectionTreeProps<T extends IRestrictedSelectionTreeDataItem, V> {
	parents?: V[];
	selectedChildren: string[];
	selectedParent?: string;
	expandedParents?: string[];
	onChildrenSelect: (keys: string[]) => void;
	onParentSelect: (key: string | undefined) => void;
	onParentsExpand?: (keys: string[]) => void;
	data: T[];
	getChildrenFromParent: (parent: V) => string[];
	getContent: (data: T) => JSX.Element;
}

export const RestrictedSelectionTree = <
	T extends IRestrictedSelectionTreeDataItem,
	V extends {id: string}
>(
	props: IRestrictedSelectionTreeProps<T, V>
) => {
	const {
		parents,
		selectedChildren,
		selectedParent,
		expandedParents,
		onChildrenSelect,
		onParentSelect,
		onParentsExpand,
		data,
		getChildrenFromParent,
		getContent
	} = props;

	const [expanded, setExpanded] = useState<string[]>([]);

	const treeData = useMemo(() => makeTree(data), [data]);

	useEffect(() => {
		setExpanded(expandedParents ?? []);
	}, [expandedParents]);

	const ctxValue = useMemo(
		(): TreeCtxType => ({
			isChildSelected: (childId: string) => selectedChildren.includes(childId),
			isParentSelected: (parentId: string) => selectedParent === parentId,
			isParentExpanded: (parentId: string) => expanded.includes(parentId),
			changeChildSelected: (
				childId: string,
				parentId: string | undefined,
				value: boolean
			) => {
				if (value && selectedParent !== parentId) {
					onChildrenSelect([...selectedChildren, childId]);
				} else {
					const newValue = selectedChildren.filter(id => id !== childId);
					onChildrenSelect(newValue);
				}
				onParentSelect(undefined);
			},
			changeParentSelected: (parentId: string, value: boolean) => {
				if (!value) {
					onParentSelect(undefined);
					onChildrenSelect([]);
					return;
				}
				if (selectedParent && selectedParent !== parentId) {
					onChildrenSelect([]);
				}
				onParentSelect(parentId);
				if (onParentsExpand) {
					onParentsExpand([...(expandedParents || []), parentId]);
				}
				const parent = parents?.find(p => p.id === parentId);
				if (parent) {
					const children = getChildrenFromParent(parent);
					onChildrenSelect(children);
				}
			},
			changeParentExpanded: (parentId: string, value: boolean) => {
				const newExpanded = value
					? expanded.concat([parentId])
					: expanded.filter(key => key !== parentId);
				if (onParentsExpand) {
					onParentsExpand(newExpanded);
				} else {
					setExpanded(newExpanded);
				}
			}
		}),
		[
			selectedChildren,
			selectedParent,
			expanded,
			onChildrenSelect,
			onParentSelect,
			onParentsExpand,
			expandedParents,
			getChildrenFromParent,
			parents
		]
	);

	return (
		<ul className={styles.tree}>
			<TreeCtx.Provider value={ctxValue}>
				{treeData.map(item => (
					<RestrictedSelectionTreeItem
						getContent={getContent}
						data={item}
						level={0}
						key={item.id}
					/>
				))}
			</TreeCtx.Provider>
		</ul>
	);
};
