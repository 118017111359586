import {useEffect, useRef} from 'react';
import {useVirtualizer} from '@tanstack/react-virtual';
import classNames from 'classnames';
import './Virtualizer.less';

interface IVirtualizerProps<DataItem extends unknown> {
	data: DataItem[];
	estimateSize?: number;
	renderItem: (item: DataItem, index: number) => JSX.Element;
	className?: string;
	itemClassName?: string;
	height?: string;
	overscan?: number;
}

/**
 * @description  Виртуализация любых данных из массива
 * @param props.data - массив данных
 * @param props.estimateSize - размер элемента по умолчанию
 * @param props.renderItem - функция отрисовки элемента
 * @param props.height - высота окна
 * @param props.overscan - элементы вне зоны видимости, но в DOM
 */
export const Virtualizer = <DataItem,>(props: IVirtualizerProps<DataItem>) => {
	const {data, estimateSize, renderItem, className, itemClassName, overscan, height} = props;
	const parentRef = useRef<HTMLDivElement | null>(null);

	const rowVirtualizer = useVirtualizer({
		count: data.length,
		getScrollElement: () => parentRef.current,
		estimateSize: () => estimateSize ?? 34,
		overscan: overscan ?? 5
	});

	const virtualItems = rowVirtualizer.getVirtualItems();

	useEffect(() => {
		rowVirtualizer.measure();
	}, [height, rowVirtualizer]);

	return (
		<div
			ref={parentRef}
			style={{height: height ?? '400px'}}
			className="virtualizer__scroll-container"
		>
			<div style={{height: rowVirtualizer.getTotalSize()}} className="virtualizer__track">
				{virtualItems.length > 0 && (
					<div
						className={classNames('virtualizer__list', className)}
						style={{transform: `translateY(${virtualItems[0]?.start ?? 0}px)`}}
					>
						{virtualItems.map(virtualItem => (
							<div
								key={virtualItem.key}
								data-index={virtualItem.index}
								ref={rowVirtualizer.measureElement}
								className={itemClassName}
							>
								{renderItem(data[virtualItem.index], virtualItem.index)}
							</div>
						))}
					</div>
				)}
			</div>
		</div>
	);
};
