import {IUseUsersResult} from '@src/api/cache/restDictionaries/defaults/useUsersQueryDefaults';

export const extractUsersAsArray = (data: IUseUsersResult) =>
	data.allIds.map((id: string) => data.byId[id]);

export const extractUsersAsMap = (data: IUseUsersResult) => data.byId;

export const extractUser = (data: IUseUsersResult, userId?: string) => {
	if (!userId) {
		return undefined;
	}
	return extractUsersAsMap(data)[userId];
};
