import {ITreeDataItem} from '@tehzor/ui-components';
import {getAllParents} from './getAllParents';

export const getInitialExpanded = <T extends ITreeDataItem>(
	treeData: T[],
	checked?: string[]
): string[] => {
	const keys: string[] = [];
	if (!checked) {
		return [];
	}
	for (const id of checked) {
		if (!treeData.find(item => item.id === id)) continue;
		const parentIds = getAllParents(id, treeData);

		for (const parentId of parentIds) {
			if (!keys.includes(parentId)) {
				keys.push(parentId);
			}
		}
	}

	return keys;
};
