import {CSSProperties} from 'react';
import {FieldLabel} from '@/shared/ui/FieldLabel/FieldLabel';
import {Select2, SelectOption, SelectPopup, TextFieldWithForwardedRef} from '@tehzor/ui-components';
import ICompany from '@tehzor/tools/interfaces/companies/ICompany';

interface ICompaniesSelect {
	required?: boolean;
	className?: string;
	style?: CSSProperties;
	errorMessage?: string;
	disabled?: boolean;
	companiesArray?: ICompany[];
	value?: string;
	handleChange: (v: string) => void;
	isUUID?: boolean;
	companiesMap?: Record<string, ICompany>;
}

const companyIcon = <i className="tz-company-20" />;

const getKey = (company: ICompany, isUUID: boolean) =>
	isUUID && company.uuid ? company.uuid : company.id;

export const CompanySelect = ({
	required,
	className,
	style,
	errorMessage,
	disabled,
	companiesArray,
	value,
	handleChange,
	isUUID = false,
	companiesMap
}: ICompaniesSelect) => {
	const onChange = (v: string) => {
		handleChange(v);
	};

	if (!companiesArray || !companiesMap) {
		return null;
	}

	const title = isUUID
		? Object.values(companiesMap).find(el => el?.uuid === value)?.name
		: value && companiesMap[value]?.name;

	return (
		<div
			className={className}
			style={style}
		>
			<FieldLabel
				label="Компания"
				required={required}
			/>
			<SelectPopup
				noHeader
				trigger={
					<TextFieldWithForwardedRef
						elementType="div"
						value={title}
						icon={companyIcon}
						error={errorMessage ?? undefined}
						disabled={disabled}
					/>
				}
			>
				<Select2
					value={value}
					onChange={onChange}
				>
					{companiesArray?.map(company => (
						<SelectOption
							key={getKey(company, isUUID)}
							itemKey={getKey(company, isUUID)}
							content={company.name}
							inputType="radio"
						/>
					))}
				</Select2>
			</SelectPopup>
		</div>
	);
};
