import {httpRequests} from '@tehzor/tools/api/httpRequests';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IScheduledPlan} from '@tehzor/tools/interfaces/scheduledPlans';
import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';

export interface IGetScheduledPlansResponse extends INormalizedData<IScheduledPlan> {
	offset: number;
	limit: number;
	total: number;
}

/**
 * Возвращает список план-графиков
 */
export const getScheduledPlansPaginateRequest = async (
	filters?: Record<string, unknown>,
	sort?: Record<string, boolean>,
	offset?: number,
	limit?: number
) => {
	const response = await httpRequests.withToken.post<IGetScheduledPlansResponse>(
		'scheduled-plans/get-paginate',
		{
			filters,
			sort: convertSortParam(sort),
			offset,
			limit
		}
	);
	return response.data;
};
