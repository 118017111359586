import {IState} from '@src/store/modules';

export const extractCheckListsPageFilters = (state: IState) =>
	state.settings.pages.manage.checkLists.filters;

export const extractCheckListsPageSort = (state: IState) =>
	state.settings.pages.manage.checkLists.sort;

export const extractCheckListsPageSettings = (state: IState) =>
	state.settings.pages.manage.checkLists;
