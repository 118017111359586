import {useFieldArray, useFormContext} from 'react-hook-form';
import {useUpdateEffect} from 'react-use';
import {TimeInterval} from '../TimeInterval/TimeInterval';
import styles from './TimeIntervals.module.less';
import {Button} from '@tehzor/ui-components';
import classNames from 'classnames';
import {getIntervalField} from '../../utils/intervals/getIntervalField';
import {getDefaultValues} from '../../utils/intervals/getDefaultValues';
import {IntervalFields} from '../../types/IntervalFields';
import {useGeneralDateRange} from '../../hooks/intervals/useGeneralDateRange';
import {IScheduleForm} from '../../types/IScheduleForm';

const iconPlus = <i className="tz-plus-20" />;

export const TimeIntervals = () => {
	const {setValue, control, getValues} = useFormContext<IScheduleForm>();
	const {fields, append} = useFieldArray({
		control,
		name: 'intervals'
	});

	const resetDateRange = () => {
		fields.forEach((_, index) => {
			setValue(getIntervalField(index, IntervalFields.DATE_RANGE_FROM), undefined);
			setValue(getIntervalField(index, IntervalFields.DATE_RANGE_TO), undefined);
		});
	};
	const generalDates = useGeneralDateRange();
	useUpdateEffect(() => {
		resetDateRange();
	}, [generalDates]);
	const handleAddInterval = () => append(getDefaultValues(getValues().simultaneousSlotsCount));
	const isIntervalsLimitExceeded = fields.length >= 100;
	return (
		<div className={styles.wrap}>
			<h3 className={styles.header}>Интервалы времени</h3>
			<div>
				{fields.map((field, index) => (
					<TimeInterval
						key={field.id}
						index={index}
					/>
				))}
				<Button
					type="common"
					leftIcon={iconPlus}
					label="Добавить интервал"
					onClick={handleAddInterval}
					className={classNames(
						isIntervalsLimitExceeded ? styles.disabledButton : styles.activeButton
					)}
					disabled={isIntervalsLimitExceeded}
				/>
			</div>
			{isIntervalsLimitExceeded && (
				<p className={styles.limitErrorMessage}>
					Превышено допустимое количество интервалов (100 шт.)
				</p>
			)}
		</div>
	);
};
