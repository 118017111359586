import {IProcess, ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';
import {CSSProperties} from 'react';
import {useTranslatedObjectStagesArray} from '@src/core/hooks/translations/useTranslatedObjectStagesArray';
import {FieldLabel} from '../../FieldLabel/FieldLabel';
import {Select2, SelectOption, SelectPopup, TextFieldWithForwardedRef} from '@tehzor/ui-components';

const arrowIcon = <i className="tz-simple-arrow-20" />;

const getInputLabel = (
	value: ProcessIds | undefined,
	processes: IProcess[] | undefined
): string | undefined => {
	if (!value) {
		return undefined;
	}

	const selectedProcess = processes && processes.find(process => process.id === value);
	return selectedProcess?.name;
};

interface IObjectProcessSelect {
	onChange: (v: string) => void;
	value?: ProcessIds;
	stage?: string;
	className?: string;
	style?: CSSProperties;
	label?: string;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
	errorMessage?: string;
}

export const ObjectProcessSelect = ({
	onChange,
	value,
	stage,
	className,
	style,
	label = 'Процесс',
	required,
	disabled,
	hasError,
	errorMessage = 'Выберите процесс'
}: IObjectProcessSelect) => {
	const translatedStages = useTranslatedObjectStagesArray();
	const selectedStageObject = translatedStages.find(stageObj => stageObj.id === stage);

	const isError = hasError ? errorMessage : undefined;

	return (
		<div
			className={className}
			style={style}
		>
			<FieldLabel
				label={label}
				required={required}
			/>

			<SelectPopup
				noHeader
				trigger={
					<TextFieldWithForwardedRef
						elementType="div"
						value={getInputLabel(value, selectedStageObject?.processes)}
						icon={arrowIcon}
						error={isError}
						disabled={disabled}
					/>
				}
			>
				<Select2
					value={value}
					onChange={onChange}
				>
					{selectedStageObject?.processes.map(process => (
						<SelectOption
							key={process.id}
							itemKey={process.id}
							content={process.name}
							inputType="radio"
						/>
					))}
				</Select2>
			</SelectPopup>
		</div>
	);
};
