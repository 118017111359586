import {IIntervalFieldData} from '../../types/IIntervalFieldData';
import {timeStrToMinutes} from './timeStrToMinutes';

export const checkIsIntersect = (
	intervalA: IIntervalFieldData,
	intervalB: IIntervalFieldData
): boolean => {
	const timeRangeFromA = timeStrToMinutes(intervalA.timeRangeFrom);
	const timeRangeToA = timeStrToMinutes(intervalA.timeRangeTo);

	const timeRangeFromB = timeStrToMinutes(intervalB.timeRangeFrom);
	const timeRangeToB = timeStrToMinutes(intervalB.timeRangeTo);

	return Math.max(timeRangeFromA, timeRangeFromB) < Math.min(timeRangeToA, timeRangeToB);
};
