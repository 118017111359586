import {EntitiesTable, Pagination, PaginationAndSize, Plate} from '@tehzor/ui-components';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useCallback, useMemo} from 'react';
import styles from './ScheduledPlansTable.module.less';
import {formScheduledPlanLink} from '@tehzor/tools/utils/links/formScheduledPlanLink';
import {getDesktopColumns} from '../model/utils/getDesktopColumns';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {
	extractScheduledPlansPageSettings,
	scheduledPlansActions,
	usePreparedScheduledPlansList
} from '@/entities/ScheduledPlans';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {IPreparedScheduledPlan} from '@tehzor/tools/interfaces/scheduledPlans';
import {SelectionRow} from './SelectionRow/SelectionRow';
import {TranslatedPaginationPageSize} from '@src/components/TranslatedPaginationPageSize';
import {pageSizes} from '@/shared/constants/pageSizes';

interface IDesktopTableProps {
	total: number;
	objectId?: string;
}
export const ScheduledPlansTable = ({objectId = 'all', total}: IDesktopTableProps) => {
	const isDesktop = useIsDesktop();
	const dispatch = useAppDispatch();
	const {pushPath} = useChangePath();

	const {changeSort, changeSelectedRows, changePageSize, changeOffset} = scheduledPlansActions;
	const {sort, selectedRows, pageSize, offset} = useAppSelector(s =>
		extractScheduledPlansPageSettings(s, objectId)
	);

	const {data: preparedScheduledPlans} = usePreparedScheduledPlansList(objectId);

	const columns = useMemo(() => getDesktopColumns(styles.deleteCell), []);

	const pagesCount = Math.ceil((total ?? 0) / pageSize);
	const currentPage = Math.floor((offset ?? 0) / pageSize);

	const handleRowClick = useCallback(
		(scheduledPlan: IPreparedScheduledPlan) =>
			pushPath(formScheduledPlanLink(scheduledPlan.objectId, scheduledPlan.id)),
		[pushPath]
	);

	const handleSelection = useCallback(
		(value: string[]) => {
			dispatch(changeSelectedRows({objectId, selectedRows: value}));
		},
		[objectId, dispatch, changeSelectedRows]
	);

	const handleSortChange = useCallback(
		(value: Record<string, boolean>) => {
			dispatch(changeSort({objectId, sort: value}));
		},
		[objectId, dispatch, changeSort]
	);

	const handlePageSizeChange = useCallback(
		(value: number) => {
			dispatch(changePageSize({objectId, pageSize: value}));
			const newOffset = Math.floor((offset ?? 0) / value);
			dispatch(changeOffset({objectId, offset: newOffset}));
		},
		[dispatch, objectId, offset, changePageSize, changeOffset]
	);

	const handlePageChange = useCallback(
		({selected}: {selected: number}) => {
			const newOffset = selected * pageSize;
			if (offset !== newOffset) {
				dispatch(changeOffset({objectId, offset: newOffset}));
			}
		},
		[objectId, offset, pageSize, dispatch, changeOffset]
	);

	return (
		<>
			<Plate withoutPadding>
				<EntitiesTable<IPreparedScheduledPlan>
					columns={columns}
					data={preparedScheduledPlans ?? []}
					sort={sort}
					selectedRows={selectedRows}
					selectable={isDesktop}
					noRowBorder={!isDesktop}
					headVisible={isDesktop}
					onRowClick={handleRowClick}
					onSelectedRowsChange={handleSelection}
					onSortChange={handleSortChange}
					renderSelectionRow={SelectionRow}
					rowProps={() => ({
						className: styles.deleteCellHover
					})}
					rowWithHover
				/>
			</Plate>
			<PaginationAndSize
				className={styles.pagination}
				pagination={
					<Pagination
						pageCount={pagesCount}
						forcePage={currentPage}
						pageRangeDisplayed={3}
						marginPagesDisplayed={1}
						onPageChange={handlePageChange}
					/>
				}
				pageSize={
					<TranslatedPaginationPageSize
						pageSize={pageSize}
						pageSizeOptions={pageSizes}
						onPageSizeChange={handlePageSizeChange}
					/>
				}
			/>
		</>
	);
};
