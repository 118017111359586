import styles from './LimitedItemsList.module.less';
import {getStringWithComma} from '@/shared/utils/getStringWithComma';

interface ILimitedItemsList {
	items: string[];
	maxVisibleItems: number;
}

export const LimitedItemsList = ({items, maxVisibleItems}: ILimitedItemsList) => {
	if (!items?.length) return null;

	const limitedItems = items.slice(0, maxVisibleItems);
	const remainingCount = items.length > maxVisibleItems ? items.length - maxVisibleItems : 0;

	return (
		<div>
			{limitedItems.length > 0 && (
				<div className={styles.text}>{getStringWithComma(limitedItems)}</div>
			)}
			{remainingCount > 0 && (
				<span className={styles.remainingCount}>{`+${remainingCount}`}</span>
			)}
		</div>
	);
};
