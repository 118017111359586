import {MouseEvent, ReactNode, useCallback} from 'react';
import classNames from 'classnames';
import IconButton from '../../../buttons/IconButton';
import {AnimatePresence, motion} from 'framer-motion';
import {ITwoWayTreeItem} from '@tehzor/tools/interfaces/ITwoWayTreeItem';
import {useTreeCtx} from '../../utils/TreeCtxType';
import './RestrictedSelectionTreeItem.less';
import {IRestrictedSelectionTreeDataItem} from '../../interfaces/IRestrictedSelectionTreeDataItem';
import {SelectOption} from '../../../inputs';

interface ISingleParentTreeSelectProps<
	T extends IRestrictedSelectionTreeDataItem = IRestrictedSelectionTreeDataItem
> {
	data: ITwoWayTreeItem<T>;
	getContent?: (data: ITwoWayTreeItem<T>) => ReactNode;
	level: number;
	multiple?: boolean;
	disabled?: boolean;
	latestOnly?: boolean;
}

const animationVariants = {
	opened: {opacity: 1, height: 'auto'},
	collapsed: {
		opacity: 0,
		height: 0,
		transition: {
			duration: 0.25,
			// Анимация до своих потомков необходима для избежания бага с резким
			// сворачиванием всех потомков при сворачивании родителя
			when: 'beforeChildren',
			staggerChildren: 0.5
		}
	}
};

const animationTransition = {type: 'tween', duration: 0.25};

export const RestrictedSelectionTreeItem = <T extends IRestrictedSelectionTreeDataItem>({
	data,
	getContent,
	level,
	multiple,
	disabled: treeDisabled,
	latestOnly
}: ISingleParentTreeSelectProps<T>) => {
	const {id, content, children, parentId} = data;
	const {
		isParentExpanded,
		isParentSelected,
		isChildSelected,
		changeChildSelected,
		changeParentSelected,
		changeParentExpanded
	} = useTreeCtx();

	const inputType = children?.length ? 'radio' : 'checkbox';

	const handleExpand = useCallback(
		(event: MouseEvent) => {
			event.stopPropagation();
			changeParentExpanded(id, !isParentExpanded(id));
		},
		[id, changeParentExpanded, isParentExpanded]
	);
	const handleClick = useCallback(
		() =>
			children?.length
				? changeParentSelected(id, !isParentSelected(id))
				: changeChildSelected(id, parentId, !isChildSelected(id)),
		[
			children,
			id,
			parentId,
			changeParentSelected,
			isParentSelected,
			changeChildSelected,
			isChildSelected
		]
	);

	return (
		<li data-testid={`TreeItem_${level}`} className="tree-select-option-wrap">
			<SelectOption
				dataTestId={`TreeItem_${level}`}
				className={{
					root: classNames('tree-select-option', `tree-select-option_level_${level}`),
					content: classNames('tree-select-option__content')
				}}
				content={
					<>
						{children && (
							<IconButton
								dataTestId="TreeItemExpandBtn"
								className="tree-select-option__expand-btn"
								type="transparent"
								onClick={handleExpand}
							>
								<i
									className={classNames(
										'tz-simple-arrow-20',
										'tree-select-option__expand-btn-icon',
										{
											'tree-select-option__expand-btn-icon_expanded':
												isParentExpanded(data.id)
										}
									)}
								/>
							</IconButton>
						)}
						{getContent ? getContent(data) : content}
					</>
				}
				inputType={children && latestOnly ? null : inputType}
				checked={children?.length ? isParentSelected(id) : isChildSelected(id)}
				disabled={treeDisabled}
				onClick={handleClick}
			/>

			{children !== undefined && (
				<AnimatePresence initial={false}>
					{isParentExpanded(data.id) && (
						<motion.ul
							className="tree-select-option__children"
							initial="collapsed"
							animate="opened"
							exit="collapsed"
							variants={animationVariants}
							transition={animationTransition}
						>
							{children.map(item => (
								<RestrictedSelectionTreeItem
									key={item.id}
									data={item}
									getContent={getContent}
									multiple={multiple}
									disabled={treeDisabled}
									level={level + 1}
								/>
							))}
						</motion.ul>
					)}
				</AnimatePresence>
			)}
		</li>
	);
};
