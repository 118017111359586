import {IGetScheduledPlansResponse} from '@/shared/api/scheduledPlans/getScheduledPlansPaginate';
import {useQuery} from '@tanstack/react-query';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractScheduledPlansPageSettings} from '../../../slice/selectors';
import {scheduledPlansQueryKeys} from '../../keys';

export const useScheduledPlansPaginateList = <T = IGetScheduledPlansResponse>(
	objectId: string,
	select?: (data: IGetScheduledPlansResponse) => T
) => {
	const {filters, sort, offset, pageSize} = useAppSelector(s =>
		extractScheduledPlansPageSettings(s, objectId)
	);

	return useQuery({
		queryKey: [...scheduledPlansQueryKeys.paginate(), filters, sort, offset, pageSize],
		select,
		meta: {
			error: 'при загрузке списка план-графиков'
		}
	});
};
