import {DateRangeTo} from '../../ui/DateRangeTo';
import {DateRangeFrom} from '../../ui/DateRangeFrom';
import styles from './DateRange.module.less';
import classNames from 'classnames';
import {FieldLabel} from '@/shared/ui/FieldLabel/FieldLabel';
import {IScheduleForm} from '../../types/IScheduleForm';
import {ScheduleFieldValueType} from '../../types/IScheduleFieldProps';
import {useParticipantsErrorHandler} from '../../hooks/schedules/useParticipantsErrorHandling';

const dashIcon = <i className={classNames('tz-minus', styles.icon)} />;

interface IDateRange {
	onSetValue: (name: keyof IScheduleForm, value?: ScheduleFieldValueType) => void;
	fieldDateFrom: keyof IScheduleForm;
	fieldDateTo: keyof IScheduleForm;
	className: string;
}

export const DateRange = ({onSetValue, fieldDateFrom, fieldDateTo, className}: IDateRange) => {
	const {clearParticipantsOverlapError} = useParticipantsErrorHandler();

	const handleSetValue = (name: keyof IScheduleForm, value?: ScheduleFieldValueType) => {
		onSetValue(name, value);
		clearParticipantsOverlapError();
	};

	return (
		<div className={className}>
			<FieldLabel label="Диапазон дат" />
			<div className={styles.container}>
				<DateRangeFrom
					onSetValue={handleSetValue}
					field={fieldDateFrom}
					className={styles.datePicker}
					required
				/>
				{dashIcon}
				<DateRangeTo
					onSetValue={handleSetValue}
					field={fieldDateTo}
					className={styles.datePicker}
				/>
			</div>
		</div>
	);
};
