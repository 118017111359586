import {schedulesQueryKeys} from '@/entities/Schedules';
import {extractIntervalsListAsArray, intervalsQueryKeys} from '@/entities/TimeIntervals';
import {oneArgIsTrue} from '@/shared/utils/oneArgIsTrue';
import {useQueries, UseQueryResult} from '@tanstack/react-query';
import {ISchedule, ITimeInterval} from '@tehzor/tools/interfaces/schedules';
import {useCallback} from 'react';

export const useScheduleWithIntervals = (scheduleId?: string) => {
	const combine = useCallback(
		(result: [UseQueryResult<ISchedule>, UseQueryResult<ITimeInterval[]>]) => {
			const [scheduleResult, intervalsResult] = result;
			const {data: schedule, isLoading: isScheduleLoading} = scheduleResult;
			const {data: intervals, isLoading: isIntervalsLoading} = intervalsResult;

			if (isIntervalsLoading) {
				return {data: undefined, isLoading: true};
			}

			return {
				data: schedule ? {...schedule, intervals} : undefined,
				isLoading: oneArgIsTrue(isScheduleLoading, isIntervalsLoading)
			};
		},
		[]
	);

	return useQueries({
		queries: [
			{
				queryKey: schedulesQueryKeys.detail(scheduleId),
				enabled: !!scheduleId
			},
			{
				queryKey: intervalsQueryKeys.byScheduleId(scheduleId),
				select: extractIntervalsListAsArray,
				enabled: !!scheduleId
			}
		],
		combine
	});
};
