import {IconButton, Menu, MenuItem, TextFieldWithForwardedRef} from '@tehzor/ui-components';
import {TranslatedSelectPopup} from '@src/components/TranslatedSelectPopup';
import styles from './TimeDurationSelect.module.less';
import {Dispatch, SetStateAction, memo} from 'react';
import {IDurationMeasure, IDurationMeasures} from '@/shared/types/IDurationMeasure';
import {useDurationSelectDialog} from '../../DurationSelectDialog/hooks/useDurationSelectDialog';
import {FieldLabel} from '../../FieldLabel/FieldLabel';
import {formattedTimeDuration} from '../../../utils/formattedTimeDuration';

const arrowIcon = <i className="tz-simple-arrow-20" />;

interface ITimeDurationSelect {
	duration: number;
	setDuration: Dispatch<SetStateAction<number>>;
	durationMeasure: IDurationMeasure;
	setDurationMeasure: Dispatch<SetStateAction<IDurationMeasure>>;
	value: number;
	error?: string;
	dialogError: (duration: number, durationMeasure?: IDurationMeasure) => string | undefined;
	label?: string;
	required?: boolean;
	durationMeasures: IDurationMeasures;
	durationOptions: number[];
	disableDurationMeasure?: boolean;
	showZeroHours?: boolean;
	className?: string;
}

const icon = <i className="tz-settings-24" />;

export const TimeDurationSelect = memo(
	({
		value,
		dialogError,
		label,
		duration,
		setDuration,
		durationMeasure,
		setDurationMeasure,
		error,
		durationMeasures,
		durationOptions,
		disableDurationMeasure = false,
		showZeroHours = false,
		required = false,
		className
	}: ITimeDurationSelect) => {
		const handleChange = (d: string) => {
			setDuration(Number(d));
		};

		const [durationSelectDialog, openDurationSelectDialog] = useDurationSelectDialog({
			duration,
			setDuration,
			durationMeasure,
			setDurationMeasure,
			dialogError,
			title: label,
			durationMeasures,
			disableDurationMeasure
		});

		const formattedDuration = (durationValue: number) =>
			formattedTimeDuration(durationValue, showZeroHours, durationMeasure.key);

		return (
			<div className={className}>
				{label && (
					<FieldLabel
						label={label}
						required={required}
					/>
				)}
				<div className={styles.selectContainer}>
					<TranslatedSelectPopup
						trigger={
							<TextFieldWithForwardedRef
								elementType="div"
								value={formattedDuration(duration)}
								className={styles.trigger}
								icon={arrowIcon}
								error={error}
							/>
						}
					>
						<Menu
							value={value.toString()}
							onChange={handleChange}
						>
							{durationOptions.map(i => (
								<MenuItem
									key={i.toString()}
									itemKey={i.toString()}
								>
									<div>{formattedDuration(i)}</div>
								</MenuItem>
							))}
						</Menu>
					</TranslatedSelectPopup>
					<IconButton
						className={styles.iconButton}
						onClick={openDurationSelectDialog}
					>
						{icon}
					</IconButton>
				</div>
				{durationSelectDialog}
			</div>
		);
	}
);
