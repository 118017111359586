import {memo, useCallback, useMemo, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import {makeFilterLabel} from '@src/components/EntitiesFilters/utils/makeFilterLabel';
import {FilterButton, TreeSelect} from '@tehzor/ui-components';
import {useEntitiesFiltersCtx} from '../utils/entitiesFiltersCtx';
import SelectSearch, {
	treeFilter
} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {useTranslation} from 'react-i18next';
import {TranslatedSelectPopup} from '@src/components/TranslatedSelectPopup';
import {useTranslatedObjectStagesArray} from '@src/core/hooks/translations/useTranslatedObjectStagesArray';
import {makeProcessesTreeData} from '@src/utils/makeProcessesTreeData';

export interface IObjectsFilterProps {
	filterName?: string;
	value?: string[];
	className?: string;
	placeholder?: string;
}

export const ProcessesFilter = memo((props: IObjectsFilterProps) => {
	const {
		value,
		className,
		placeholder = 'selectSearch.placeholder',
		filterName = 'processes'
	} = props;
	const {t} = useTranslation();

	const {dispatch} = useEntitiesFiltersCtx();
	const [selectedProcesses, setSelectedProcesses] = useState<string[] | undefined>(value);
	const [expandedProcesses, setExpandedProcesses] = useState<string[] | undefined>([]);
	const [search, setSearch] = useState('');

	const clearSearch = useCallback(() => setSearch(''), []);

	const translatedStages = useTranslatedObjectStagesArray();
	const treeData = useMemo(() => {
		if (!translatedStages) return [];

		return makeProcessesTreeData(translatedStages);
	}, [translatedStages]);

	const {filteredData, expanded} = useMemo(
		() => treeFilter(treeData, 'content', search),
		[search, treeData]
	);

	useUpdateEffect(() => {
		setExpandedProcesses(expanded?.map(item => item.id));
	}, [expanded]);

	const handleApply = useCallback(() => {
		const changes = {[filterName]: selectedProcesses};
		dispatch(changes);
		clearSearch();
	}, [selectedProcesses, dispatch, clearSearch, filterName]);

	const handleExpand = useCallback((v?: string[]) => {
		setExpandedProcesses(v);
	}, []);

	const handleClear = useCallback(() => {
		setSelectedProcesses([]);
		clearSearch();
	}, [clearSearch]);

	const handleFullClear = useCallback(() => {
		dispatch({[filterName]: undefined});
		setSelectedProcesses([]);
		setExpandedProcesses([]);
		clearSearch();
	}, [dispatch, clearSearch, filterName]);

	const handleCancel = useCallback(() => {
		setSelectedProcesses(value);
		clearSearch();
	}, [value, clearSearch]);

	const handleSelectAll = useCallback(() => {
		clearSearch();
		if (treeData?.length) {
			setSelectedProcesses([...treeData.map(item => item.id)]);
		}
	}, [treeData, clearSearch]);

	useUpdateEffect(() => {
		setExpandedProcesses([]);
		setSelectedProcesses(value);
	}, [value]);

	return (
		<TranslatedSelectPopup
			onCancel={handleCancel}
			onApply={handleApply}
			onClear={handleClear}
			className={className}
			clearButton={!!selectedProcesses?.length}
			footer
			count={selectedProcesses?.length}
			onSelectAll={handleSelectAll}
			search={
				<SelectSearch
					value={search}
					placeholder={t(placeholder)}
					onChange={setSearch}
					type="popup"
				/>
			}
			trigger={
				<FilterButton
					className="entities-filters__item"
					label={makeFilterLabel(t('processes.processes'), value, treeData)}
					active={!!value?.length}
					onClear={handleFullClear}
				/>
			}
		>
			<TreeSelect
				data={filteredData}
				multiple
				value={selectedProcesses}
				onChange={setSelectedProcesses}
				expandedValue={expandedProcesses}
				onExpand={handleExpand}
			/>
		</TranslatedSelectPopup>
	);
});
