import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {Select2, SelectOption} from '@tehzor/ui-components';
import {useMemo} from 'react';
import {getFilteredData} from '../utils/schedules/participantsSelect/getFilteredData';

interface IUsersTabContent {
	tabIndex: number;
	selectedUsers?: string[];
	handleSelectUsersResetGroup: (v?: string[]) => void;
	users: IBriefUser[] | undefined;
	search: string;
}

export const UsersTabContent = ({
	tabIndex,
	users,
	search,
	selectedUsers,
	handleSelectUsersResetGroup
}: IUsersTabContent) => {
	const filteredData = useMemo(
		() => getFilteredData(tabIndex, search, users),
		[tabIndex, search, users]
	);

	return (
		<Select2
			multiple
			value={selectedUsers}
			onChange={handleSelectUsersResetGroup}
		>
			{filteredData?.map(item => (
				<SelectOption
					dataTestId="MenuItem"
					key={item.id}
					itemKey={item.id}
					content={item.fullName}
				/>
			))}
		</Select2>
	);
};
