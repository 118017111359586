import {nanoid} from '@reduxjs/toolkit';
import {FrequencyIds} from '@tehzor/tools/interfaces/schedules';

export const getDefaultValues = (simultaneousSlotsCount: number | undefined) => ({
	id: nanoid(),
	timeRangeFrom: '',
	timeRangeTo: '',
	dateRangeFrom: new Date(),
	repeatType: {id: FrequencyIds.EVERY_WEEK, name: 'Каждую неделю'},
	selectedDays: [],
	simultaneousSlots: simultaneousSlotsCount || ''
});
