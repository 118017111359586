import {ExpandablePlate} from '@tehzor/ui-components';
import styles from './AdvancedIntervalSettings.module.less';
import {IntervalSimultaneousSlots} from '../IntervalSimultaneousSlots';
import {IntervalsDateRange} from '../IntervalsDateRange/IntervalsDateRange';
import {getIntervalField} from '../../utils/intervals/getIntervalField';
import {IntervalFields} from '../../types/IntervalFields';

const icon = <i className="tz-simple-arrow-20" />;

interface IAdvancedIntervalSettings {
	index: number;
}

export const AdvancedIntervalSettings = ({index}: IAdvancedIntervalSettings) => (
	<ExpandablePlate
		title={{expanded: 'Скрыть', collapsed: 'Подробнее'}}
		className={{
			root: styles.root,
			content: styles.content,
			expandButton: styles.rootButton,
			expandButtonIcon: styles.rightIcon,
			expandButtonLabel: styles.label
		}}
		icon={icon}
		initiallyClosed
	>
		<IntervalsDateRange
			fieldDateFrom={getIntervalField(index, IntervalFields.DATE_RANGE_FROM)}
			fieldDateTo={getIntervalField(index, IntervalFields.DATE_RANGE_TO)}
			dashIconElement={<span className={styles.rangeDash} />}
			className={styles.dateRange}
		/>
		<IntervalSimultaneousSlots
			field={getIntervalField(index, IntervalFields.SIMULTANEOUS_SLOTS)}
			required
			className={styles.slots}
		/>
	</ExpandablePlate>
);
