import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {
	ISchedulesPageSettingsState,
	ISchedulesPageSizePayload,
	ISchedulesSortState
} from './interfaces';
import {IChangeSortPayload} from '@src/store/interfaces/IChangeSortPayload';

export const getInitialStateForPage = (): ISchedulesPageSettingsState => ({
	sort: {createdAt: false},
	filters: {},
	pageSize: 20,
	offset: 0,
	selectedRows: []
});

type IChangeSortPayloadNew = Omit<IChangeSortPayload<ISchedulesSortState>, 'objectId'>;

export const schedulesSlice = createSlice({
	name: 'schedules',
	initialState: getInitialStateForPage,
	reducers: {
		changeSort(state, action: PayloadAction<IChangeSortPayloadNew>) {
			state.sort = action.payload.sort;
		},
		changeSelectedRows(state, action: PayloadAction<{selectedRows: string[]}>) {
			state.selectedRows = action.payload.selectedRows;
		},
		changePageSize(state, action: PayloadAction<ISchedulesPageSizePayload>) {
			state.pageSize = action.payload.pageSize;
		},
		changeOffset(state, action: PayloadAction<{offset: number}>) {
			state.offset = action.payload.offset;
		}
	}
});
export const {actions: schedulesActions, reducer: schedulesReducer} = schedulesSlice;
