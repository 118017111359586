import useAppSelector from '@src/core/hooks/useAppSelector';
import {IAddProblemForm} from '../useEditableProblemForm';
import {extractUserProfile} from '@src/store/modules/auth/profile/selectors';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import {useMemo} from 'react';

export const useDefaultProblemData = (
	defaultData?: ISavingProblem,
	problem?: Omit<
		IProblem,
		'createdBy' | 'modifiedBy' | 'initialGroupLeader' | 'activeGroupLeader'
	>
): IAddProblemForm | undefined => {
	const user = useAppSelector(extractUserProfile);
	const defaultInspector = user ? [user.id] : [];
	return useMemo(
		() => ({
			categoryId: problem?.categoryId || defaultData?.categoryId || undefined,
			plannedFixDate: problem?.plannedFixDate || defaultData?.plannedFixDate || undefined,
			reason: problem?.reason || defaultData?.reason || undefined,
			planId: problem?.plan?.id || defaultData?.planId || undefined,
			location: problem?.location || defaultData?.location || undefined,
			floor: problem?.floor || defaultData?.floor || undefined,
			inspectors: problem?.inspectors || defaultData?.inspectors || defaultInspector,
			inspectorsActiveGroup:
				problem?.inspectorsActiveGroup || defaultData?.inspectorsActiveGroup || undefined,
			performers: problem?.performers || defaultData?.performers || [],
			watchers: problem?.watchers || defaultData?.watchers || [],
			performersActiveGroup:
				problem?.performersActiveGroup || defaultData?.performersActiveGroup || undefined,
			description: problem?.description || defaultData?.description || undefined,
			prescription: problem?.prescription || defaultData?.prescription || undefined,
			attachments: problem?.attachments || defaultData?.attachments || [],
			problemTags: problem?.problemTags || defaultData?.problemTags || [],
			critical: problem?.critical || defaultData?.critical || false,
			contractId: problem?.contractId || defaultData?.contractId || undefined,
			customFields: problem?.customFields || defaultData?.customFields || {},
			initialRuleParams: {
				categoryId: problem?.categoryId || defaultData?.categoryId || undefined,
				planId: problem?.plan?.id || defaultData?.planId || undefined
			},
			templateId: undefined
		}),
		[defaultData, problem, user]
	);
};
