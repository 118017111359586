import {DependencyList, useContext, useEffect} from 'react';
import {AppHeaderCtx, AppHeaderCtxOptions} from '../utils/AppHeaderCtx';
import useUnmount from 'react-use/lib/useUnmount';

export function useAppHeader(options?: AppHeaderCtxOptions, deps: DependencyList = []) {
	const fn = useContext(AppHeaderCtx);

	useUnmount(() => {
		// Обнуление данных в шапке при unmount'е
		fn();
	});

	useEffect(() => {
		// Обновление данных при mount'е и изменении параметров
		fn(options);
	}, deps);
}
