import {IconButton} from '../buttons';
import {CircleLoader} from '../loaders';
import {ListLoader} from '../loaders/skeletons';
import './EntityCountIndicator.less';

const reloadIcon = <i className="tz-reload-20" />;

interface IEntityCountIndicatorProps {
	text: string;
	isFetching: boolean;
	totalEntities?: number;
	onClick: () => void;
}

export const EntityCountIndicator = ({
	text,
	isFetching,
	totalEntities,
	onClick
}: IEntityCountIndicatorProps) => (
	<div className="entity-indicators">
		<span className="entity-indicators__title">{text}</span>
		{!isFetching ? (
			<span className="entity-indicators__title">{totalEntities}</span>
		) : (
			<ListLoader />
		)}
		{!isFetching ? (
			<IconButton type="inline-blue-accent" onClick={onClick}>
				{reloadIcon}
			</IconButton>
		) : (
			<div className="entity-indicators__loader-container">
				<CircleLoader className="entity-indicators__circle-loader" />
			</div>
		)}
	</div>
);
