import {makeUserGroupsTreeData} from '@/shared/lib/EntitiesFilters/utils/makeUserGroupsTreeData';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';

export const getTreeData = (
	tabIndex: number,
	workingGroups?: IWorkingGroup[],
	usersMap?: Record<string, IBriefUser>
) => {
	if (tabIndex !== 1 || !usersMap || !workingGroups?.length) {
		return [];
	}

	return makeUserGroupsTreeData(workingGroups, usersMap);
};
