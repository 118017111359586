import {memo, useState} from 'react';
import {ProblemsTable} from '../../ProblemsTable';
import './ItemDetail.desktop.less';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useExtractTreeCheckItemById} from '@src/core/hooks/queries/checkItems/hooks';
import {TasksTable} from '../../TasksTable';
import {CheckDetailTabs, ITabItem} from '../../Tabs/CheckDetailTabs';
import {useAddingCheckListProblemDialog} from '../../../../hooks/useAddingCheckListProblemDialog';
import {useAddingCheckListTaskDialog} from '../../../../hooks/useAddingCheckListTaskDialog';
import {DetailAction} from '../../DetailAction/DetailAction';
import {InspectionsTable} from '../../InspectionsTable';
import {useAddingCheckListInspectionDialog} from '@src/pages/CheckListPage/hooks/useAddingCheckListInspectionDialog';
import {IDesktopItemDetailPermissions} from '../../../DesktopDetailColumn';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useExtractCheckListById} from '@src/core/hooks/queries/checkLists/hooks';
import {useAddingWorkAcceptanceCheckListProblemDialog} from '@src/pages/WorkAcceptancePage/hooks/useAddingWorkAcceptanceCheckListProblemDialog';
import {useAddingWorkAcceptanceCheckListInspectionDialog} from '@src/pages/WorkAcceptancePage/hooks/useAddingWorkAcceptanceCheckListInspectionDialog';
import {useTranslation} from 'react-i18next';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';
import {getTaskType} from '@src/pages/CheckListPage/utils/getTaskType';

interface IDesktopItemDetailProps {
	permissions: IDesktopItemDetailPermissions;
}

const iconPlus = <i className="tz-plus-16" />;

const tabsClassNames = {
	links: 'check-item-detail__tabs-links',
	link: 'check-item-detail__tabs-link'
};

export const DesktopItemDetail = memo(({permissions}: IDesktopItemDetailProps) => {
	const {t} = useTranslation();
	const {listId, itemId, stage, spaceId, workAcceptanceId} = useStrictParams<{
		spaceId?: string;
		workAcceptanceId?: string;
		objectId: string;
		listId: string;
		itemId: string;
		stage: ObjectStageIds;
	}>();
	const hideInspections = stage === ObjectStageIds.WARRANTY || stage === ObjectStageIds.TRANSFER;
	const tabs = {
		problems: 0,
		inspections: hideInspections ? undefined : 1,
		tasks: hideInspections ? 1 : 2
	};
	const {canAddTask, canAddProblem, canAddInspection} = permissions;
	const [activeTab, setActiveTab] = useState(tabs.problems);
	const {data: item} = useExtractTreeCheckItemById(listId, itemId);
	const [problemSpaceDialog, handleOpenProblemSpaceDialog] =
		useAddingCheckListProblemDialog(itemId);
	const [inspectionSpaceDialog, handleOpenInspectionSpaceDialog] =
		useAddingCheckListInspectionDialog(itemId);

	const [problemWorkAcceptanceDialog, handleOpenProblemWorkAcceptanceDialog] =
		useAddingWorkAcceptanceCheckListProblemDialog(listId, itemId);
	const [inspectionWorkAcceptanceDialog, handleOpenInspectionWorkAcceptanceDialog] =
		useAddingWorkAcceptanceCheckListInspectionDialog(listId, itemId);

	const problemDialog = spaceId ? problemSpaceDialog : problemWorkAcceptanceDialog;
	const handleOpenProblemDialog = spaceId
		? handleOpenProblemSpaceDialog
		: handleOpenProblemWorkAcceptanceDialog;

	const inspectionDialog = spaceId ? inspectionSpaceDialog : inspectionWorkAcceptanceDialog;
	const handleOpenInspectionDialog = spaceId
		? handleOpenInspectionSpaceDialog
		: handleOpenInspectionWorkAcceptanceDialog;

	const {data: checkList} = useExtractCheckListById(listId);
	const taskType = getTaskType(checkList?.processId);

	const [taskDialog, handleOpenTaskDialog] = useAddingCheckListTaskDialog({
		taskType
	});
	const tabsContent: ITabItem[] = [
		{
			value: t('checkListPage.detail.itemDetail.tabs.problems.value'),
			content: <ProblemsTable item={item} />
		}
	];

	if (!hideInspections) {
		tabsContent.push({
			value: t('checkListPage.detail.itemDetail.tabs.inspections.value'),
			content: <InspectionsTable item={item} />
		});
	}
	tabsContent.push({
		value: t('checkListPage.detail.itemDetail.tabs.tasks.value'),
		content: <TasksTable item={item} />
	});

	const showAction: boolean =
		(!!spaceId && checkList?.processId !== ProcessIds.ACCEPTANCE_CONTROL) ||
		(!!workAcceptanceId && checkList?.processId === ProcessIds.ACCEPTANCE_CONTROL);

	const showDetailTaskAction = showAction && canAddTask && activeTab === tabs.tasks;
	const showDetailProblemAction = showAction && canAddProblem && activeTab === tabs.problems;
	const showDetailInspectionAction =
		showAction && canAddInspection && !hideInspections && activeTab === tabs.inspections;

	if (!item) {
		return null;
	}

	return (
		<div className="check-item-detail__container">
			<div className="check-item-detail__scrollable">
				<CheckDetailTabs
					tabs={tabsContent}
					onTabClick={setActiveTab}
					activeTab={activeTab}
					tabsClassNames={tabsClassNames}
					itemsClassName="check-item-detail__items"
				>
					{showDetailTaskAction && (
						<DetailAction
							leftIcon={iconPlus}
							onClick={handleOpenTaskDialog}
							label={t('checkListPage.detail.itemDetail.detailAction.task.label')}
						/>
					)}

					{showDetailProblemAction && (
						<DetailAction
							leftIcon={iconPlus}
							onClick={handleOpenProblemDialog}
							label={t('checkListPage.detail.itemDetail.detailAction.problem.label')}
						/>
					)}

					{showDetailInspectionAction && (
						<DetailAction
							leftIcon={iconPlus}
							onClick={handleOpenInspectionDialog}
							label={t(
								'checkListPage.detail.itemDetail.detailAction.inspection.label'
							)}
						/>
					)}
				</CheckDetailTabs>
				{showAction && canAddProblem && problemDialog}
				{showAction && canAddTask && taskDialog}
				{showAction && canAddInspection && inspectionDialog}
			</div>
		</div>
	);
});
