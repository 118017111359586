import {combineReducers} from 'redux';
import {checkListsReducer} from './checkLists/slice';
import {workingGroupsReducer} from './workingGroups/slice';
import categoriesSets from './categoriesSets/reducers';
import {spaceStatusesSets} from './spaceStatusesSets/reducers';
import {fieldsSettingsSets} from '@/entities/FieldsSettingsSets';
import {customFieldsReducer, builtInFieldsReducer} from '@/entities/Fields';

export default combineReducers({
	checkLists: checkListsReducer,
	workingGroups: workingGroupsReducer,
	categoriesSets,
	spaceStatusesSets,
	fieldsSettingsSets,
	customFields: customFieldsReducer,
	builtInFields: builtInFieldsReducer
});
