export enum IntervalFields {
	ID = 'id',
	TIME_RANGE_FROM = 'timeRangeFrom',
	TIME_RANGE_TO = 'timeRangeTo',
	DATE_RANGE_FROM = 'dateRangeFrom',
	DATE_RANGE_TO = 'dateRangeTo',
	REPEAT_TYPE = 'repeatType',
	SELECTED_DAYS = 'selectedDays',
	SIMULTANEOUS_SLOTS = 'simultaneousSlots'
}
