import {IPreparedScheduledPlan} from '@tehzor/tools/interfaces/scheduledPlans';
import {CellProps} from 'react-table';
import styles from './ScheduledPlanNameCell.module.less';

export const ScheduledPlanNameCell = ({row}: CellProps<IPreparedScheduledPlan>) => {
	const {name, description} = row.original;
	return (
		<div className={styles.cell}>
			<div className={styles.cellTitle}>{name}</div>
			{description && <div className={styles.cellSubtitle}>{description}</div>}
		</div>
	);
};
