import {CSSProperties, ReactNode} from 'react';
import './ExpandablePlate.less';
import classNames from 'classnames';
import Plate from '../Plate';
import ExpandablePanel from '../ExpandablePanel';
import useToggle from 'react-use/lib/useToggle';
import ExpandButton from './components/ExpandButton';
import {convertClassNames} from '../../../utils/convertClassNames';

type ITitle =
	| ReactNode
	| {
			expanded: ReactNode;
			collapsed: ReactNode;
	  };

const getLabel = (title: ITitle, expanded: boolean) => {
	if (typeof title !== 'object') return title;

	if (title && 'expanded' in title && 'collapsed' in title) {
		return expanded ? title.expanded : title.collapsed;
	}

	return null;
};

interface IExpandablePlateProps {
	className?:
		| string
		| {
				root?: string;
				expandButton?: string;
				expandButtonIcon?: string;
				expandButtonLabel?: string;
				content?: string;
		  };
	style?: CSSProperties;
	children?: ReactNode;
	title?: ITitle;
	initiallyClosed?: boolean;
	icon?: ReactNode;
}

const ExpandablePlate = ({
	className,
	style,
	children,
	title,
	initiallyClosed,
	icon
}: IExpandablePlateProps) => {
	const [expanded, toggleExpanded] = useToggle(!initiallyClosed);
	const classes = convertClassNames(className);

	const label = getLabel(title, expanded);
	return (
		<Plate
			className={{
				root: classNames('expandable-plate', classes.root),
				content: 'expandable-plate__plate-content'
			}}
			style={style}
			header={
				<ExpandButton
					className={{
						root: classNames('expandable-plate__expand-btn', classes.expandButton),
						rightIconClassName: classes.expandButtonIcon,
						labelClassName: classes.expandButtonLabel
					}}
					label={label}
					expanded={expanded}
					onClick={toggleExpanded}
					icon={icon}
				/>
			}
		>
			<ExpandablePanel expanded={expanded}>
				<div className={classNames('expandable-plate__content', classes.content)}>
					{children}
				</div>
			</ExpandablePanel>
		</Plate>
	);
};

ExpandablePlate.displayName = 'ExpandablePlate';

export default ExpandablePlate;
