import {ISchedule} from '@tehzor/tools/interfaces/schedules/ISchedule';

import {EntitiesTable, Pagination, PaginationAndSize, Plate} from '@tehzor/ui-components';

import {useIsDesktop} from '@/shared/hooks/dimensions/useIsDesktop/useIsDesktop';
import {useCallback} from 'react';
import {SelectionRow} from './SelectionRow/SelectionRow';
import styles from './SchedulesTable.module.less';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {
	extractSchedulesPageSettings,
	schedulesActions,
	useSchedulesPaginateListAsArray
} from '@/entities/Schedules';
import {TranslatedPaginationPageSize} from '@src/components/TranslatedPaginationPageSize';
import {pageSizes} from '@/shared/constants/pageSizes';
import {useTableColumns} from '../model/hooks/useTableColumns';
import {useChangePath} from '@src/core/hooks/useChangePath';

export const SchedulesTable = ({total}: {total: number}) => {
	const columns = useTableColumns(styles.deleteCell);
	const {data: schedules} = useSchedulesPaginateListAsArray();
	const isDesktop = useIsDesktop();
	const dispatch = useAppDispatch();
	const {pushPath} = useChangePath();
	const {changeSort, changeSelectedRows, changePageSize, changeOffset} = schedulesActions;
	const {sort, selectedRows, pageSize, offset} = useAppSelector(s =>
		extractSchedulesPageSettings(s)
	);

	const pagesCount = Math.ceil((total ?? 0) / pageSize);
	const currentPage = Math.floor((offset ?? 0) / pageSize);

	const handleRowClick = useCallback(
		(schedule: ISchedule) => {
			pushPath(`/manage/schedules/${schedule.id}`);
		},
		[pushPath]
	);

	const handleSelection = useCallback(
		(value: string[]) => {
			dispatch(changeSelectedRows({selectedRows: value}));
		},
		[changeSelectedRows, dispatch]
	);

	const handleSortChange = useCallback(
		(value: Record<string, boolean>) => {
			dispatch(changeSort({sort: value}));
		},
		[changeSort, dispatch]
	);

	const handlePageSizeChange = useCallback(
		(value: number) => {
			dispatch(changePageSize({pageSize: value}));
			const newOffset = Math.floor((offset ?? 0) / value);
			dispatch(changeOffset({offset: newOffset}));
		},
		[dispatch, offset, changePageSize, changeOffset]
	);

	const handlePageChange = useCallback(
		({selected}: {selected: number}) => {
			const newOffset = selected * pageSize;
			if (offset !== newOffset) {
				dispatch(changeOffset({offset: newOffset}));
			}
		},
		[offset, pageSize, dispatch, changeOffset]
	);

	return (
		<>
			<Plate withoutPadding>
				<EntitiesTable
					data={schedules ?? []}
					columns={columns}
					headVisible={isDesktop}
					onSelectedRowsChange={handleSelection}
					renderSelectionRow={SelectionRow}
					onRowClick={handleRowClick}
					onSortChange={handleSortChange}
					selectedRows={selectedRows}
					sort={sort}
					rowProps={() => ({
						className: styles.deleteCellHover
					})}
					rowWithHover
					selectable
				/>
			</Plate>
			<PaginationAndSize
				className={styles.pagination}
				pagination={
					<Pagination
						pageCount={pagesCount}
						forcePage={currentPage}
						pageRangeDisplayed={3}
						marginPagesDisplayed={1}
						onPageChange={handlePageChange}
					/>
				}
				pageSize={
					<TranslatedPaginationPageSize
						pageSize={pageSize}
						pageSizeOptions={pageSizes}
						onPageSizeChange={handlePageSizeChange}
					/>
				}
			/>
		</>
	);
};
