import {FieldNamesMarkedBoolean} from 'react-hook-form';
import {ISavingSchedule} from '@tehzor/tools/interfaces/schedules';
import {IScheduleForm} from '../../types/IScheduleForm';
import {ScheduleFieldValueType} from '../../types/IScheduleFieldProps';
import {convertIntervalsFormData} from '../intervals/convertIntervalsFormData';

export const getPreparedMainInfoFormData = (
	scheduleId: string | undefined,
	data: IScheduleForm,
	dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<IScheduleForm>>>
): Partial<ISavingSchedule> => {
	if (!scheduleId) {
		return {
			...data,
			dateRangeFrom: data.dateRangeFrom ? new Date(data.dateRangeFrom).getTime() : undefined,
			dateRangeTo: data.dateRangeTo ? new Date(data.dateRangeTo).getTime() : undefined,
			objectIds: data.objectIds?.length ? data.objectIds : undefined,
			intervals: convertIntervalsFormData(data.intervals || [])
		};
	}
	const updatedFields = Object.fromEntries(
		Object.keys(dirtyFields).map(key => {
			let value = data[key as keyof IScheduleForm] as ScheduleFieldValueType | null;

			if (value === undefined) {
				value = null;
			}
			if (key === 'dateRangeFrom' || key === 'dateRangeTo') {
				value = value ? new Date(value as Date).getTime() : null;
			}
			return [key, value];
		})
	) as Partial<ISavingSchedule>;

	const preparedTImeIntervals = convertIntervalsFormData(data.intervals || []);

	return {...updatedFields, intervals: preparedTImeIntervals};
};
