import {dateFormat} from '@tehzor/tools/utils/dateFormats';
import {DatePicker, TextFieldWithForwardedRef} from '@tehzor/ui-components';
import {IDatePickerTriggerProps} from '@tehzor/ui-components/src/components/dates/DatePicker/DatePicker';
import {format} from 'date-fns';
import {useController, useFormContext} from 'react-hook-form';
import {useDateFnsLocale} from '@src/core/hooks/translations/useDateFnsLocales';
import {useUpdateEffect} from 'react-use';
import {useTranslation} from 'react-i18next';
import {IDateRangeKeys} from '../types/IntervalFieldsKeys';
import {getIntervalField} from '../utils/intervals/getIntervalField';
import {IScheduleForm} from '../types/IScheduleForm';
import {IntervalFields} from '../types/IntervalFields';

interface IDateRangeTo {
	field: IDateRangeKeys;
	className?: string;
	generalDateTo: Date | undefined;
}

export const IntervalDateRangeTo = ({field, className, generalDateTo}: IDateRangeTo) => {
	const index = Number(field.split('.')[1]);

	const {t} = useTranslation();
	const {control, watch, setValue} = useFormContext<IScheduleForm>();
	const {
		field: {value: dateTo}
	} = useController({
		name: field,
		control
	});
	const dateFnsLocale = useDateFnsLocale();
	const datesOptions = {locale: dateFnsLocale};
	const dateFromFieldValue = watch(getIntervalField(index, IntervalFields.DATE_RANGE_FROM));

	const dateFrom = dateFromFieldValue ? new Date(dateFromFieldValue) : undefined;
	const disabledDate = (date: Date) =>
		(dateFrom && date < dateFrom) ||
		date < new Date() ||
		(!!generalDateTo && date > generalDateTo);

	useUpdateEffect(() => {
		if (dateFrom && dateTo && dateTo < dateFrom) {
			setValue(field, undefined);
		}
	}, [dateFrom, dateTo]);

	const handleChange = (v: Date) => {
		setValue(field, v);
	};

	const handleClearButton = () => {
		setValue(field, undefined);
	};

	const getTrigger = (triggerProps: IDatePickerTriggerProps) => (
		<TextFieldWithForwardedRef
			elementType="div"
			className={className}
			value={triggerProps?.value !== undefined ? format(triggerProps.value, dateFormat) : ''}
			onClick={triggerProps?.toggle}
			ref={triggerProps?.ref}
			cleanable={!!dateTo}
			onClearClick={handleClearButton}
		/>
	);

	return (
		<DatePicker
			value={dateTo}
			datesOptions={datesOptions}
			onChange={handleChange}
			disabledDate={disabledDate}
			trigger={getTrigger}
			selectCancelBtnLabel={t('cancelBtn.label')}
			selectApplyBtnLabel={t('applyBtn.label')}
			useApplyButton
		/>
	);
};
