import {ISchedule} from '@tehzor/tools/interfaces/schedules/ISchedule';
import {format} from 'date-fns';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import styles from './ModificationCell.module.less';
import classNames from 'classnames';
import {CellProps} from 'react-table';

export const ModificationCell = ({row}: CellProps<ISchedule>) => {
	const {modifiedAt, modifiedBy} = row.original;
	if (!modifiedAt && !modifiedBy) {
		return null;
	}
	const modifiedAtStr = modifiedAt ? format(modifiedAt, dateTimeCommaSeparatedFormat) : null;
	const modifiedByStr = modifiedBy?.fullName || null;

	return (
		<div className={classNames(styles.wrap, styles.text)}>
			{modifiedAt && <span>{modifiedAtStr}</span>}
			{modifiedBy && <span>{modifiedByStr}</span>}
		</div>
	);
};
