import {durationMeasureKeys} from '../types/IDurationMeasure';

export const formattedTimeDuration = (
	timeDuration: number,
	showZeroHours: boolean,
	measure?: durationMeasureKeys
): string => {
	switch (measure) {
		// Если передана мера "день", возвращаем количество дней
		case durationMeasureKeys.DAY: {
			const days = Math.floor(timeDuration / 1440); // 1440 минут в дне
			return `${days} дн.`;
		}
		// Если передана мера "часы", возвращаем количество часов
		case durationMeasureKeys.HOUR: {
			const hours = Math.floor(timeDuration / 60);
			if (showZeroHours && hours === 0) {
				return `0 ч.`;
			}
			if (hours % 24 === 0) {
				return `${hours / 24} дн.`;
			}
			return `${hours} ч.`;
		}
		// Если передана мера "минуты" или ничего не указано, возвращаем обычное форматирование
		case durationMeasureKeys.MINUTE:
		default: {
			const hours = Math.floor(timeDuration / 60);
			const minutes = timeDuration - hours * 60;
			const days = Math.floor(timeDuration / 1440);

			if (days > 0) {
				return `${days} дн.`;
			}

			if (hours > 0 && minutes > 0) {
				return `${hours} ч. ${minutes} мин.`;
			}
			if (hours > 0) {
				return `${hours} ч.`;
			}
			if (showZeroHours && hours === 0) {
				return `0 ч.`;
			}

			return `${minutes} мин.`;
		}
	}
};
