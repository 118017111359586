import {ProcessCell} from '../../ui/ProcessCell/ProcessCell';
import {ObjectsCell} from '../../ui/ObjectsCell/ObjectsCell';
import {ParticipantsCell} from '../../ui/ParticipantsCell/ParticipantsCell';
import {ModificationCell} from '../../ui/ModificationCell/ModificationCell';
import {CreationCell} from '../../ui/CreationCell/CreationCell';
import {DateRangeCell} from '../../ui/DateRangeCell/DateRangeCell';
import {CompanyCell} from '../../ui/CompanyCell/CompanyCell';
import {ISchedule} from '@tehzor/tools/interfaces/schedules/ISchedule';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {DeleteCell} from '../../ui/DeleteCell/DeleteCell';
import {Column, CellProps} from 'react-table';

export const getDesktopColumns = (
	deleteCellClassName?: string,
	users?: Record<string, IBriefUser>
): Array<Column<ISchedule>> => [
	{
		id: 'process',
		Header: 'Процесс',
		accessor: 'process',
		Cell: ProcessCell,
		minWidth: 150,
		width: 150
	},
	{
		id: 'company',
		Header: 'Компания',
		accessor: 'company',
		Cell: CompanyCell,
		minWidth: 150,
		width: 150
	},
	{
		id: 'objectIds',
		Header: 'Объекты',
		Cell: ObjectsCell,
		minWidth: 150,
		width: 150
	},
	{
		id: 'participantIds',
		Header: 'Участники',
		Cell: (props: CellProps<ISchedule>) => ParticipantsCell({props, users}),
		minWidth: 130,
		width: 130
	},
	{
		id: 'dateRangeFrom',
		Header: 'Даты',
		Cell: DateRangeCell,
		minWidth: 130,
		width: 130
	},
	{
		id: 'createdAt',
		Header: 'Добавлено',
		accessor: 'createdAt',
		Cell: CreationCell,
		minWidth: 130,
		width: 130
	},
	{
		id: 'modifiedAt',
		Header: 'Изменено',
		accessor: 'modifiedAt',
		Cell: ModificationCell,
		minWidth: 130,
		width: 130
	},
	{
		id: 'delete',
		Header: '',
		Cell: (props: CellProps<ISchedule>) => DeleteCell({props, className: deleteCellClassName}),
		minWidth: 52,
		width: 52
	}
];
