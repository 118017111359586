import ContentLoader from 'react-content-loader';

export const ListLoader = () => (
	<ContentLoader
		speed={2}
		width={40}
		height={40}
		viewBox="0 0 40 40"
		backgroundColor="#f3f3f3"
		foregroundColor="#ecebeb"
	>
		<rect
			x="0"
			y="10"
			rx="5"
			ry="5"
			width="40"
			height="20"
		/>
	</ContentLoader>
);
