import {useMemo} from 'react';
import {IScheduleForm} from '../../types/IScheduleForm';
import {useFormContext, useWatch} from 'react-hook-form';
import {editableScheduleFields} from '../../constants/editableScheduleFields';

export const useGeneralDateRange = () => {
	const {control} = useFormContext<IScheduleForm>();

	const dateRangeFrom = useWatch({
		control,
		name: editableScheduleFields.DATE_RANGE_FROM
	});

	const dateRangeTo = useWatch({
		control,
		name: editableScheduleFields.DATE_RANGE_TO
	});
	const generalDateFromStr = dateRangeFrom ? dateRangeFrom.toISOString() : '';
	const generalDateToStr = dateRangeTo ? dateRangeTo.toISOString() : '';

	return useMemo(
		() => ({dateRangeFrom: generalDateFromStr, dateRangeTo: generalDateToStr}),
		[generalDateFromStr, generalDateToStr]
	);
};
