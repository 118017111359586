import {IGetScheduledPlansResponse} from '@/shared/api/scheduledPlans/getScheduledPlansPaginate';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {format} from 'date-fns';

export const extractPreparedScheduledPlansList = (data: IGetScheduledPlansResponse) =>
	data.allIds.map(id => {
		const scheduledPlan = data.byId[id];

		return {
			id: scheduledPlan.id,
			name: scheduledPlan.name,
			description: scheduledPlan.description,
			objectId: scheduledPlan.objectId,
			companyId: scheduledPlan.companyId,
			createdAt: scheduledPlan.createdAt
				? format(scheduledPlan.createdAt, dateTimeCommaSeparatedFormat)
				: '',
			createdBy: scheduledPlan.createdBy?.fullName || '',
			object: scheduledPlan.object,
			company: scheduledPlan.company,
			modifiedAt: scheduledPlan.modifiedAt
				? format(scheduledPlan.modifiedAt, dateTimeCommaSeparatedFormat)
				: '',
			modifiedBy: scheduledPlan.modifiedBy?.fullName || ''
		};
	});
