import INormalizedData from '@/shared/types/INormalizedData';
import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {ITimeInterval} from '@tehzor/tools/interfaces/schedules';

export interface IGetIntervalsResponse extends INormalizedData<ITimeInterval> {
	offset?: number;
	limit?: number;
	total?: number;
}

/**
 * Возвращает пагинированный список интервалов
 *
 * @param scheduleId id расписания интервалов
 */

export const getPaginateTimeIntervalsRequest = async (scheduleId?: string) => {
	const response = await httpRequests.withToken.post<IGetIntervalsResponse>(
		'schedules-time-intervals/get-paginate',
		{
			filters: {scheduleId}
		}
	);
	return response.data;
};
