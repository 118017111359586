import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';
import {RestrictedSelectionTree} from '@tehzor/ui-components';
import {useMemo, useState} from 'react';
import {getFilteredTreeData} from '../../utils/schedules/participantsSelect/getFilteredTreeData';
import {getTreeData} from '../../utils/schedules/participantsSelect/getTreeData';
import {getInitialExpanded} from '@/shared/utils/getInitialExpanded';
import {
	ITreeUserGroupsItem,
	ITreeUserGroupsItemType
} from '@/shared/lib/EntitiesFilters/utils/makeUserGroupsTreeData';
import styles from './WorkingGroupsTabContent.module.less';

interface IWorkingGroupsTabContent {
	tabIndex: number;
	selectedUsers: string[];
	workingGroups?: IWorkingGroup[];
	selectedGroup?: string;
	handleSelectGroup: (v?: string) => void;
	handleSelectUsers: (v: string[]) => void;
	search: string;
	usersMap: Record<string, IBriefUser> | undefined;
}

const getContent = (data: ITreeUserGroupsItem) => (
	<div>
		<div className={styles.content}>{data.content}</div>
		<div className={styles.subtitle}>{data.additionalContent}</div>
		{data.type === ITreeUserGroupsItemType.LEADER && (
			<div className={styles.info}>Руководитель</div>
		)}
	</div>
);

export const WorkingGroupsTabContent = ({
	tabIndex,
	selectedUsers,
	workingGroups,
	selectedGroup,
	handleSelectGroup,
	handleSelectUsers,
	search,
	usersMap
}: IWorkingGroupsTabContent) => {
	const treeData = useMemo(
		() => getTreeData(tabIndex, workingGroups, usersMap),
		[tabIndex, workingGroups, usersMap]
	);

	const [expandedGroups, setExpandedGroups] = useState<string[]>(
		getInitialExpanded(treeData, selectedUsers)
	);

	const treeFilteredData = useMemo(
		() => getFilteredTreeData(tabIndex, search, treeData, setExpandedGroups),
		[tabIndex, search, treeData]
	);

	const handleExpandGroup = (v: string[]) => {
		setExpandedGroups(v);
	};
	const getChildrenFromParent = (parent: IWorkingGroup) => {
		const users = [...(parent.performers ?? []), parent.leader];
		return [...new Set(users)];
	};

	return (
		<RestrictedSelectionTree
			parents={workingGroups}
			selectedParent={selectedGroup}
			onParentSelect={handleSelectGroup}
			selectedChildren={selectedUsers}
			onChildrenSelect={handleSelectUsers}
			data={treeFilteredData}
			expandedParents={expandedGroups}
			onParentsExpand={handleExpandGroup}
			getChildrenFromParent={getChildrenFromParent}
			getContent={getContent}
		/>
	);
};
