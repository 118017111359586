import {Column, CellProps} from 'react-table';
import {ScheduledPlanNameCell} from '../../ui/Cells/ScheduledPlanNameCell/ScheduledPlanNameCell';
import {ScheduledPlanObjectCell} from '../../ui/Cells/ScheduledPlanObjectCell/ScheduledPlanObjectCell';
import {ScheduledPlanCreatedAtCell} from '../../ui/Cells/ScheduledPlanCreatedAtCell/ScheduledPlanCreatedAtCell';
import {ScheduledPlanModifiedAtCell} from '../../ui/Cells/ScheduledPlanModifiedAtCell/ScheduledPlanModifiedAtCell';
import {IPreparedScheduledPlan} from '@tehzor/tools/interfaces/scheduledPlans';
import {DeleteCell} from '../../ui/Cells/DeleteCell/DeleteCell';

export const getDesktopColumns = (
	deleteCellClassName: string
): Array<Column<IPreparedScheduledPlan>> => [
	{
		id: 'name',
		accessor: 'name',
		Header: 'Название',
		Cell: ScheduledPlanNameCell,
		width: 110,
		minWidth: 80
	},
	{
		id: 'objectName',
		accessor: row => row.object?.name,
		Header: 'Компания, объект',
		Cell: ScheduledPlanObjectCell,

		width: 110,
		minWidth: 80
	},
	{
		id: 'createdAt',
		accessor: item => item.createdAt,
		Header: 'Добавлено',
		Cell: ScheduledPlanCreatedAtCell,
		width: 110,
		minWidth: 80
	},
	{
		id: 'modifiedAt',
		accessor: item => item.modifiedAt,
		Header: 'Изменено',
		Cell: ScheduledPlanModifiedAtCell,
		width: 110,
		minWidth: 80
	},
	{
		id: 'delete',
		Cell: (props: CellProps<IPreparedScheduledPlan>) =>
			DeleteCell({props, className: deleteCellClassName}),
		Header: '',
		width: 70,
		disableResizing: true,
		isNonClickable: true
	}
];
