import {IDurationMeasure, durationMeasureKeys} from '@/shared/types/IDurationMeasure';
import {useDurationMeasures} from '@/shared/ui/DurationSelectDialog/hooks/useDurationMeasures';
import {TimeDurationSelect} from '@/shared/ui/Form/TimeDurationSelect/TimeDurationSelect';
import {useEffect, useState} from 'react';
import {useController, useFormContext} from 'react-hook-form';
import {useUpdateEffect} from 'react-use';
import {IScheduleFieldProps} from '../types/IScheduleFieldProps';
import {defaultTimeValues} from '../hooks/schedules/useDefaultScheduleFormData';
import {IScheduleForm} from '../types/IScheduleForm';
import {getIntegerErrorMessage} from '../utils/schedules/getIntegerErrorMessage';

const durationValues = [0, 60, 120, 180];
const getDialogError = (duration: number) => {
	const integerErrorMessage = getIntegerErrorMessage(duration);
	if (integerErrorMessage) {
		return integerErrorMessage;
	}
	const currentDuration = +duration;
	if (currentDuration > 240 || currentDuration < 0) {
		return 'Введите от 0 до 240 часов';
	}

	return undefined;
};

export const MinPreregistrationPeriodSelect = ({
	field,
	onSetValue,
	className
}: IScheduleFieldProps) => {
	const {control} = useFormContext<IScheduleForm>();
	const {
		field: {value}
	} = useController({
		name: field,
		control
	});
	const measuresOptions = [durationMeasureKeys.HOUR];
	const durationMeasures = useDurationMeasures({
		availableOptions: measuresOptions
	});

	const [duration, setDuration] = useState<number>(Number(value));
	const [durationMeasure, setDurationMeasure] = useState<IDurationMeasure>(durationMeasures.hour);

	// установка дефолтного значения после получения данных с бэка
	useEffect(() => {
		if (value !== undefined && duration === defaultTimeValues.minimumPreregistrationPeriod) {
			setDuration(Number(value));
		}
	}, [value]);

	useUpdateEffect(() => {
		onSetValue(field, duration);
	}, [duration, onSetValue]);

	return (
		<TimeDurationSelect
			value={Number(value)}
			dialogError={getDialogError}
			label="Минимальный срок предварительной записи в слот"
			duration={duration}
			setDuration={setDuration}
			durationMeasure={durationMeasure}
			setDurationMeasure={setDurationMeasure}
			durationMeasures={durationMeasures}
			durationOptions={durationValues}
			showZeroHours
			disableDurationMeasure
			className={className}
		/>
	);
};
