import {ISchedule} from '@tehzor/tools/interfaces/schedules/ISchedule';
import {CellProps} from 'react-table';

interface IDeleteCellProps {
	props: CellProps<ISchedule>;
	className?: string;
}

export const DeleteCell = ({props, className}: IDeleteCellProps) => {
	const {id} = props.row.original;
	return (
		<div
			className={className}
			onClick={() => alert(`расписание ${id} удалено`)}
		>
			<i className="tz-delete" />
		</div>
	);
};
