import {ITreeUserGroupsItem} from '@/shared/lib/EntitiesFilters/utils/makeUserGroupsTreeData';
import {treeFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';

export const getFilteredTreeData = (
	tabIndex: number,
	search: string,
	treeData: ITreeUserGroupsItem[],
	changeExpanded: (expanded: string[]) => void
) => {
	if (tabIndex !== 1) {
		return [];
	}

	const {filteredData, expanded} = treeFilter(treeData, 'name', search);
	if (expanded?.length) {
		changeExpanded(expanded.map(item => item.id));
	}
	return filteredData;
};
