import {ISchedule} from '@tehzor/tools/interfaces/schedules/ISchedule';
import {dateFormat} from '@tehzor/tools/utils/dateFormats';
import {format} from 'date-fns';
import styles from './DateRangeCell.module.less';
import {CellProps} from 'react-table';

export const DateRangeCell = ({row}: CellProps<ISchedule>) => {
	const {dateRangeFrom, dateRangeTo} = row.original;

	const dateFrom = dateRangeFrom ? format(dateRangeFrom, dateFormat) : null;
	const dateTo = dateRangeTo ? format(dateRangeTo, dateFormat) : null;

	if (!dateFrom && !dateTo) {
		return <div className={styles.text}>—</div>;
	}

	return (
		<div className={styles.text}>
			{dateFrom && <span>{dateFrom}</span>}
			{dateFrom && dateTo && ' - '}
			{dateTo && <span>{dateTo}</span>}
		</div>
	);
};
