import {IScheduledPlansFilterState, IScheduledPlansSortState} from '../../slice/interfaces';

export type IScheduledPlansPaginateQueryKey = [] & {
	0: string; //scheduled-plans
	1: string; //list
	2: string; //paginate
	3: IScheduledPlansFilterState;
	4: IScheduledPlansSortState;
	5: number; //offset
	6: number; //limit
};

export const scheduledPlansQueryKeys = {
	all: () => ['scheduled-plans'],
	list: () => [...scheduledPlansQueryKeys.all(), 'list'],
	paginate: () => [...scheduledPlansQueryKeys.list(), 'paginate']
};
