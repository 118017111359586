import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {ISchedule} from '@tehzor/tools/interfaces/schedules/ISchedule';

/**
 * Возвращает расписание по его id
 */

export type IGetScheduleResponse = ISchedule;

export const getScheduleRequest = async (scheduleId: string) => {
	const response = await httpRequests.withToken.get<IGetScheduleResponse>(
		`schedules/${scheduleId}`
	);
	return response.data;
};
