import {useMutation, useQueryClient} from '@tanstack/react-query';
import {internalAcceptancesQueryKeys} from '@src/api/cache/internalAcceptances/keys';
import {makeInternalAcceptanceAddRequest} from '@src/api/backend/internalAcceptance';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {addErrorToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

interface IAddInternalAcceptanceOnlineParams {
	objectId: string;
	links?: IInternalAcceptance['links'];
	stage?: ObjectStageIds;
}

// Добавление внутренней приёмки, без учёта офлайн режима
export const useAddInternalAcceptanceOnline = () => {
	const queryClient = useQueryClient();
	const {t} = useTranslation();

	return useMutation({
		mutationKey: internalAcceptancesQueryKeys.add(),
		mutationFn: ({objectId, links, stage}: IAddInternalAcceptanceOnlineParams) =>
			makeInternalAcceptanceAddRequest(objectId, links, stage),
		onError: () => addErrorToast({title: t('toast.addInternalAcceptance.error.title')}),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: internalAcceptancesQueryKeys.list()});
			void queryClient.invalidateQueries({queryKey: internalAcceptancesQueryKeys.latest()});
			void queryClient.invalidateQueries({queryKey: spacesQueryKeys.internalAcceptances()});
		}
	});
};
