import {CSSProperties, ReactNode} from 'react';
import classNames from 'classnames';
import {useSortedElements} from '@tehzor/ui-components/src/components/filters/MobileFilter/utils/useSortedElements';
import {isActive} from '@tehzor/ui-components/src/components/filters/MobileFilter/utils/isActive';
import {ErrorBoundary, ErrorMessage, InlineButton, MobileFilterButton} from '@tehzor/ui-components';
import {convertClassNames} from '@tehzor/ui-components/src/utils/convertClassNames';

export type FilterItemsClasses =
	| string
	| {
			root?: string;
			button?: string;
			btnWrap?: string;
			label?: string;
			labelWrap?: string;
			selectAllBtn?: string;
			cancelAllBtn?: string;
	  };

export interface IFilterItem {
	id: string;
	name: string;
	isDisable?: boolean;
}

export interface IFilterItemsProps {
	className?: FilterItemsClasses;
	style?: CSSProperties;
	label?: ReactNode;
	elements?: IFilterItem[];
	selected?: string[];
	selectAllBtnLabel?: string;
	cancelAllBtnLabel?: string;
	noOptions?: boolean;
	noOptionsOnChange?: () => void;
	noOptionsChecked?: boolean;
	noOptionsContent?: ReactNode;
	onChange?: (value: string[] | undefined) => void;
	noSorted?: boolean;
}

export const FilterItems = ({
	className,
	style,
	label,
	elements,
	selected = [],
	selectAllBtnLabel,
	cancelAllBtnLabel,
	noOptions,
	noOptionsOnChange,
	noOptionsChecked,
	noOptionsContent,
	onChange,
	noSorted
}: IFilterItemsProps) => {
	const classes = convertClassNames(className);

	const sortedElements = useSortedElements(elements, selected, noSorted);

	const handleClick = (item: IFilterItem) => {
		if (!onChange) return;

		if (isActive(item, selected)) {
			onChange(selected.filter(id => id !== item.id));
		} else {
			onChange([...selected, item.id]);
		}
	};

	const handleAllChange = () => {
		if (onChange && elements) {
			onChange(elements.map(item => item.id));
		}
	};

	const handleAllCancel = () => {
		if (onChange && elements) {
			onChange([]);
		}
	};

	const handleNoOptionClick = () => {
		if (!noOptionsOnChange) return;
		noOptionsOnChange();
	};
	return (
		<ErrorBoundary
			label="FilterItems"
			component={
				<div>
					<ErrorMessage />
				</div>
			}
		>
			<div
				className={classNames(classes.root)}
				style={style}
			>
				<div className={classNames(classes.labelWrap)}>
					<div className={classNames(classes.label)}>{label}</div>
					{selectAllBtnLabel && (
						<InlineButton
							className={classNames(classes.selectAllBtn)}
							type="accent"
							label={selectAllBtnLabel}
							onClick={handleAllChange}
						/>
					)}
				</div>
				<div className={classNames(classes.btnWrap)}>
					{noOptions ? (
						<MobileFilterButton
							label={noOptionsContent}
							active={noOptionsChecked}
							onClick={handleNoOptionClick}
						/>
					) : null}
					{sortedElements.map(item => (
						<MobileFilterButton
							className={{customDisabled: classNames(classes.button)}}
							key={item.id}
							label={item.name}
							active={isActive(item, selected)}
							onClick={() => handleClick(item)}
							disabled={item.isDisable}
						/>
					))}
					{cancelAllBtnLabel && (
						<InlineButton
							className={classNames(classes.cancelAllBtn)}
							type="cancel"
							label={cancelAllBtnLabel}
							onClick={handleAllCancel}
						/>
					)}
				</div>
			</div>
		</ErrorBoundary>
	);
};

FilterItems.displayName = 'FilterItems';
