import {QueryFunctionContext, useQueryClient} from '@tanstack/react-query';
import {ISchedulesPaginateQueryKey, schedulesQueryKeys, IScheduleQueryKey} from '../keys/index';
import {getSchedulesPaginateRequest} from '@/shared/api/schedules/getSchedules';
import {useSchedulesPermissions} from '@/shared/hooks/permissions/useSchedulesPermissions';
import {getScheduleRequest} from '@/shared/api/schedule/getSchedule';

export const useSchedulesQueryDefaults = () => {
	const queryClient = useQueryClient();
	const {canViewSchedules} = useSchedulesPermissions();

	queryClient.setQueryDefaults(schedulesQueryKeys.paginate(), {
		queryFn: ({queryKey}: QueryFunctionContext<ISchedulesPaginateQueryKey>) => {
			const [, , , filters, sort, offset, limit] = queryKey;
			return getSchedulesPaginateRequest(filters, sort, offset, limit);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 60 * 24, // 1 День
		enabled: canViewSchedules
	});

	queryClient.setQueryDefaults(schedulesQueryKeys.details(), {
		queryFn: ({queryKey}: QueryFunctionContext<IScheduleQueryKey>) => {
			const [, , scheduleId] = queryKey;
			return getScheduleRequest(scheduleId);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 60 * 24 // 1 День
	});
};
