import {ResponsiblesFilter} from '@src/components/EntitiesFilters';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {useFilteredWorkingGroups} from '@src/core/hooks/queries/workingGroups/hooks';
import {memo, useCallback} from 'react';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';

interface IProblemPerformersFilterProps {
	objectIds?: string[];
	stagesIds?: string[];
	performers?: string[];
	label?: string;
}

export const ProblemPerformersFilter = memo(
	({objectIds, performers, stagesIds, label}: IProblemPerformersFilterProps) => {
		const {data: workingGroups} = useFilteredWorkingGroups({
			objects: objectIds,
			stages: stagesIds,
			types: [WorkingGroupTypeId.PERFORMERS]
		});

		const {dispatch} = useEntitiesFiltersCtx();

		const handleChange = useCallback(
			(value?: string[]) => {
				dispatch({performers: value});
			},
			[dispatch]
		);

		return (
			<ResponsiblesFilter
				respUsers={performers}
				workingGroups={workingGroups}
				onChange={handleChange}
				label={label}
			/>
		);
	}
);
