import {CellProps} from 'react-table';
import {IPreparedWorkAcceptance} from '@src/interfaces/IPreparedWorkAcceptance';
import {useExtractCategoryById} from '@src/core/hooks/queries/categories/hook';
import {useTranslation} from 'react-i18next';
import {DeadlineIntervalMobile} from '@src/components/DeadlineIntervalMobile';
import {compareFixDate} from '@tehzor/tools/utils/compareFixDate';
import {FixDateTag} from '@src/components/FixDateTag';

export const WorkAcceptanceDescriptionMobileCell = ({row}: CellProps<IPreparedWorkAcceptance>) => {
	const {t} = useTranslation();
	const {
		categoryId,
		description,
		percent,
		physicalWorkScope,
		frontType,
		type,
		acceptanceIntervalStart,
		acceptanceIntervalEnd,
		actualAcceptanceDateOriginal,
		acceptanceIntervalEndOriginal
	} = row.original;
	const {data: category} = useExtractCategoryById(categoryId);

	return (
		<div className="work-acceptances-page__m-table__cell-description">
			<div className="work-acceptances-page__m-table__cell-number">
				{`№${row?.original?.number}` || ''}
			</div>
			{description && (
				<div className="work-acceptances-page__m-table__cell-description__name">
					{description}
				</div>
			)}
			{type && (
				<div className="work-acceptances-page__m-table__cell-description__type">
					{t('workAcceptancesPage.table.columns.type.header')}: {type.name}
				</div>
			)}
			{frontType && (
				<div className="work-acceptances-page__m-table__cell-description__front-type">
					{t('workAcceptancesPage.table.columns.frontType.header')}: {frontType.name}
				</div>
			)}
			<div className="work-acceptances-page__m-table__cell-description__category">
				{category?.name || ''}
			</div>
			<div className="work-acceptances-page__m-table__cell-description__progress">
				{percent ? `${percent}%` : '0%'}
				{physicalWorkScope?.value && physicalWorkScope?.unitName
					? `, ${physicalWorkScope.value + physicalWorkScope.unitName}`
					: ''}
			</div>
			{acceptanceIntervalStart && acceptanceIntervalEnd && (
				<div className="work-acceptances-page__m-table-description__acceptance-date">
					<div className="work-acceptances-page__m-table-description__acceptance-date__title">
						{t('workAcceptancesPage.table.columns.plannedFixDate.header')}:
					</div>

					<DeadlineIntervalMobile
						dateStart={acceptanceIntervalStart}
						dateEnd={acceptanceIntervalEnd}
					/>
					{compareFixDate(actualAcceptanceDateOriginal, acceptanceIntervalEndOriginal) &&
						acceptanceIntervalEndOriginal && (
							<FixDateTag
								className="fix-date-view-field__tag"
								plannedFixDate={acceptanceIntervalEndOriginal}
								actualFixDate={actualAcceptanceDateOriginal}
							/>
						)}
				</div>
			)}
		</div>
	);
};
