import {IMobileFilterItem} from '@tehzor/ui-components/src/components/filters/MobileFilter';

export const WeekDays: IMobileFilterItem[] = [
	{id: '1', name: 'ПН'},
	{id: '2', name: 'ВТ'},
	{id: '3', name: 'СР'},
	{id: '4', name: 'ЧТ'},
	{id: '5', name: 'ПТ'},
	{id: '6', name: 'СБ'},
	{id: '7', name: 'ВС'}
];
