import {manageCategoriesSetsRoutes} from './manageCategoriesSetsRoutes';
import {manageCheckListsRoutes} from './manageCheckListsRoutes';
import {manageExportTemplatesRoutes} from './manageExportTemplatesRoutes';
import {manageProblemTagsSetsRoutes} from './manageProblemTagsSetsRoutes';
import {manageSpaceStatusesSetsRoutes} from './manageSpaceStatusesSetsRoutes';
import {manageSpaceTypeDecorationRoutes} from './manageSpaceTypeDecorationRoutes';
import {manageWorkingGroupsRoutes} from './manageWorkingGroupsRoutes';
import {manageFieldsRoutes} from '@/app/routes/manageFieldsRoutes';
import {manageSchedulesRoutes} from '@/app/routes/manageSchedulesRoutes';

export const manageRoutes = [
	{
		path: 'check-lists',
		children: manageCheckListsRoutes
	},
	{
		path: 'working-groups',
		children: manageWorkingGroupsRoutes
	},
	{
		path: 'space-type-decoration',
		children: manageSpaceTypeDecorationRoutes
	},
	{
		path: 'categories-sets',
		children: manageCategoriesSetsRoutes
	},
	{
		path: 'problem-tags-sets',
		children: manageProblemTagsSetsRoutes
	},
	{
		path: 'export-templates',
		children: manageExportTemplatesRoutes
	},
	{
		path: 'space-statuses-sets',
		children: manageSpaceStatusesSetsRoutes
	},
	{
		path: 'fields',
		children: manageFieldsRoutes
	},
	{
		path: 'schedules',
		children: manageSchedulesRoutes
	}
];
