interface IMonthDays {
	id: string;
	name: string;
	isDisable?: boolean;
}

export const MonthDays: IMonthDays[] = Array.from({length: 31}, (_, i) => {
	const day = i + 1;

	return {
		id: day.toString(),
		name: day.toString()
	};
});
