import {IGetObjectsResponse} from '@src/api/backend/objects';
import {ICity} from '@src/core/hooks/queries/objects/interfaces';
import arrayToTree from 'array-to-tree';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {IObjectsFiltersState} from '@src/store/modules/settings/pages/objects/reducers';
import {filterTree} from '@src/utils/tree';
import {filterFun} from '@src/core/hooks/queries/objects/utils/filterFun';
import findTreeNode from '@tehzor/tools/utils/findTreeNode';
import IObjectRespRule from '@tehzor/tools/interfaces/objects/IObjectRespRule';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import compareDesc from 'date-fns/compareDesc';
import {getAllTreeKeys} from '@tehzor/tools/utils/tree/getAllTreeKeys';

export const extractObjectsMap = (data: IGetObjectsResponse) => data.byId;

export const extractObjectsIds = (data: IGetObjectsResponse) => data.allIds;

export const extractObjectsAsArray = (data: IGetObjectsResponse) =>
	data.allIds.map(id => data.byId[id]);

export const extractObjectsCitiesAsArray = (data: IGetObjectsResponse) => {
	const objects = extractObjectsAsArray(data);

	const uniqueCities = Array.from(
		objects.reduce<Set<string>>((acc, obj) => {
			if (obj.city) {
				acc.add(obj.city);
			}

			return acc;
		}, new Set())
	);
	const sortedCities = uniqueCities.sort();

	return sortedCities.map(city => ({id: city, name: city})) as ICity[];
};

export const extractObjectsAsArrayByIds = (data: IGetObjectsResponse, objectsIds?: string[]) => {
	if (!objectsIds) return undefined;
	return data.allIds.filter(id => objectsIds.includes(id)).map(id => data.byId[id]);
};

export const extractObject = (data: IGetObjectsResponse, objectId?: string) => {
	if (!objectId) return undefined;
	return data.byId[objectId];
};

export const extractObjectById = (data: IGetObjectsResponse, objectId?: string) => {
	if (!objectId) return undefined;
	if (objectId === 'all') return extractObjectsAsArray(data);
	return [data.byId[objectId]];
};

export const extractCurrentObjectByExternalId = (
	data: IGetObjectsResponse,
	externalId?: string
) => {
	if (!externalId) return undefined;
	const objects = extractObjectsAsArray(data);
	return objects.find(object => object.externalId === externalId);
};

export const extractObjectsAsTree = (data: IGetObjectsResponse) => {
	const objects = extractObjectsAsArray(data);
	return arrayToTree<IObject>(objects, {
		parentProperty: 'parentId',
		customID: 'id'
	});
};

export const extractFilteredTreeObjects = (
	data: IGetObjectsResponse,
	filters: IObjectsFiltersState
) => {
	const tree = extractObjectsAsTree(data);
	const hasCompanies = Array.isArray(filters.companies) && filters.companies.length;
	return tree.filter(object =>
		hasCompanies ? filters.companies?.includes(object.companyId) : true
	);
};

export const extractFilteredWithNewFiltersTreeObjects = (
	data: IGetObjectsResponse,
	filters: IObjectsFiltersState,
	objectFilterValue?: string
) => {
	const tree = extractObjectsAsTree(data);
	return filterTree(tree, filterFun, {...filters, object: objectFilterValue});
};

export const extractCurrentTreeObject = (data: IGetObjectsResponse, objectId?: string) => {
	if (!objectId) return undefined;
	const tree = extractObjectsAsTree(data);
	return findTreeNode(tree, objectId);
};

export const extractParentTreeObject = (data: IGetObjectsResponse, objectId?: string) => {
	const tree = extractObjectsAsTree(data);
	const currentTree = extractCurrentTreeObject(data, objectId);

	if (!currentTree || !currentTree.parentId) return undefined;

	return findTreeNode(tree, currentTree.parentId);
};

export const extractObjectRespRules = (data: IGetObjectsResponse, objectId?: string) => {
	const object = extractObject(data, objectId);
	if (!object || !object.contractors) return undefined;
	let rules = [] as IObjectRespRule[];

	for (const c of object.contractors) {
		if (c.respRules) {
			rules = rules.concat(c.respRules);
		}
	}

	return rules;
};

export const extractObjectRespUsers = (
	data: IGetObjectsResponse,
	users?: Record<string, IBriefUser>,
	objectId?: string
) => {
	const rules = extractObjectRespRules(data, objectId);
	if (!rules || !users) return undefined;
	return rules
		.map(rule => users[rule.userId])
		.filter((user, i, all) => user !== undefined && i === all.findIndex(u => user.id === u.id))
		.sort((a: IBriefUser, b: IBriefUser) =>
			a.fullName > b.fullName ? 1 : b.fullName > a.fullName ? -1 : 0
		);
};

export const extractObjectAutoSelectRespRules = (data: IGetObjectsResponse, objectId?: string) => {
	const rules = extractObjectRespRules(data, objectId);
	if (!rules) return undefined;
	return rules.filter(rule => rule.autoSelect);
};

export const extractObjectFieldsSettings = (data: IGetObjectsResponse, objectId?: string) => {
	const object = extractObject(data, objectId);
	if (!object) return undefined;
	return object.fieldsSettings;
};

export const extractObjectsStartDate = (data: IGetObjectsResponse) => {
	const objects = extractObjectsAsArray(data);
	return objects.reduce<Date>((min, object) => {
		if (object.createdAt) {
			const date = new Date(object.createdAt);
			if (compareDesc(date, min) === 1) {
				return date;
			}
		}
		return min;
	}, new Date());
};

export const extractObjectChildrenIds = (data: IGetObjectsResponse, objectId?: string) => {
	if (!objectId) return undefined;
	const objects = extractObjectsAsArray(data);
	return objects.reduce<string[]>((prev, object) => {
		if (object.parentId === objectId) {
			prev.push(object.id);
		}
		return prev;
	}, []);
};

export const extractTargetObjects = (
	data: IGetObjectsResponse,
	objectId?: string,
	withParentObject?: boolean
) => {
	if (!objectId) return undefined;
	const tree = extractCurrentTreeObject(data, objectId);
	const childrenKeys = tree?.children?.length ? getAllTreeKeys(tree.children) : [];

	if (withParentObject) {
		return [objectId, ...childrenKeys];
	}
	return childrenKeys.length ? childrenKeys : [objectId];
};

export const extractObjectIdsForCompany = (data: IGetObjectsResponse, companyId?: string) => {
	if (!companyId) return undefined;
	const objects = extractObjectsAsArray(data);
	const objectIds: string[] = [];
	for (const object of objects) {
		if (object.companyId === companyId) {
			objectIds.push(object.id);
		}
	}
	return objectIds;
};

export const extractObjectIdsForCompanyOrAllIds = (
	data: IGetObjectsResponse,
	companyId?: string
) => {
	if (!companyId) return data.allIds;
	const objects = extractObjectsAsArray(data);
	const objectIds: string[] = [];
	for (const object of objects) {
		if (object.companyId === companyId) {
			objectIds.push(object.id);
		}
	}
	return objectIds;
};
