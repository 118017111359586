import {ISchedule} from '@tehzor/tools/interfaces/schedules/ISchedule';
import {format} from 'date-fns';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import styles from './CreationCell.module.less';
import classNames from 'classnames';
import {CellProps} from 'react-table';

export const CreationCell = ({row}: CellProps<ISchedule>) => {
	const {createdAt, createdBy} = row.original;

	if (!createdAt && !createdBy) {
		return null;
	}

	const createdAtStr = createdAt ? format(createdAt, dateTimeCommaSeparatedFormat) : null;
	const createdByStr = createdBy?.fullName || null;

	return (
		<div className={classNames(styles.wrap, styles.text)}>
			{createdAt && <span>{createdAtStr}</span>}
			{createdBy && <span>{createdByStr}</span>}
		</div>
	);
};
