import {CSSProperties, useCallback, useState} from 'react';
import './InlineDateRangePicker.less';
import Calendar, {IDatesOptions} from '../Calendar';
import {ru} from 'date-fns/locale';
import SelectableDay from './component/SelectableDay';
import {isBefore} from 'date-fns';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import RangeWeekRow from './component/RangeWeekRow';

interface IDatePickerProps {
	className?: string;
	style?: CSSProperties;
	datesOptions?: IDatesOptions;
	valueFrom?: Date;
	valueTo?: Date;

	onChange?: (from: Date, to: Date) => void;
	disabledDate?: (value: Date) => boolean;
}

const InlineDateRangePicker = (props: IDatePickerProps) => {
	const {
		className,
		style,
		datesOptions = {locale: ru},
		valueFrom: propsValueFrom,
		valueTo: propsValueTo,
		onChange,
		disabledDate
	} = props;

	const [valueFrom, setValueFrom] = useState<Date | undefined>(propsValueFrom);
	const [valueTo, setValueTo] = useState<Date | undefined>(propsValueTo);
	const [inProgress, setInProgress] = useState<boolean>(false);

	useUpdateEffect(() => {
		setValueFrom(propsValueFrom);
		setValueTo(propsValueTo);
	}, [propsValueFrom, propsValueTo]);

	const handleDayClick = useCallback(
		(v: Date) => {
			if (!valueFrom || (valueFrom && !inProgress)) {
				setValueFrom(v);
				setValueTo(undefined);
				setInProgress(true);
			} else {
				setInProgress(false);
				if (onChange) {
					if (isBefore(valueFrom, v)) {
						onChange(valueFrom, v);
					} else {
						onChange(v, valueFrom);
					}
				}
			}
		},
		[valueFrom, inProgress, onChange]
	);

	const handleDayHover = useCallback(
		(isActive: boolean, date: Date) => {
			if (inProgress) {
				setValueTo(isActive ? date : undefined);
			}
		},
		[inProgress]
	);

	return (
		<Calendar
			className={className}
			style={style}
			initialDate={valueFrom}
			datesOptions={datesOptions}
			weekRowComponent={<RangeWeekRow valueFrom={valueFrom} valueTo={valueTo} />}
			dayComponent={
				<SelectableDay
					valueFrom={valueFrom}
					valueTo={valueTo}
					inProgress={inProgress}
					onClick={handleDayClick}
					onHover={handleDayHover}
					disabledDate={disabledDate}
				/>
			}
		/>
	);
};

export default InlineDateRangePicker;
