import CreatedByFilterPage from '@src/pages/filters/CreatedByFilterPage';
import CreationDateFilterPage from '@src/pages/filters/CreationDateFilterPage';
import {ObjectsFilterPage} from '@src/pages/filters/ObjectsFilterPage';
import {TasksResponsiblesFilterPage} from '@src/pages/filters/TasksResponsiblesFilterPage';
import {TasksFiltersPage} from '@src/pages/filters/TasksFiltersPage';
import {TasksPrioritiesFilterPage} from '@src/pages/filters/TasksPrioritiesFilterPage';
import {TasksStatusesFilterPage} from '@src/pages/filters/TasksStatusesFilterPage';
import {TasksTypesFilterPage} from '@src/pages/filters/TasksTypesFilterPage';
import {TasksIntervalEndFilterPage} from '@src/pages/filters/TasksIntervalEndFilterPage';
import {TasksIntervalStartFilterPage} from '@src/pages/filters/TasksIntervalStartFilterPage';

export const tasksFilterRoutes = [
	{
		path: 'objects',
		element: <ObjectsFilterPage />
	},

	{
		path: 'statuses',
		element: <TasksStatusesFilterPage />
	},

	{
		path: 'types',
		element: <TasksTypesFilterPage />
	},
	{
		path: 'priorities',
		element: <TasksPrioritiesFilterPage />
	},

	{
		path: 'intervalstart',
		element: <TasksIntervalStartFilterPage />
	},

	{
		path: 'intervalend',
		element: <TasksIntervalEndFilterPage />
	},

	{
		path: 'creationdate',
		element: <CreationDateFilterPage />
	},

	{
		path: 'respusers',
		element: <TasksResponsiblesFilterPage />
	},

	{
		path: 'createdBy',
		element: <CreatedByFilterPage />
	},

	{
		path: '',
		element: <TasksFiltersPage />
	}
];
