import {ITreeDataItem} from '@tehzor/ui-components/src/components/Tree';
import {useTranslatedObjectStagesArray} from '@src/core/hooks/translations/useTranslatedObjectStagesArray';

/**
 * Формирует дерево процессов из переведенных стадий
 *
 * @param translatedStages - переведенные стадии
 */
export function makeProcessesTreeData(
	translatedStages: ReturnType<typeof useTranslatedObjectStagesArray>
): ITreeDataItem[] {
	const result: ITreeDataItem[] = [];

	for (const stage of translatedStages) {
		for (const process of stage.processes) {
			result.push({
				id: process.id,
				content: process.name,
				parentId: stage.id
			});
		}
		result.push({
			id: stage.id,
			content: stage.name
		});
	}
	return result;
}
