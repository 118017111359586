import Attachments from '@src/components/editableFields/Attachments';
import {useCompany} from '@src/core/hooks/queries/companies/hooks';
import {useFilteredLegalsArray} from '@src/core/hooks/queries/legals';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {useUnitsAsArray} from '@src/core/hooks/queries/units/hook';
import {
	IEditableWorkAcceptanceAction,
	IEditableWorkAcceptanceState
} from '@src/core/hooks/states/useEditableWorkAcceptanceState';
import {
	IUploadingFilesAction,
	IUploadingFilesState
} from '@src/core/hooks/states/useUploadingFilesState';
import {AttachmentFileDestination} from '@tehzor/tools/enums/AttachmentFileDestination';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {WorkAcceptanceFrontTypeId} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceFrontType';
import {useIsLargeTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import {Dispatch, Suspense} from 'react';
import {useTranslation} from 'react-i18next';
import Category from '../editableFields/Category';
import {SpaceTreeSelect} from '../editableFields/SpaceTreeSelect/SpaceTreeSelect';
import {StructureTreeSelect} from '../editableFields/StructureTreeSelect';
import Text from '../editableFields/Text';
import {UnitSelect} from '../editableFields/UnitSelect';
import {WorkAcceptanceFrontTypeSelect} from '../editableFields/WorkAcceptanceFrontTypeSelect';
import {WorkAcceptanceTypeSelect} from '../editableFields/WorkAcceptanceTypeSelect';
import './EditableWorkAcceptance.less';
import {EditableAcceptors} from './fields/EditableAcceptors';
import {SingleSelectContractField} from './fields/SingleSelectContractField';
import {CustomField} from '../editableFields/CustomField';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import {ICustomFieldSetting} from '@tehzor/tools/interfaces/fields';
import {EditableSubmitters} from './fields/EditableSubmitters';
import DateTimeSelect from '../editableFields/DateTimeSelect';

interface IEditableWorkAcceptanceProps {
	objectId: string;
	editingState: IEditableWorkAcceptanceState;
	editingDispatch: Dispatch<IEditableWorkAcceptanceAction>;
	fieldsSettings: {
		builtin: Record<string, IObjectFieldSetting>;
		custom: Record<string, ICustomFieldSetting>;
	};
	uploadingFilesState: IUploadingFilesState;
	uploadingFilesDispatch: Dispatch<IUploadingFilesAction>;
	saving: boolean;
	stage: ObjectStageIds;
}

const attachmentsClassNames = {
	root: 'editable-work-acceptance__attachments',
	scrollArea: 'editable-work-acceptance__attachments-scroll-area'
};

/**
 * Интерфейс с визуальными компонентами для редактирования полей
 */
export const EditableWorkAcceptance = (props: IEditableWorkAcceptanceProps) => {
	const {
		objectId,
		editingState,
		editingDispatch,
		fieldsSettings,
		saving,
		stage,
		uploadingFilesState,
		uploadingFilesDispatch
	} = props;
	const {t} = useTranslation();
	const {data: object} = useObject(objectId);
	const {data: company} = useCompany(object?.companyId);
	const legalEntities = useFilteredLegalsArray(company?.uuid ? [company?.uuid] : undefined);
	const {data: unitsListArr} = useUnitsAsArray();
	const isDesktop = useIsLargeTablet();
	const isLargeTablet = useIsLargeTablet();
	const hasCustomFields = !!Object.keys(fieldsSettings.custom).length;

	const frontSelect =
		editingState.frontType === WorkAcceptanceFrontTypeId.STRUCTURES ? (
			<StructureTreeSelect
				objectId={objectId}
				field="structureIds"
				label={t('editableWorkAcceptance.frontSelect.label')}
				value={editingState.structureIds || []}
				locations={editingState.locations}
				editingDispatch={editingDispatch}
				required={fieldsSettings.builtin.structureIds.required}
				disabled={saving}
				hasError={editingState.errors.structureIds}
			/>
		) : editingState.frontType === WorkAcceptanceFrontTypeId.SPACES ? (
			<Suspense>
				<SpaceTreeSelect
					objectId={objectId}
					field="spaceIds"
					label={t('editableWorkAcceptance.frontSelect.label')}
					value={editingState.spaceIds || []}
					locations={editingState.locations}
					editingDispatch={editingDispatch}
					required={fieldsSettings.builtin.spaceIds.required}
					disabled={saving}
					hasError={editingState.errors.spaceIds}
				/>
			</Suspense>
		) : null;

	return (
		<div className="editable-work-acceptance">
			<div className="editable-work-acceptance__info-grid">
				{frontSelect}

				<WorkAcceptanceTypeSelect
					field="type"
					label={t('editableWorkAcceptance.type.label')}
					value={editingState.type}
					editingDispatch={editingDispatch}
					required={fieldsSettings.builtin.type.required}
					disabled={saving}
					hasError={editingState.errors.type}
					errorMessage={t('editableWorkAcceptance.type.error')}
				/>

				<WorkAcceptanceFrontTypeSelect
					field="frontType"
					label={t('editableWorkAcceptance.frontType.label')}
					value={editingState.frontType}
					editingDispatch={editingDispatch}
					required={fieldsSettings.builtin.frontType.required}
					disabled={saving}
					hasError={editingState.errors.frontType}
					errorMessage={t('editableWorkAcceptance.frontType.error')}
				/>

				<Category
					value={editingState.categoryId}
					objectId={objectId}
					label={t('editableWorkAcceptance.category.label')}
					editingDispatch={editingDispatch}
					required={fieldsSettings.builtin.categoryId.required}
					disabled={saving}
					hasError={editingState.errors.categoryId}
					stage={stage}
				/>

				<DateTimeSelect
					valueFrom={editingState.acceptanceIntervalStart}
					valueTo={editingState.acceptanceIntervalEnd}
					editingDispatch={editingDispatch}
					required={fieldsSettings.builtin.acceptanceIntervalStart.required}
					disabled={saving}
					hasErrorFrom={editingState.errors.acceptanceIntervalStart}
					hasErrorTo={editingState.errors.acceptanceIntervalEnd}
					fieldFrom="acceptanceIntervalStart"
					fieldTo="acceptanceIntervalEnd"
					label={t('editableWorkAcceptance.acceptanceDate.label')}
					shouldDisableDate
				/>
				<EditableAcceptors
					objectId={objectId}
					stage={stage}
					editingState={editingState}
					editingDispatch={editingDispatch}
					required={fieldsSettings.builtin.acceptors.required}
					disabled={saving}
					hasError={editingState.errors.acceptors}
				/>

				<EditableSubmitters
					objectId={objectId}
					stage={stage}
					editingState={editingState}
					editingDispatch={editingDispatch}
					required={fieldsSettings.builtin.submitters.required}
					disabled={saving}
					hasError={editingState.errors.submitters}
				/>

				<SingleSelectContractField
					field="contractorLegalEntityId"
					label={t('editableWorkAcceptance.contractorLegalEntity.label')}
					legalEntities={legalEntities}
					dialogTitle={t('editableWorkAcceptance.contractorLegalEntity.label')}
					errorMessage={t('editableWorkAcceptance.contractorLegalEntity.error')}
					editingDispatch={editingDispatch}
					hasError={editingState.errors.contractorLegalEntityId}
					required={fieldsSettings.builtin.contractorLegalEntityId.required}
					value={editingState.contractorLegalEntityId}
					isMobile={!isLargeTablet}
					disabled={saving}
				/>

				<Text
					field="unitPlanValue"
					label={t('editableWorkAcceptance.unitPlanValue.label')}
					elementType="input"
					value={editingState.unitPlanValue}
					editingDispatch={editingDispatch}
					required={fieldsSettings.builtin.unitPlanValue.required}
					disabled={saving}
					hasError={editingState.errors.unitPlanValue}
				/>

				<UnitSelect
					field="unitPlan"
					label={t('editableWorkAcceptance.unitPlan.label')}
					value={editingState.unitPlan?.toString()}
					editingDispatch={editingDispatch}
					required={fieldsSettings.builtin.unitPlan.required}
					disabled={saving}
					hasError={editingState.errors.unitPlan}
					unitsListArr={unitsListArr || []}
				/>

				<Text
					field="workScope"
					label={t('editableWorkAcceptance.workScope.label')}
					elementType="input"
					value={editingState.workScope}
					editingDispatch={editingDispatch}
					required={fieldsSettings.builtin.workScope.required}
					disabled={saving}
					hasError={editingState.errors.workScope}
				/>

				<Text
					field="description"
					label={t('editableWorkAcceptance.description.label')}
					elementType="textarea"
					value={editingState.description}
					editingDispatch={editingDispatch}
					required={fieldsSettings.builtin.description.required}
					disabled={saving}
					hasError={editingState.errors.description}
				/>
				{hasCustomFields &&
					Object.values(fieldsSettings.custom).map(customSetting => (
						<CustomField
							key={customSetting.id}
							custom={customSetting}
							isMobile={!isDesktop}
							editingDispatch={editingDispatch}
							editingState={editingState}
							disabled={saving}
						/>
					))}
			</div>
			<Attachments
				canDraw
				className={attachmentsClassNames}
				attachmentsDestination={AttachmentFileDestination.PROBLEM}
				attachments={editingState.attachments}
				uploadingFiles={uploadingFilesState.value}
				editingDispatch={editingDispatch}
				uploadingFilesDispatch={uploadingFilesDispatch}
				required={fieldsSettings.builtin.attachments.required}
				waitForUploading={false}
				disabled={saving}
				hasError={editingState.errors.attachments && uploadingFilesState.error}
				showAttachBtn
			/>
		</div>
	);
};
