import StatusesFilterPage from '../StatusesFilterPage';
import {useExtractSpaceTypesAsArray} from '@src/core/hooks/queries/spaceTypes/hooks';
import {useTranslatedDictionaryArray} from '@src/core/hooks/translations/useTranslatedDictionaryArray';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useTranslation} from 'react-i18next';
import {useMatch} from 'react-router';

interface SpaceTypesFilterPageProps {
	filterName?: string;
}

const SpaceTypesFilterPage = ({filterName}: SpaceTypesFilterPageProps) => {
	const {t} = useTranslation();
	const match = useMatch('/objects/:objectId/:entity/*');
	const objectId = match?.params.objectId;
	const entity = match?.params.entity;
	const {data: types} = useExtractSpaceTypesAsArray();

	const translatedOptions = useTranslatedDictionaryArray(dictionaryKeys.spaceTypes, types);
	if (!translatedOptions) return null;

	return (
		<div>
			<StatusesFilterPage
				objectId={objectId}
				entity={entity}
				statuses={translatedOptions}
				label={t('spacesFiltersPage.spaceTypesFilterMobile.baseListFilterMobile.title')}
				filterName={filterName || "types"}
			/>
		</div>
	);
};

export default SpaceTypesFilterPage;
