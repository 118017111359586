// eslint-disable-next-line import/no-extraneous-dependencies
import {CSSProperties, ReactNode, useCallback, useState} from 'react';
import './DateTimeRangePicker.less';
import {useToggle, useUpdateEffect} from 'react-use';
import {useIsBeforeLargeTablet, useIsBeforeLargeTabletHeight} from '../../../utils/mediaQueries';
import InlineDateRangePicker from '../InlineDateRangePicker';
import MobileDetect from 'mobile-detect';
import Popup from '../../containers/Popup';
import classNames from 'classnames';
import {IPopupMenuTriggerFnProps} from '../../menu/PopupMenu';
import {IDatesOptions} from '../Calendar';
import {Button} from '../../buttons';
import {IDatePickerDialogProps} from '../DatePickerLayer';
import {format, isSameDay} from 'date-fns';
import {TimePicker} from './components/TimePicker/TimePicker';
import {Dialog} from '../../dialogs';
import {getAdjustedTime} from './utils/getAdjustedTime';

const md = new MobileDetect(window.navigator.userAgent);

export interface IDateTimeRangePickerTriggerProps extends IPopupMenuTriggerFnProps {
	valueFrom?: Date;
	valueTo?: Date;
}

/**
 * ITranslations нужен для перевода
 * @param.selectApplyBtnLabel default[RU] = Применить
 * @param.selectCancelBtnLabel default[RU] = Отменить
 * @param.start default[RU] = Начало
 * @param.end default[RU] = Окончание
 * @param.fullStart default[RU] = Дата начала
 * @param.fullEnd default[RU] = Дата окончания
 */
export interface IDateTimePickerTranslations {
	selectApplyBtnLabel: string;
	selectCancelBtnLabel: string;
	start: string;
	end: string;
	fullStart: string;
	fullEnd: string;
}

export interface IDateTimePickerProps {
	className?: string;
	style?: CSSProperties;
	trigger: (props: IDateTimeRangePickerTriggerProps) => ReactNode;
	datesOptions?: IDatesOptions;
	valueFrom?: Date;
	valueTo?: Date;
	dialogProps?: IDatePickerDialogProps['dialogProps'];
	popupProps?: IDatePickerDialogProps['popupProps'];
	translations: IDateTimePickerTranslations;

	onChange: (from: Date, to: Date) => void;
	disabledDate?: (value?: Date) => boolean;
	hidePastTimes?: boolean;
}
export const DateTimeRangePicker = (props: IDateTimePickerProps) => {
	const {
		className,
		style,
		trigger,
		datesOptions,
		valueFrom: propsValueFrom,
		valueTo: propsValueTo,
		dialogProps,
		popupProps,
		translations,
		onChange,
		disabledDate,
		hidePastTimes
	} = props;

	const [isOpen, toggleOpen] = useToggle(false);
	const [valueFrom, setValueFrom] = useState<Date | undefined>(propsValueFrom);
	const [valueTo, setValueTo] = useState<Date | undefined>(propsValueTo);

	useUpdateEffect(() => {
		setValueFrom(propsValueFrom);
		setValueTo(propsValueTo);
	}, [propsValueFrom, propsValueTo]);

	const open = useCallback(() => {
		toggleOpen(true);
	}, [toggleOpen]);

	const close = useCallback(() => {
		toggleOpen(false);
		setValueFrom(propsValueFrom);
		setValueTo(propsValueTo);
	}, [propsValueFrom, propsValueTo, toggleOpen]);

	const handleApply = useCallback(() => {
		if (valueFrom && valueTo) {
			toggleOpen(false);
			onChange(valueFrom, valueTo);
		}
	}, [valueFrom, valueTo, onChange, toggleOpen]);
	const handleChange = useCallback(
		(from: Date, to: Date) => {
			const options = {
				isSingleDayRange: isSameDay(from, to),
				isToday: isSameDay(new Date(), from),
				hidePastTimes
			};

			setValueFrom(getAdjustedTime(from, false, options));
			setValueTo(getAdjustedTime(to, true, options));
		},
		[hidePastTimes]
	);

	const getTrigger = useCallback(
		(triggerProps: IPopupMenuTriggerFnProps) =>
			trigger({
				...triggerProps,
				valueFrom: propsValueFrom,
				valueTo: propsValueTo
			}),
		[propsValueFrom, propsValueTo, trigger]
	);

	const inlineDateRangePicker = (
		<InlineDateRangePicker
			className={classNames('date-picker__calendar', className)}
			style={style}
			datesOptions={datesOptions}
			valueFrom={valueFrom}
			valueTo={valueTo}
			onChange={handleChange}
			disabledDate={disabledDate}
		/>
	);

	const isSmallWidth = useIsBeforeLargeTablet();
	const isSmallHeight = useIsBeforeLargeTabletHeight();
	const isMobileView = md.mobile() || isSmallWidth || isSmallHeight;

	const triggerActions = {
		open,
		close,
		toggle: isOpen ? close : open
	};

	const buttons = (
		<div
			className={classNames('date-time-picker__buttons', {
				'date-time-picker__buttons_margin': !isMobileView
			})}
		>
			<Button
				className="date-time-picker__button"
				type="cancel"
				label={translations.selectCancelBtnLabel}
				onClick={close}
			/>
			<Button
				className="date-time-picker__button"
				type="accent-blue"
				label={translations.selectApplyBtnLabel}
				onClick={handleApply}
			/>
		</div>
	);
	const isBothValuesExist = !!(valueTo && valueFrom);

	const shouldHideValueFromPastTimes = isBothValuesExist && isSameDay(valueFrom, new Date());

	const shouldHideValueToPastTimes = isBothValuesExist && isSameDay(valueFrom, valueTo);

	const result = (
		<div className="date-time-picker__result" style={style}>
			<div className="date-time-picker__result__value">
				<div className="date-time-picker__result__value_label">{translations.start}</div>
				{valueFrom ? (
					<>
						<div className="date-time-picker__result__value_date">
							{format(valueFrom, 'dd.MM.yyyy', datesOptions)}
						</div>
						<div>
							<TimePicker
								value={valueFrom}
								setValue={setValueFrom}
								mobile={!!isMobileView}
								timeInterval={30}
								trigger={TimePickerTriggerProps => (
									<Button
										className="date-time-picker__result__value_time"
										label={format(valueFrom, 'HH:mm')}
										onClick={TimePickerTriggerProps.toggle}
										ref={TimePickerTriggerProps.ref}
										rightIcon={<i className="tz-simple-arrow-16" />}
										type="common-bordered"
									/>
								)}
								hidePastTimes={shouldHideValueFromPastTimes && hidePastTimes}
							/>
						</div>
					</>
				) : (
					<div className="date-time-picker__result__placeholder">
						{translations.fullStart}
					</div>
				)}
			</div>
			<div className="date-time-picker__result__value">
				<div className="date-time-picker__result__value_label">{translations.end}</div>
				{valueTo ? (
					<>
						<div className="date-time-picker__result__value_date">
							{format(valueTo, 'dd.MM.yyyy', datesOptions)}
						</div>
						<div>
							<TimePicker
								value={valueTo}
								setValue={setValueTo}
								mobile={!!isMobileView}
								timeInterval={30}
								trigger={TimePickerTriggerProps => (
									<Button
										className="date-time-picker__result__value_time"
										label={format(valueTo, 'HH:mm')}
										onClick={TimePickerTriggerProps.toggle}
										ref={TimePickerTriggerProps.ref}
										rightIcon={<i className="tz-simple-arrow-16" />}
										type="common-bordered"
									/>
								)}
								hidePastTimes={shouldHideValueToPastTimes && hidePastTimes}
								optionalValue={shouldHideValueToPastTimes ? valueFrom : undefined}
							/>
						</div>
					</>
				) : (
					<div className="date-time-picker__result__placeholder">
						{translations.fullEnd}
					</div>
				)}
			</div>
		</div>
	);

	return isMobileView ? (
		<>
			{getTrigger({...triggerActions})}

			<Dialog
				{...dialogProps}
				isOpen={isOpen}
				footer={buttons}
				fullScreenOnMobile
				onRequestClose={close}
			>
				{inlineDateRangePicker}
				{result}
			</Dialog>
		</>
	) : (
		<Popup
			{...popupProps}
			className={classNames('date-time-picker_popup', className)}
			trigger={(triggerProps: IPopupMenuTriggerFnProps) =>
				getTrigger({...triggerProps, ...triggerActions})
			}
			isOpen={isOpen}
			onOutsideClick={close}
			onDisappear={close}
			onParentClose={close}
		>
			{inlineDateRangePicker}
			{result}
			{buttons}
		</Popup>
	);
};
