import {FilterMenu, Menu, MenuItem} from '@tehzor/ui-components';
import {Skeleton} from '@/shared/ui/MobileDropDownMenu/Skeleton/Skeleton';
import {convertClassNames} from '@tehzor/ui-components/src/utils/convertClassNames';
import {ReactNode} from 'react';

export type IDropdownSelectClassNames =
	| string
	| {
			root?: string;
			button?: string;
			wrap?: string;
			label?: string;
			icon?: string;
			buttonOpen?: string;
			menu?: string;
			menuItem?: string;
	  };

interface IMenuItem {
	id: string;
	name: string;
}

interface IDropdownSelectProps {
	value?: string;
	label?: string;
	onChange?: (key: string) => void;
	items?: IMenuItem[];
	isLoading?: boolean;
	className?: IDropdownSelectClassNames;
	isCustomFilterButton?: boolean;
	icon?: ReactNode;
}

export const DropDownSelect = ({
	value,
	label,
	onChange,
	items,
	className,
	isCustomFilterButton,
	icon,
	isLoading = false
}: IDropdownSelectProps) => {
	if (isLoading) {
		return <Skeleton />;
	}
	const classes = convertClassNames(className);
	return (
		<FilterMenu
			label={label}
			className={className}
			isCustomFilterButton={isCustomFilterButton}
		>
			<Menu
				className={classes.menu}
				value={value}
				onChange={onChange}
			>
				{items &&
					items.map(item => (
						<MenuItem
							className={classes.menuItem}
							key={item.id}
							itemKey={item.id}
						>
							{item.name}
							{item.name === value ? icon : null}
						</MenuItem>
					))}
			</Menu>
		</FilterMenu>
	);
};
