import {PrivateRoute} from '@src/containers/PrivateRoute';
import {SchedulesPage} from '@/pages/SchedulesPage';
import {RouteObject} from 'react-router-dom';
import {SchedulePage} from '@/pages/SchedulePage';

export const manageSchedulesRoutes: RouteObject[] = [
	{index: true, element: <PrivateRoute element={<SchedulesPage />} />},
	{
		path: 'add',
		element: <PrivateRoute element={<SchedulePage />} />
	},
	{
		path: ':scheduleId',
		element: <PrivateRoute element={<SchedulePage />} />
	}
];
