import {useSchedulesPageBreadcrumbs} from '@/features/Schedules/hooks/useSchedulesPageBreadcrumbs';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import style from './SchedulePageBreadcrumbs.module.less';

export const SchedulePageBreadcrumbs = () => {
	const {scheduleId} = useStrictParams<{
		scheduleId?: string;
	}>();
	useAppHeader(
		{
			title: scheduleId ? 'Расписание' : 'Настроить расписание',
			showBackBtn: true
		},
		[scheduleId]
	);
	const schedulesPageBreadcrumbs = useSchedulesPageBreadcrumbs();

	return (
		<AppBreadcrumbs
			items={schedulesPageBreadcrumbs}
			className={style.breadcrumbs}
		/>
	);
};
