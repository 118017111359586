import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import {transformLocations} from '@src/pages/CheckListPage/utils/transformLocations';
import {ReactNode} from 'react';
import {useAddingTaskDialog} from '@src/components/AddingTaskDialog';
import {TaskTypeId} from '@tehzor/tools/interfaces/tasks/ITaskType';
import {useShape} from '@src/core/hooks/queries/plans/hooks';
import {queryClient} from '@src/api/QueryClient';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';

export const useAddingSpaceTaskDialog = (space?: ISpace): [ReactNode, () => void] => {
	const [planId, sectorId] = transformLocations(space?.locations);
	const {data: sector} = useShape(space?.objectId, planId, sectorId);
	const onSuccess = () => {
		void queryClient.invalidateQueries({queryKey: spacesQueryKeys.tasks()});
	};
	const sectors = sector ? [{sector: sector.id, name: sector.name}] : undefined;
	const link = sector ? {spaceId: space?.id} : undefined;

	const taskDialogArgs = {
		objectId: space?.objectId,
		taskType: TaskTypeId.SPACE_CALL,
		floor: space?.floor,
		location: {sectors},
		planId,
		links: link,
		onSuccess
	};

	return useAddingTaskDialog(taskDialogArgs);
};
