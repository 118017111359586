import {isSameDay} from 'date-fns';
import {getNearestTimeValue} from './getNearestTimeValue';

export const getAdjustedTime = (
	date: Date,
	isEnd: boolean,
	options: {
		isSingleDayRange: boolean;
		isToday: boolean;
		hidePastTimes?: boolean;
	}
) => {
	const {isSingleDayRange, isToday, hidePastTimes} = options;
	const adjustedTime = new Date(date);
	const now = new Date();

	// Если выбрана сегодняшняя дата и скрываем прошедшее время
	if (hidePastTimes && isSameDay(now, adjustedTime)) {
		return isEnd ? new Date(adjustedTime.setHours(23, 30, 0, 0)) : getNearestTimeValue(now, 30);
	}

	// Если один и тот же день и НЕ сегодня
	if (isSingleDayRange && !isToday && !isEnd) {
		return new Date(adjustedTime.setHours(9, 0, 0, 0));
	}

	// обычный случай, когда обе даты не сегодня
	const hours = isEnd ? 23 : 9;
	const minutes = isEnd ? 30 : 0;
	return new Date(adjustedTime.setHours(hours, minutes, 0, 0));
};
