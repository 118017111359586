export const canSelectTime = (timeValue: Date, hidePastTimes = false) => {
	if (!hidePastTimes) return true;

	const now = new Date();
	const referenceTime = new Date(
		now.getFullYear(),
		now.getMonth(),
		now.getDate(),
		timeValue.getHours(),
		timeValue.getMinutes()
	);

	return referenceTime.getTime() > now.getTime();
};
