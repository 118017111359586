import {CellProps} from 'react-table';
import {useTranslation} from 'react-i18next';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {FixDateTag} from '@src/components/FixDateTag';
import './WorkAcceptanceDeadlineCell.less';
import {DeadlineIntervalDesktop} from '@src/components/DeadlineIntervalDesktop';
import {DeadlineIntervalMobile} from '@src/components/DeadlineIntervalMobile';
import {format} from 'date-fns';
import {ru} from 'date-fns/locale';
import {compareFixDate} from '@tehzor/tools/utils/compareFixDate';

interface IWorkAcceptanceDeadlineProps {
	workAcceptance: IWorkAcceptance;
}

export const WorkAcceptanceDeadline = ({workAcceptance}: IWorkAcceptanceDeadlineProps) => {
	const {t} = useTranslation();
	const {acceptanceIntervalStart, acceptanceIntervalEnd, actualAcceptanceDate} = workAcceptance;
	const isDesktop = useIsDesktop();

	const formattedDateStart = acceptanceIntervalStart
		? format(acceptanceIntervalStart, dateTimeCommaSeparatedFormat, {locale: ru})
		: undefined;
	const formattedDateEnd = acceptanceIntervalEnd
		? format(acceptanceIntervalEnd, dateTimeCommaSeparatedFormat, {locale: ru})
		: undefined;

	return acceptanceIntervalStart && acceptanceIntervalEnd ? (
		<>
			{isDesktop ? (
				<DeadlineIntervalDesktop
					dateStart={formattedDateStart}
					dateEnd={formattedDateEnd}
				/>
			) : (
				<DeadlineIntervalMobile
					dateStart={formattedDateStart}
					dateEnd={formattedDateEnd}
					label={t('problemsPage.mobileProblemCell.plannedFixDateShort')}
				/>
			)}
			{compareFixDate(actualAcceptanceDate, acceptanceIntervalEnd) &&
				acceptanceIntervalEnd && (
					<FixDateTag
						className="fix-date-view-field__tag"
						plannedFixDate={acceptanceIntervalEnd}
						actualFixDate={actualAcceptanceDate}
					/>
				)}
		</>
	) : null;
};

export const WorkAcceptanceDeadlineCell = ({row}: CellProps<{data: IWorkAcceptance}>) => (
	<WorkAcceptanceDeadline workAcceptance={row.original.data} />
);
