import {CSSProperties, ReactNode, useCallback, useRef, useState} from 'react';
import './DatePicker.less';
import DatePickerLayer, {IDatePickerDialogProps} from '../DatePickerLayer';
import {IPopupMenuTriggerFnProps} from '../../menu/PopupMenu';
import InlineDatePicker from '../InlineDatePicker';
import {IDatesOptions} from '../Calendar';
import DatePickerResult from '../DatePickerResult';
import {ru} from 'date-fns/locale';
import {useUpdateEffect} from 'react-use';

export interface IDatePickerTriggerProps extends IPopupMenuTriggerFnProps {
	value?: Date;
}

interface IDatePickerProps {
	className?: string;
	style?: CSSProperties;
	trigger: (props: IDatePickerTriggerProps) => ReactNode;
	dateFormat?: string;
	datesOptions?: IDatesOptions;
	value?: Date;
	dialogProps?: IDatePickerDialogProps['dialogProps'];
	popupProps?: IDatePickerDialogProps['popupProps'];
	placeholder1?: string;
	showTimeSelect?: boolean;
	InlineDatePickerLabel?: string;
	useApplyButton?: boolean;
	selectApplyBtnLabel?: string;
	selectCancelBtnLabel?: string;
	disabledDate?: (value: Date) => boolean;
	onChange?: (value: Date) => void;
}

const DatePicker = (props: IDatePickerProps) => {
	const {
		className,
		style,
		trigger,
		dateFormat = 'dd MMMM yyyy',
		datesOptions = {locale: ru},
		value: propsValue,
		dialogProps,
		popupProps,
		placeholder1,
		showTimeSelect,
		selectApplyBtnLabel,
		InlineDatePickerLabel,
		selectCancelBtnLabel,
		useApplyButton,
		onChange,
		disabledDate
	} = props;

	const [isOpen, setIsOpen] = useState(false);
	const [value, setValue] = useState<Date | undefined>(propsValue);

	// необходим, чтобы при сбросе значений из trigger'а данные корректно сбрасывались
	useUpdateEffect(() => {
		setValue(propsValue);
	}, [propsValue]);
	const resolveFn = useRef<() => void | undefined>();

	const open = useCallback(() => {
		setIsOpen(true);
	}, []);

	const close = useCallback(() => {
		setIsOpen(false);
		setValue(propsValue);
	}, [propsValue]);

	const applyValue = useCallback(
		(v: Date) => {
			setIsOpen(false);
			// Применение нового значения только после закрытия окна/попапа
			void new Promise<void>(resolve => {
				resolveFn.current = resolve;
			}).then(() => {
				resolveFn.current = undefined;
				if (onChange) {
					onChange(v);
				}
			});
		},
		[onChange]
	);

	const handleApply = useCallback(() => {
		if (value) {
			applyValue(value);
		}
	}, [value, applyValue]);

	const handleChange = useCallback(
		(v: Date) => {
			if (!useApplyButton) {
				applyValue(v);
			} else {
				setValue(v);
			}
		},
		[applyValue, useApplyButton]
	);

	const handleClosingComplete = useCallback(() => {
		if (resolveFn.current) {
			resolveFn.current();
		}
	}, []);

	const getTrigger = useCallback(
		(triggerProps: IPopupMenuTriggerFnProps) =>
			trigger({
				...triggerProps,
				value: propsValue
			}),
		[propsValue, trigger]
	);

	const result = useApplyButton && (
		<DatePickerResult
			className="date-picker__result"
			value1={value}
			placeholder1={placeholder1 || 'Выберите дату'}
			dateFormat={dateFormat}
			dateOptions={datesOptions}
		/>
	);

	return (
		<DatePickerLayer
			trigger={getTrigger}
			result={result}
			isOpen={isOpen}
			isButtonsVisible={useApplyButton}
			dialogProps={dialogProps}
			popupProps={popupProps}
			onApply={handleApply}
			selectApplyBtnLabel={selectApplyBtnLabel}
			selectCancelBtnLabel={selectCancelBtnLabel}
			onOpen={open}
			onClose={close}
			onAfterClose={handleClosingComplete}
		>
			<InlineDatePicker
				className={className}
				style={style}
				datesOptions={datesOptions}
				showTimeSelect={showTimeSelect}
				label={InlineDatePickerLabel}
				value={value}
				onChange={handleChange}
				disabledDate={disabledDate}
			/>
		</DatePickerLayer>
	);
};

export default DatePicker;
