import {CellProps} from 'react-table';
import {FixDateTag} from '@src/components/FixDateTag/FixDateTag';
import {IPreparedWorkAcceptance} from '@src/interfaces/IPreparedWorkAcceptance';
import {compareFixDate} from '@tehzor/tools/utils/compareFixDate';
import {DeadlineIntervalDesktop} from '@src/components/DeadlineIntervalDesktop';

export const FixDateCell = ({row}: CellProps<IPreparedWorkAcceptance>) => {
	const {
		acceptanceIntervalStart,
		acceptanceIntervalEnd,
		actualAcceptanceDateOriginal,
		acceptanceIntervalEndOriginal
	} = row.original;
	if (!acceptanceIntervalStart || !acceptanceIntervalEnd) {
		return null;
	}

	return (
		<div className="work-acceptances-page__d-table-plannedFixDate">
			<DeadlineIntervalDesktop
				dateStart={acceptanceIntervalStart}
				dateEnd={acceptanceIntervalEnd}
			/>
			{compareFixDate(actualAcceptanceDateOriginal, acceptanceIntervalEndOriginal) &&
				acceptanceIntervalEndOriginal && (
					<FixDateTag
						className="fix-date-view-field__tag"
						plannedFixDate={acceptanceIntervalEndOriginal}
						actualFixDate={actualAcceptanceDateOriginal}
					/>
				)}
		</div>
	);
};
