import './CheckListsPage.less';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import AppUpdateMenu from '@src/components/AppUpdateData/AppUpdateMenu';
import EntitiesFiltersProvider from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {Table} from '@src/pages/manage/CheckListsPage/components/table/Table.desktop';
import {PageBreadcrumbs} from '@src/pages/manage/CheckListsPage/components/PageBreadcrumbs';
import {useCheckListPermissions} from '@src/core/hooks/permissions/useCheckListPermissions';
import {useCheckListsIsLoading} from '@src/core/hooks/queries/checkLists/hooks';
import {useCheckListsFilters as useFilters} from './hooks/useCheckListsFilters';
import {MobileTable} from './components/table/Table.mobile';
import {DesktopAddButton} from './components/DesktopAddButton';
import {CheckListsFilters} from './components/CheckListsFilters';

export const CheckListsPage = () => {
	useAppHeader({
		title: 'Чек-листы',
		mobileRightButtons: <AppUpdateMenu />
	});
	const permissions = useCheckListPermissions();

	const isDesktop = useIsDesktop();

	const checkListsIsLoading = useCheckListsIsLoading();
	const {filters, applyFilters, onClear} = useFilters();

	if (isDesktop) {
		return (
			<EntitiesFiltersProvider
				filters={filters}
				onApply={applyFilters}
				onClear={onClear}
			>
				<div className="page-cont manage-check-lists-page">
					<PageBreadcrumbs permissions={permissions} />
					<CheckListsFilters />

					<Table loading={checkListsIsLoading} />
				</div>
			</EntitiesFiltersProvider>
		);
	}
	return (
		<div className="page-cont manage-check-lists-page_mobile">
			<PageBreadcrumbs permissions={permissions} />
			<MobileTable loading={checkListsIsLoading} />
			{permissions.canAdd ? <DesktopAddButton /> : null}
		</div>
	);
};
