import {Controls} from '@/shared/ui/EntitiesPage/Controls/Controls';
import {Wrapper} from '@/shared/ui/EntitiesPage/Wrapper/Wrapper';
import {EntitiesPageWrapper} from '@/widgets/EntitiesPageWrapper/EntitiesPageWrapper';
import {SchedulesTopBar} from '../components/SchedulesTopBar';
import {LoadingIndicator} from '@/shared/ui/LoadingIndicator/LoadingIndicator';
import {SchedulesTable} from '@/widgets/SchedulesTable/ui/SchedulesTable';
import {useSchedulesPaginateList} from '@/entities/Schedules';

export const SchedulesPage = () => {
	const {data, isFetching, refetch: refetchSchedules} = useSchedulesPaginateList();
	const appHeaderOptions = {
		title: 'Расписания'
	};

	return (
		<EntitiesPageWrapper
			objectId="all"
			appHeaderOptions={appHeaderOptions}
		>
			<Wrapper>
				<Controls>
					<SchedulesTopBar />
				</Controls>

				<LoadingIndicator
					text={`Всего: `}
					isFetching={isFetching}
					totalEntities={data?.total ?? 0}
					onClick={refetchSchedules}
				/>
				<SchedulesTable total={data?.total ?? 0} />
			</Wrapper>
		</EntitiesPageWrapper>
	);
};
