import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {useFilteredWorkingGroups} from '@src/core/hooks/queries/workingGroups/hooks';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {ResponsiblesFilterPage} from '../ResponsiblesFilterPage';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';
import {useObjectChildrenIds} from '@src/core/hooks/queries/objects/hooks';

export const PerformersFilterPage = () => {
	const {t} = useTranslation();
	const {objectId, entity} = useStrictParams<{objectId: string; entity?: string}>();

	const {state, change} = useEntitiesFiltersCtx<{
		performers?: string[];
		objects?: string[];
		stages?: string[];
	}>();

	const nestedObjects = useObjectChildrenIds(objectId);

	const objectIds = useMemo(() => {
		if (state.objects?.length || objectId === 'all') {
			return state.objects;
		}
		if (nestedObjects?.length) {
			return [...nestedObjects, objectId];
		}
		return [objectId];
	}, [state.objects, objectId, nestedObjects]);

	const {data: groups} = useFilteredWorkingGroups({
		objects: objectIds,
		stages: state.stages,
		types: [WorkingGroupTypeId.PERFORMERS]
	});

	const handleChange = useCallback(
		(value?: string[]) => {
			change({performers: value});
		},
		[change]
	);

	return (
		<ResponsiblesFilterPage
			objectId={objectId}
			entity={entity}
			respUsers={state.performers}
			label={t('entitiesFilters.performers.label')}
			workingGroups={groups}
			onChange={handleChange}
		/>
	);
};
