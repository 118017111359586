import {Plate} from '@tehzor/ui-components';
import styles from './TimeInterval.module.less';
import {TimeRange} from '../TimeRange/TimeRange';
import {RepeatPeriodsSelect} from '../RepeatPeriodsSelect/RepeatPeriodsSelect';
import {SelectedDays} from '../SelectedDays/SelectedDays';
import {AdvancedIntervalSettings} from '../AdvancedIntervalSettings/AdvancedIntervalSettings';

interface ITimeInterval {
	index: number;
}

export const TimeInterval = ({index}: ITimeInterval) => (
	<Plate
		className={{root: styles.root, content: styles.wrap}}
		withoutPadding
	>
		<div className={styles.header}>
			<h4 className={styles.headerText}>{`Интервал ${index + 1}`}</h4>
			<i className="tz-delete" />
		</div>

		<div className={styles.topInputsWrap}>
			<TimeRange index={index} />
			<RepeatPeriodsSelect index={index} />
		</div>
		<SelectedDays index={index} />
		<AdvancedIntervalSettings index={index} />
	</Plate>
);
