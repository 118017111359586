import {TextField} from '@tehzor/ui-components';
import {useController, useFormContext} from 'react-hook-form';
import {IScheduleFieldProps} from '../types/IScheduleFieldProps';
import {FieldLabel} from '@/shared/ui/FieldLabel/FieldLabel';
import {IScheduleForm} from '../types/IScheduleForm';

export const SimultaneousSlots = ({
	field,
	onSetValue,
	required,
	className,
	errorMessage = 'Введите значение от 0 до 1000'
}: IScheduleFieldProps) => {
	const {control} = useFormContext<IScheduleForm>();

	const {
		field: {value},
		fieldState: {invalid}
	} = useController({
		name: field,
		control,
		rules: {
			required,
			validate: v => Number(v) >= 0 && Number(v) <= 1000
		}
	});

	const handleChange = (v: string) => {
		const parsedValue = !isNaN(Number(v)) && v.trim() !== '' ? Number(v) : v;
		onSetValue(field, parsedValue);
	};

	return (
		<div className={className}>
			<FieldLabel
				label="Количество одновременных слотов"
				required={required}
			/>
			<TextField
				value={String(value)}
				elementType="input"
				onChange={handleChange}
				error={invalid ? errorMessage : undefined}
			/>
		</div>
	);
};
