import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';
import {IRuleParams} from '@tehzor/tools/utils/responsibilityRules/IRuleParams';
import {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import isEqual from 'lodash/isEqual';
import {useController, useFormContext} from 'react-hook-form';
import {editableProblemFields} from '../useEditableProblemForm/editableProblemFields';
import {Responsibles} from './Responsibles';
import {SetValueOptions} from './ProblemForm';

interface IEditablePerformersProps {
	objectId: string;
	stage?: ObjectStageIds;
	scope?: string;
	name: string;
	onSetValue?: (name: string, value: unknown, options?: SetValueOptions) => void;
	disabled?: boolean;
	errorMessage?: string;
	required?: boolean;
}

export const EditableProblemPerformers = ({
	objectId,
	stage,
	scope,
	name,
	onSetValue,
	disabled,
	errorMessage,
	required
}: IEditablePerformersProps) => {
	const {t} = useTranslation();
	const {getValues, control} = useFormContext();
	const [categoryId, planId, performersActiveGroup, initialRuleParams] = getValues([
		editableProblemFields.CATEGORY_ID,
		editableProblemFields.PLAN_ID,
		editableProblemFields.PERFORMERS_ACTIVE_GROUP,
		editableProblemFields.INITIAL_RULE_PARAMS
	]);

	const ruleParams: IRuleParams = useMemo(
		() => ({categoryId: categoryId || undefined, planId}),
		[categoryId, planId]
	);

	// обязательное требование заполнения снимается если нет исполнителей на выбор или выбрана группа
	const [isException, setIsException] = useState<boolean | undefined>(undefined);
	const {
		field: {value: performers},
		fieldState: {invalid}
	} = useController({
		name,
		control,
		rules: {
			required: isException || performersActiveGroup ? false : required
		}
	});

	const handleChange = useCallback(
		(users: string[], group?: string) => {
			if (onSetValue) {
				onSetValue(name, users);
				onSetValue(editableProblemFields.PERFORMERS_ACTIVE_GROUP, group);
			}
		},
		[onSetValue, name]
	);

	const handleUpdateException = useCallback(
		(value: boolean) => {
			setIsException(value);
		},
		[setIsException]
	);

	const checkDoSuggestions = useCallback(() => {
		if (isEqual(initialRuleParams, ruleParams)) {
			return false;
		}
		if (onSetValue) {
			onSetValue(editableProblemFields.INITIAL_RULE_PARAMS, undefined);
		}
		return true;
	}, [initialRuleParams, onSetValue, ruleParams]);

	return (
		<Responsibles
			objectId={objectId}
			stage={stage}
			scope={scope}
			workingGroupType={WorkingGroupTypeId.PERFORMERS}
			respUsers={performers}
			activeGroup={performersActiveGroup}
			ruleParams={ruleParams}
			label={t('components.editableProblem.performers.label')}
			errorLabel={invalid && !performersActiveGroup ? errorMessage : undefined}
			dialogTitle={t('components.delegationDialog.performers.title')}
			disabled={disabled}
			onResponsiblesChange={handleChange}
			onUpdateException={handleUpdateException}
			checkDoSuggestions={checkDoSuggestions}
			required={required}
		/>
	);
};
