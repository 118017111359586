import {SidebarMenu, SidebarMenuItem, SidebarMenuSeparator} from '@tehzor/ui-components';
import {useNavPermissions} from '@src/core/hooks/useNavPermissions';
import {usePageKey} from '../utils/usePageKey';
import {ISidebarMenuItemProps} from '@tehzor/ui-components/src/components/sidebar/SidebarMenuItem/SidebarMenuItem';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useTranslation} from 'react-i18next';
import {useCallback} from 'react';

interface IAppSidebarMenuProps {
	type: ISidebarMenuItemProps['type'];

	closeSidebar?(): Promise<void>;
}

export const AppSidebarMenu = ({type, closeSidebar}: IAppSidebarMenuProps) => {
	const {t} = useTranslation();

	const permissions = useNavPermissions();
	const pageKey = usePageKey();
	const {pushPath} = useChangePath();

	const handleClick = useCallback(
		async (path?: string) => {
			if (path) {
				if (closeSidebar) {
					await closeSidebar();
				}
				pushPath(`/${path}`);
			}
		},
		[closeSidebar]
	);

	return (
		<SidebarMenu>
			<SidebarMenuItem
				dataTestId="SideBarMenuItem"
				icon={<i className="tz-object-28" />}
				label={t('sidebar.objects')}
				type={type}
				active={pageKey === 'objects'}
				data="objects"
				onClick={handleClick}
			/>
			{permissions.problemsView && (
				<SidebarMenuItem
					dataTestId="SideBarMenuItem"
					icon={<i className="tz-problem-28" />}
					label={t('sidebar.problems')}
					type={type}
					active={pageKey === 'problems'}
					data="problems"
					onClick={handleClick}
				/>
			)}
			{permissions.inspectionsView && (
				<SidebarMenuItem
					dataTestId="SideBarMenuItem"
					icon={<i className="tz-inspection-28" />}
					label={t('sidebar.inspections')}
					type={type}
					active={pageKey === 'inspections'}
					data="inspections"
					onClick={handleClick}
				/>
			)}
			{permissions.tasksView && (
				<SidebarMenuItem
					dataTestId="SideBarMenuItem"
					icon={<i className="tz-task-28" />}
					label={t('sidebar.tasks')}
					type={type}
					active={pageKey === 'tasks'}
					data="tasks"
					onClick={handleClick}
				/>
			)}
			{permissions.checksView && (
				<SidebarMenuItem
					dataTestId="SideBarMenuItem"
					icon={<i className="tz-check-28" />}
					label={t('sidebar.checks')}
					type={type}
					active={pageKey === 'checks'}
					data="checks"
					onClick={handleClick}
				/>
			)}
			{permissions.workAcceptancesView && (
				<SidebarMenuItem
					dataTestId="SideBarMenuItem"
					icon={<i className="tz-work-acceptance-28" />}
					label={t('sidebar.workAcceptances')}
					type={type}
					active={pageKey === 'workAcceptances'}
					data="work-acceptances"
					onClick={handleClick}
				/>
			)}
			{permissions.internalAcceptancesView && (
				<SidebarMenuItem
					dataTestId="SideBarMenuItem"
					icon={<i className="tz-internal-acceptance-28" />}
					label={t('sidebar.internalAcceptances')}
					type={type}
					active={pageKey === 'internalAcceptances'}
					data="internal-acceptances"
					onClick={handleClick}
				/>
			)}
			{permissions.ownerAcceptancesView && (
				<SidebarMenuItem
					dataTestId="SideBarMenuItem"
					icon={<i className="tz-owner-acceptance-28" />}
					label={t('sidebar.ownerAcceptances')}
					type={type}
					active={pageKey === 'ownerAcceptances'}
					data="owner-acceptances"
					onClick={handleClick}
				/>
			)}
			{permissions.warrantyClaimsView && (
				<SidebarMenuItem
					dataTestId="SideBarMenuItem"
					icon={<i className="tz-warranty-claim-28" />}
					label={t('sidebar.warrantyClaims')}
					type={type}
					active={pageKey === 'warrantyClaims'}
					data="warranty-claims"
					onClick={handleClick}
				/>
			)}
			{permissions.scheduledPlansView && (
				<SidebarMenuItem
					dataTestId="SideBarMenuItem"
					icon={<i className="tz-schedule-plan-28" />}
					label={'Планы-графики'}
					type={type}
					active={pageKey === 'scheduledPlans'}
					data="scheduled-plans"
					onClick={handleClick}
				/>
			)}
			{permissions.documentsView && (
				<>
					<SidebarMenuSeparator type={type} />
					<SidebarMenuItem
						dataTestId="SideBarMenuItem"
						icon={<i className="tz-check-list-manage-28" />}
						label={t('sidebar.documents')}
						type={type}
						active={pageKey === 'documents'}
						data="documents"
						onClick={handleClick}
					/>
				</>
			)}
			{permissions.contractsView && (
				<SidebarMenuItem
					dataTestId="SideBarMenuItem"
					icon={<i className="tz-contract-28" />}
					label={t('sidebar.contracts')}
					type={type}
					active={pageKey === 'contracts'}
					data="contracts"
					onClick={handleClick}
				/>
			)}
			{permissions.legalsView && (
				<SidebarMenuItem
					dataTestId="SideBarMenuItem"
					icon={<i className="tz-legal-entity-28" />}
					label={t('sidebar.legalEntities')}
					type={type}
					active={pageKey === 'legals'}
					data="legals"
					onClick={handleClick}
				/>
			)}
			{permissions.statsView && (
				<>
					<SidebarMenuSeparator type={type} />
					<SidebarMenuItem
						dataTestId="SideBarMenuItem"
						icon={<i className="tz-statistics-28" />}
						label={t('sidebar.statistics')}
						type={type}
						active={pageKey === 'statistics'}
						data="statistics"
						onClick={handleClick}
					/>
				</>
			)}
			{permissions.checkListsEdit && (
				<>
					<SidebarMenuSeparator type={type} />
					<SidebarMenuItem
						dataTestId="SideBarMenuItem"
						icon={<i className="tz-check-list-manage-28" />}
						label={t('sidebar.management.checklists')}
						type={type}
						active={pageKey === 'manage/check-lists'}
						data="manage/check-lists"
						onClick={handleClick}
					/>
				</>
			)}
			{permissions.workingGroupEdit && (
				<SidebarMenuItem
					dataTestId="SideBarMenuItem"
					icon={<i className="tz-group-manage-28" />}
					label={t('sidebar.management.workingGroups')}
					type={type}
					active={pageKey === 'manage/working-groups'}
					data="manage/working-groups"
					onClick={handleClick}
				/>
			)}
			{permissions.spaceTypeDecorationEdit && (
				<SidebarMenuItem
					dataTestId="SideBarMenuItem"
					icon={<i className="tz-space-type-manage-28" />}
					label={t('sidebar.management.spaceTypeDecorations')}
					type={type}
					active={pageKey === 'manage/space-type-decoration'}
					data="manage/space-type-decoration"
					onClick={handleClick}
				/>
			)}
			{permissions.categoriesSetView && (
				<SidebarMenuItem
					dataTestId="SideBarMenuItem"
					icon={<i className="tz-category-manage-28" />}
					label={t('sidebar.management.categories')}
					type={type}
					active={pageKey === 'categoriesSets'}
					data="manage/categories-sets"
					onClick={handleClick}
				/>
			)}

			{permissions.problemTagsSetsEdit && (
				<SidebarMenuItem
					dataTestId="SideBarMenuItem"
					icon={<i className="tz-tag-manage-28" />}
					label={t('sidebar.management.tags')}
					type={type}
					active={pageKey === 'manage/problem-tags-sets'}
					data="manage/problem-tags-sets"
					onClick={handleClick}
				/>
			)}

			{permissions.exportTemplatesEdit && (
				<SidebarMenuItem
					dataTestId="SideBarMenuItem"
					icon={<i className="tz-export-template-manage-28" />}
					label={t('sidebar.management.exportTemplates')}
					type={type}
					active={pageKey === 'manage/export-templates'}
					data="manage/export-templates"
					onClick={handleClick}
				/>
			)}

			{permissions.spaceStatusesSetsView && (
				<SidebarMenuItem
					dataTestId="SideBarMenuItem"
					icon={<i className="tz-space-status-manage-28" />}
					label={t('sidebar.management.spaceStatuses')}
					type={type}
					active={pageKey === 'manage/space-statuses-sets'}
					data="manage/space-statuses-sets"
					onClick={handleClick}
				/>
			)}

			{permissions.fieldsView && (
				<SidebarMenuItem
					dataTestId="SideBarMenuItem"
					icon={<i className="tz-fields-28" />}
					label={t('sidebar.management.fields')}
					type={type}
					active={pageKey === 'manage/fields'}
					data="manage/fields"
					onClick={handleClick}
				/>
			)}
			{permissions.schedulesView && (
				<SidebarMenuItem
					dataTestId="SideBarMenuItem"
					icon={<i className="tz-schedules-28" />}
					label="Расписания"
					type={type}
					active={pageKey === 'manage/schedules'}
					data="manage/schedules"
					onClick={handleClick}
				/>
			)}
		</SidebarMenu>
	);
};
