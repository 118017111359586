import {
	ActionButtons,
	Button,
	Menu,
	MenuItem,
	ModalDialog,
	TextField,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import {TranslatedSelectPopup} from '@src/components/TranslatedSelectPopup';
import styles from './DurationSelectDialog.module.less';
import {memo, useCallback, useMemo, useState} from 'react';
import {
	IDurationMeasure,
	IDurationMeasures,
	durationMeasureKeys
} from '@/shared/types/IDurationMeasure';
import {useUpdateEffect} from 'react-use';
import {durationMultipliers} from './constants/durationMultipliers';

interface IDurationSelectDialog {
	isOpen: boolean;
	onClose: () => void;
	duration: number;
	setDuration: (durationValue: number) => void;
	durationMeasure: IDurationMeasure;
	setDurationMeasure: (measure: IDurationMeasure) => void;
	dialogError: (duration: number, durationMeasure?: IDurationMeasure) => string | undefined;
	title?: string;
	durationMeasures: IDurationMeasures;
	disableDurationMeasure: boolean;
}

const arrowIcon = <i className="tz-simple-arrow-20" />;

const modalClasses = {root: styles.root, body: styles.body, title: styles.title};

const convertDurationToLocal = (duration: number, measure: durationMeasureKeys): number => {
	if (measure === durationMeasureKeys.HOUR) return duration / 60;
	if (measure === durationMeasureKeys.DAY) return duration / 1440; // 1440 минут в дне
	return duration;
};

export const DurationSelectDialog = memo(
	({
		isOpen,
		onClose,
		duration,
		setDuration,
		durationMeasure,
		setDurationMeasure,
		dialogError,
		title,
		durationMeasures,
		disableDurationMeasure
	}: IDurationSelectDialog) => {
		const [localDuration, setLocalDuration] = useState(
			String(convertDurationToLocal(duration, durationMeasure.key))
		);
		const [localDurationMeasure, setLocalDurationMeasure] =
			useState<IDurationMeasure>(durationMeasure);

		const [durationError, setDurationError] = useState(false);
		const [durationMeasureError, setDurationMeasureError] = useState(false);

		useUpdateEffect(() => {
			setLocalDuration(String(convertDurationToLocal(duration, durationMeasure.key)));
		}, [duration, durationMeasure]);

		const handleDurationChange = (v: string) => {
			setLocalDuration(v);
			setDurationError(false);
		};
		const handleDurationMeasurementChange = (v: durationMeasureKeys) => {
			const selectedValue = durationMeasures[v];
			setLocalDurationMeasure(selectedValue);
			setDurationMeasureError(false);
		};

		const handleClose = useCallback(() => {
			onClose();
			setDurationMeasureError(false);
		}, [onClose]);

		const handleCancel = useCallback(() => {
			setLocalDuration(String(convertDurationToLocal(duration, durationMeasure.key)));
			setLocalDurationMeasure(durationMeasure);
			onClose();
		}, [onClose, duration, durationMeasure]);

		const handleApply = useCallback(() => {
			if (dialogError(Number(localDuration), localDurationMeasure)) {
				setDurationError(true);
				return;
			}
			if (!localDurationMeasure) {
				setDurationMeasureError(true);
				return;
			}
			setDurationMeasureError(false);

			const multiplier = durationMultipliers[localDurationMeasure.key];
			const newDuration = Number(localDuration) * multiplier;
			setDuration(newDuration);
			setDurationMeasure(localDurationMeasure);
			handleClose();
		}, [
			dialogError,
			handleClose,
			localDuration,
			localDurationMeasure,
			setDuration,
			setDurationMeasure
		]);

		const footer = useMemo(
			() => (
				<ActionButtons>
					<Button
						type="cancel"
						label="Отменить"
						onClick={handleCancel}
					/>
					<Button
						type="accent-blue"
						label="Подтверить"
						onClick={handleApply}
						disabled={durationError}
					/>
				</ActionButtons>
			),
			[handleCancel, handleApply, durationError]
		);
		return (
			<ModalDialog
				open={isOpen}
				onRequestClose={handleClose}
				className={modalClasses}
				title={title}
				footer={footer}
			>
				<>
					<TextField
						elementType="input"
						className={styles.item}
						onChange={handleDurationChange}
						value={localDuration.toString()}
						error={
							durationError
								? dialogError(Number(localDuration), localDurationMeasure)
								: undefined
						}
					/>

					<TranslatedSelectPopup
						trigger={
							<TextFieldWithForwardedRef
								elementType="div"
								className={styles.item}
								value={localDurationMeasure?.value ?? ''}
								icon={arrowIcon}
								error={
									durationMeasureError ? 'Выберите единицу измерения' : undefined
								}
								disabled={disableDurationMeasure}
							/>
						}
					>
						<Menu
							value={localDurationMeasure?.key}
							onChange={handleDurationMeasurementChange}
						>
							{Object.values(durationMeasures).map(i => (
								<MenuItem
									key={i.key}
									itemKey={i.key}
								>
									<div>{i.value}</div>
								</MenuItem>
							))}
						</Menu>
					</TranslatedSelectPopup>
				</>
			</ModalDialog>
		);
	}
);
