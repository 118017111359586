import {useSchedulesPageBreadcrumbs} from '@/features/Schedules/hooks/useSchedulesPageBreadcrumbs';
import {TopBar} from '@/shared/ui/EntitiesPage/TopBar/TopBar';
import {Button} from '@tehzor/ui-components';
import {useChangePath} from '@src/core/hooks/useChangePath';

const iconPlus = <i className="tz-plus-20" />;

export const SchedulesTopBar = () => {
	const {pushPath} = useChangePath();

	const onClick = () => {
		pushPath('/manage/schedules/add');
	};

	const schedulesPageBreadcrumbs = useSchedulesPageBreadcrumbs();
	return (
		<TopBar breadcrumbs={schedulesPageBreadcrumbs}>
			<Button
				type="accent-blue"
				leftIcon={iconPlus}
				label="Добавить расписание"
				onClick={onClick}
			/>
		</TopBar>
	);
};
