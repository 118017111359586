export const getNearestTimeValue = (date: Date, interval = 30): Date => {
	const minutes = date.getMinutes();
	const remainder = minutes % interval;
	const roundedUpMinutes = remainder === 0 ? minutes : minutes + (interval - remainder);
	return new Date(
		date.getFullYear(),
		date.getMonth(),
		date.getDate(),
		date.getHours(),
		roundedUpMinutes
	);
};
