import {useCallback, useMemo, useState} from 'react';
import {LoadingPanel} from '@tehzor/ui-components';
import './ObjectsPage.less';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {ObjectsDisplayMode} from '@src/interfaces/ObjectsDisplayMode';
import {PlatesPage} from './components/PlatesPage';
import {ListPage} from './components/ListPage';
import EntitiesFiltersProvider from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {extractObjectsPageSettings} from '@src/store/modules/settings/pages/objects/selectors';
import {IObjectsFiltersState} from '@src/store/modules/settings/pages/objects/reducers';
import {changeFilters, clearFilters} from '@src/store/modules/settings/pages/objects/actions';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {flattenTree} from '@src/utils/tree';
import ObjectsFilters from './components/filters/ObjectsFilters';
import {ObjectsBreadcrumbs} from './components/breadcrumbs/ObjectsBreadcrumbs';
import {ObjectsMenu} from './components/menu/ObjectsMenu';
import {useIsFetching} from '@tanstack/react-query';
import {objectsQueryKeys} from '@src/api/cache/objects/keys';
import {useFilteredWithNewFiltersTreeObjects} from '@src/core/hooks/queries/objects/hooks';
import {useTranslation} from 'react-i18next';

/**
 * Страница списка объектов
 */
export const ObjectsPage = () => {
	const {t, i18n} = useTranslation();

	useScrollRestoration();

	useAppHeader({title: t('objectsPage.title'), mobileRightButtons: <ObjectsMenu />}, [
		i18n.language
	]);

	const [search, setSearch] = useState<string | undefined>(undefined);

	const treeObjects = useFilteredWithNewFiltersTreeObjects(search);

	const text = useMemo(() => {
		if (!treeObjects) return '';
		const count = flattenTree(treeObjects).length;
		return `${t('objectsPage.count.label')}: ${count}`;
	}, [treeObjects]);

	const dispatch = useAppDispatch();

	const {filters} = useAppSelector(extractObjectsPageSettings) || {};
	const {displayMode = ObjectsDisplayMode.SCHEMA} = useAppSelector(extractObjectsPageSettings);
	const isLoading = !!useIsFetching({queryKey: objectsQueryKeys.list()});

	const applyFilters = useCallback((value: IObjectsFiltersState) => {
		dispatch(changeFilters('all', value));
	}, []);

	const onClear = useCallback(() => {
		dispatch(clearFilters());
	}, []);

	if (!treeObjects || isLoading) {
		return (
			<LoadingPanel
				className="objects-page__loading-panel2"
				active={isLoading}
			/>
		);
	}

	return (
		<div className="page-cont">
			<EntitiesFiltersProvider
				objectId="all"
				filters={filters}
				onApply={applyFilters}
				onClear={onClear}
			>
				<ObjectsBreadcrumbs />
				<ObjectsFilters
					search={search}
					setSearch={setSearch}
				/>
				{displayMode === ObjectsDisplayMode.SCHEMA && (
					<PlatesPage
						text={text}
						treeObjects={treeObjects}
					/>
				)}
				{displayMode === ObjectsDisplayMode.LIST && (
					<ListPage
						text={text}
						treeObjects={treeObjects}
						search={search}
					/>
				)}
			</EntitiesFiltersProvider>
		</div>
	);
};
