import {TextField} from '@tehzor/ui-components';
import {useController, useFormContext} from 'react-hook-form';
import {memo, useEffect, useRef} from 'react';
import styles from './TimeInput.module.less';
import {ITimeRangeKeys} from '../../types/IntervalFieldsKeys';
import {ErrorMessages} from '../../constants/ErrorMessages';
import {IScheduleForm} from '../../types/IScheduleForm';

interface ITimeInput {
	field: ITimeRangeKeys;
	onFocusBlur?: (isFocused: boolean) => void;
	required?: boolean;
	errorWithoutMessage?: string | undefined;
}

export const TimeInput = memo(({field, required, onFocusBlur, errorWithoutMessage}: ITimeInput) => {
	const {control} = useFormContext<IScheduleForm>();
	const inputRef = useRef<HTMLInputElement | null>(null);

	const {
		field: {value, onChange: fieldOnChange, onBlur: fieldOnBlur},
		fieldState: {error}
	} = useController({
		name: field,
		control,
		rules: {
			required: required ? ErrorMessages.requiredField : false,
			validate: value => {
				// проверка на соответсвие формату HH:mm
				if (!value.includes(':')) {
					return ErrorMessages.invalidTimeValue;
				}

				const [hoursStr, minutesStr] = value.split(':');

				if (!hoursStr?.trim() || !minutesStr?.trim()) {
					return ErrorMessages.invalidTimeValue;
				}

				const hours = Number(hoursStr);
				const minutes = Number(minutesStr);

				if (
					Number.isNaN(hours) ||
					Number.isNaN(minutes) ||
					hours < 0 ||
					hours > 23 ||
					minutes < 0 ||
					minutes > 59
				) {
					return ErrorMessages.invalidTimeValue;
				}

				return true;
			}
		}
	});

	useEffect(() => {
		const inputElement = inputRef.current;

		if (!inputElement) {
			return undefined;
		}

		const handleFocus = () => {
			if (onFocusBlur) {
				onFocusBlur(true);
			}
		};

		const handleBlur = () => {
			fieldOnBlur();
			if (onFocusBlur) {
				onFocusBlur(false);
			}
		};

		inputElement.addEventListener('focus', handleFocus);
		inputElement.addEventListener('blur', handleBlur);

		return () => {
			inputElement.removeEventListener('focus', handleFocus);
			inputElement.removeEventListener('blur', handleBlur);
		};
	}, [fieldOnBlur, onFocusBlur]);

	const handleChange = (value: string) => {
		let newValue = value;

		if (newValue.length > 5) newValue = newValue.slice(0, 5);

		if (newValue.length >= 3 && !newValue.includes(':')) {
			newValue = `${newValue.slice(0, 2)}:${newValue.slice(2)}`;
		}

		fieldOnChange(newValue);
	};

	return (
		<div className={styles.wrap}>
			{!value.length && (
				<div className={styles.placeholder}>
					<span className={styles.hours}>чч</span>
					<span className={styles.separator}>:</span>
					<span className={styles.minutes}>мм</span>
				</div>
			)}
			<TextField
				value={value}
				onChange={handleChange}
				className={{element: styles.element}}
				elementType="input"
				error={error?.message || undefined || errorWithoutMessage}
				elementRef={inputRef}
			/>
		</div>
	);
});
