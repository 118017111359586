import {Calendar} from './components/Calendar/Calendar';
import {StageSelect} from './components/StageSelect';
import styles from './ScheduleForm.module.less';
import {editableScheduleFields} from './constants/editableScheduleFields';
import {ProcessSelect} from './components/ProcessSelect';
import {ObjectsSelect} from './components/ObjectsSelect';
import {ParticipantsSelect} from './components/ParticipantsSelect';
import {DateRange} from './components/DateRange/DateRange';
import {SimultaneousSlots} from './components/SimultaneousSlots';
import {SlotDurationSelect} from './components/SlotDurationSelect';
import {SlotsIntervalSelect} from './components/SlotsIntervalSelect';
import {MaxPreregistrationPeriodSelect} from './components/MaxPreregistrationPeriodSelect';
import {MinPreregistrationPeriodSelect} from './components/MinPreregistrationPeriodSelect';
import {MaxOccupiedSlots} from './components/MaxOccupiedSlots/MaxOccupiedSlots';
import {SetValueOptions} from '@/shared/types/SetValueOptions';
import {IScheduleForm} from './types/IScheduleForm';
import {ScheduleFieldValueType} from './types/IScheduleFieldProps';
import {CompanySelect} from './components/CompanySelect';
import {createErrorMessages} from './utils/schedules/createErrorMessages';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useAddSchedule, useEditSchedule} from '@/entities/Schedules';
import {useDefaultScheduleFormData} from './hooks/schedules/useDefaultScheduleFormData';
import {FormProvider, useForm} from 'react-hook-form';
import {useCallback} from 'react';
import {Plate, Scrollbar} from '@tehzor/ui-components';
import {ActionsButtons} from './ui/ActionsButtons/ActionsButtons';
import {getPreparedMainInfoFormData} from './utils/schedules/getPreparedMainInfoFormData';
import {TimeIntervals} from './components/TimeIntervals/TimeIntervals';
import {ParticipantsErrorTypes} from './types/ParticipantsErrorTypes';
import {ScheduleErrorMessage} from '@tehzor/tools/enums/ScheduleErrorMessage';

const simpleBarProps = {autoHide: false};

export const ScheduleForm = () => {
	const errors = createErrorMessages();
	const {scheduleId} = useStrictParams<{
		scheduleId?: string;
	}>();
	const {pushPath} = useChangePath();
	const {mutateAsync: addSchedule, isSuccess: isAddingSuccess} = useAddSchedule();
	const {mutateAsync: editSchedule, isSuccess: isEditingSuccess} = useEditSchedule();
	const defaultValues = useDefaultScheduleFormData(scheduleId);

	const methods = useForm<IScheduleForm>({
		defaultValues,
		values: defaultValues,
		shouldUnregister: false,
		mode: 'all'
	});

	const {
		setValue,
		getValues,
		trigger,
		formState: {dirtyFields, isDirty},
		reset,
		setError
	} = methods;
	const handleReset = useCallback(() => reset(defaultValues), [reset, defaultValues]);
	const errorMessages = createErrorMessages();

	const handleSubmit = async () => {
		const isMainInfoValid = await trigger();
		if (!isMainInfoValid) {
			return;
		}
		const values = getValues();
		try {
			const preparedData = getPreparedMainInfoFormData(scheduleId, values, dirtyFields);
			if (scheduleId) {
				await editSchedule({scheduleId, fields: preparedData});
				if (isEditingSuccess) {
					handleReset();
				}
			} else {
				const result = await addSchedule(preparedData);
				pushPath(`/manage/schedules/${result.id}`);
				if (isAddingSuccess) {
					handleReset();
				}
			}
		} catch (error) {
			if (error?.message === ScheduleErrorMessage.OVERLAP_SCHEDULES_BY_DATE) {
				setError(editableScheduleFields.PARTICIPANT_IDS, {
					type: ParticipantsErrorTypes.OVERLAP,
					message: errorMessages.overlapParticipants
				});
			}
		}
	};

	const changeHandler = useCallback(
		(name: keyof IScheduleForm, value?: ScheduleFieldValueType, options?: SetValueOptions) => {
			const defaultOptions = {shouldDirty: true, shouldValidate: true};
			setValue(name, value, options || defaultOptions);
		},
		[setValue]
	);
	return (
		<Plate className={{root: styles.plate, content: styles.plateContent}}>
			<Scrollbar
				className={styles.scrollBar}
				simpleBarProps={simpleBarProps}
			>
				<div className={styles.container}>
					<FormProvider {...methods}>
						<div>
							<p className={styles.title}>Общие параметры</p>
							<div className={styles.form}>
								<Calendar
									onSetValue={changeHandler}
									field={editableScheduleFields.CALENDAR_ID}
									className={styles.formItem}
								/>
								<StageSelect
									onSetValue={changeHandler}
									field={editableScheduleFields.STAGE_ID}
									required
									className={styles.formItem}
									errorMessage={errors.stageId}
								/>
								<ProcessSelect
									field={editableScheduleFields.PROCESS_ID}
									onSetValue={changeHandler}
									required
									className={styles.formItem}
									errorMessage={errors.processId}
								/>
								<CompanySelect
									onSetValue={changeHandler}
									field={editableScheduleFields.COMPANY_ID}
									required
									className={styles.formItem}
									errorMessage={errors.companyId}
								/>
								<ObjectsSelect
									onSetValue={changeHandler}
									field={editableScheduleFields.OBJECT_IDS}
									className={styles.formItem}
								/>
								<ParticipantsSelect
									onSetValue={changeHandler}
									field={editableScheduleFields.PARTICIPANT_IDS}
									required
									className={styles.formItem}
									errorMessage={errors.participantIds}
								/>
								<DateRange
									onSetValue={changeHandler}
									fieldDateFrom={editableScheduleFields.DATE_RANGE_FROM}
									fieldDateTo={editableScheduleFields.DATE_RANGE_TO}
									className={styles.formItem}
								/>
								<SimultaneousSlots
									onSetValue={changeHandler}
									field={editableScheduleFields.SIMULTANEOUS_SLOTS_COUNT}
									required
									className={styles.formItem}
									errorMessage={errors.simultaneousSlotsCount}
								/>
								<SlotDurationSelect
									onSetValue={changeHandler}
									field={editableScheduleFields.SLOT_DURATION}
									required
									className={styles.formItem}
								/>
								<SlotsIntervalSelect
									onSetValue={changeHandler}
									field={editableScheduleFields.INTERVAL_BETWEEN_SLOTS}
									required
									className={styles.formItem}
								/>
								<MaxOccupiedSlots
									onSetValue={changeHandler}
									field={editableScheduleFields.MAXIMUM_OCCUPIED_SLOTS_NUMBER}
									className={styles.formItem}
									errorMessage={errors.maximumOccupiedSlotsNumber}
								/>
								<MaxPreregistrationPeriodSelect
									onSetValue={changeHandler}
									field={editableScheduleFields.MAXIMUM_PREREGISTRATION_PERIOD}
									className={styles.formItem}
								/>
								<MinPreregistrationPeriodSelect
									onSetValue={changeHandler}
									field={editableScheduleFields.MINIMUM_PREREGISTRATION_PERIOD}
									className={styles.formItem}
								/>
							</div>
							<TimeIntervals />
						</div>
					</FormProvider>
				</div>
			</Scrollbar>
			<ActionsButtons
				handleSubmit={handleSubmit}
				handleReset={handleReset}
				disabled={!isDirty}
			/>
		</Plate>
	);
};
