import {dateFormat} from '@tehzor/tools/utils/dateFormats';
import {DatePicker, TextFieldWithForwardedRef} from '@tehzor/ui-components';
import {IDatePickerTriggerProps} from '@tehzor/ui-components/src/components/dates/DatePicker/DatePicker';
import {format} from 'date-fns';
import {useDateFnsLocale} from '@src/core/hooks/translations/useDateFnsLocales';

import {useController, useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {IScheduleFieldProps} from '../types/IScheduleFieldProps';
import {IScheduleForm} from '../types/IScheduleForm';

export const DateRangeFrom = ({onSetValue, field, className}: IScheduleFieldProps) => {
	const {t} = useTranslation();
	const dateFnsLocale = useDateFnsLocale();
	const datesOptions = {locale: dateFnsLocale};
	const {control} = useFormContext<IScheduleForm>();
	const {
		field: {value}
	} = useController({
		name: field,
		control
	});

	const handleChange = (v: Date) => {
		onSetValue(field, v);
	};

	const disabledDate = (date: Date) => {
		const today = new Date();
		today.setHours(0, 0, 0, 0);
		return date < today;
	};

	const getTrigger = (triggerProps: IDatePickerTriggerProps) => (
		<TextFieldWithForwardedRef
			elementType="div"
			className={className}
			value={triggerProps?.value !== undefined ? format(triggerProps.value, dateFormat) : ''}
			onClick={triggerProps?.toggle}
			ref={triggerProps?.ref}
		/>
	);

	return (
		<DatePicker
			value={value as Date}
			datesOptions={datesOptions}
			onChange={handleChange}
			disabledDate={disabledDate}
			trigger={getTrigger}
			selectCancelBtnLabel={t('cancelBtn.label')}
			selectApplyBtnLabel={t('applyBtn.label')}
			useApplyButton
		/>
	);
};
