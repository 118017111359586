import {
	ITreeDataItem,
	SelectSearch,
	TextFieldWithForwardedRef,
	TreeSelect
} from '@tehzor/ui-components';
import {useCallback, useMemo, useState} from 'react';
import {treeFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {useUpdateEffect} from 'react-use';
import {TranslatedSelectPopup} from '@src/components/TranslatedSelectPopup';
import {FieldLabel} from '@/shared/ui/FieldLabel/FieldLabel';
import {getInitialExpanded} from '@/shared/utils/getInitialExpanded';
import {getSelectionTitle} from '@/shared/utils/getSelectionTitle';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';

const icon = <i className="tz-object-24" />;

interface IObjectsSelectProps {
	handleChange: (v: string[]) => void;
	treeData: ITreeDataItem[];
	handleClear: () => void;
	handleCancel: () => void;
	handleApply: () => void;
	handleClearButton: () => void;
	selectedObjects: string[];
	value?: string[];
	objectsMap?: Record<string, IObject>;
	checkStrictly?: boolean;
	disabled?: boolean;
}

export const ObjectsSelect = ({
	handleChange,
	treeData,
	value,
	handleClear,
	handleCancel,
	handleApply,
	handleClearButton,
	selectedObjects,
	objectsMap,
	checkStrictly = false,
	disabled = false
}: IObjectsSelectProps) => {
	const [expanded, setExpanded] = useState<string[] | undefined>(
		getInitialExpanded(treeData, value)
	);
	const [search, setSearch] = useState('');

	const {filteredData, expanded: expandedValue} = useMemo(
		() => treeFilter(treeData, 'content', search),
		[search, treeData]
	);
	useUpdateEffect(() => {
		setExpanded(expandedValue?.map(item => item.id));
	}, [expandedValue]);

	const clearSearch = useCallback(() => setSearch(''), []);

	const onClear = useCallback(() => {
		handleClear();
		clearSearch();
	}, [handleClear, clearSearch]);

	const onCancel = useCallback(() => {
		handleCancel();
		clearSearch();
		setExpanded([]);
	}, [handleCancel, clearSearch]);

	const onApply = () => {
		handleApply();
		clearSearch();
	};

	const onClearClick = () => {
		handleClearButton();
		clearSearch();
	};

	const onChange = (v: string[]) => {
		handleChange(v);
	};

	const handleExpanded = (v: string[]) => {
		setExpanded(v);
	};

	return (
		<>
			<FieldLabel label="Объекты" />
			<TranslatedSelectPopup
				onCancel={onCancel}
				onApply={onApply}
				onClear={onClear}
				clearButton={!!selectedObjects?.length}
				footer
				count={selectedObjects?.length}
				search={
					<SelectSearch
						value={search}
						onChange={setSearch}
						type="popup"
					/>
				}
				trigger={
					<TextFieldWithForwardedRef
						elementType="div"
						value={getSelectionTitle(objectsMap, selectedObjects, 'name')}
						icon={icon}
						onClearClick={onClearClick}
						cleanable={!!selectedObjects.length}
						disabled={disabled}
					/>
				}
			>
				<TreeSelect
					data={filteredData}
					multiple
					value={selectedObjects}
					onChange={onChange}
					expandedValue={expanded}
					onExpand={handleExpanded}
					checkStrictly={checkStrictly}
				/>
			</TranslatedSelectPopup>
		</>
	);
};
