import {ISchedule} from '@tehzor/tools/interfaces/schedules/ISchedule';
import {LimitedItemsList} from '@/shared/ui/LimitedItemsList/LimitedItemsList';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {CellProps} from 'react-table';

interface IParticipantsCellProps {
	props: CellProps<ISchedule>;
	users?: Record<string, IBriefUser>;
}

export const ParticipantsCell = ({props, users}: IParticipantsCellProps) => {
	const {participantIds} = props.row.original;

	if (!participantIds || !users) {
		return null;
	}

	const participantsNames = participantIds.map(id => users[id].displayName);

	return (
		<LimitedItemsList
			items={participantsNames}
			maxVisibleItems={3}
		/>
	);
};
