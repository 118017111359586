import {Column} from 'react-table';
import {IPreparedCheckList} from '../../interfaces/IPreparedCheckList';
import {CheckListSpaceCompanyCell} from './CheckListSpaceCompanyCell';
import {CheckListSpaceTypesCell} from './CheckListSpaceTypesCell';
import {ObjectStageCell} from '@src/pages/manage/CheckListsPage/components/table/ObjectStageCell';
import {CheckListObjectsCell} from '@src/pages/manage/CheckListsPage/components/table/CheckListObjectsCell';
import {DesktopMenuCell} from './DesktopMenuCell';
import {useTranslation} from 'react-i18next';
import {useMemo} from 'react';

export const useDesktopColumns = () => {
	const {t} = useTranslation();

	return useMemo<Array<Column<IPreparedCheckList>>>(
		() => [
			{
				id: 'name',
				accessor: 'name',
				Header: t('checkListsPage.table.columns.name'),
				width: 80,
				minWidth: 80,
				sortDescFirst: true
			},
			{
				id: 'companyId',
				Header: t('checkListsPage.table.columns.company'),
				accessor: 'companyId',
				Cell: CheckListSpaceCompanyCell,
				width: 80,
				minWidth: 80
			},
			{
				id: 'objects',
				Header: t('checkListsPage.table.columns.objects'),
				accessor: 'objects',
				Cell: CheckListObjectsCell,
				width: 80,
				minWidth: 80
			},
			{
				id: 'stage',
				Header: t('checkListsPage.table.columns.stage'),
				Cell: ObjectStageCell,
				width: 80,
				minWidth: 80
			},
			{
				id: 'spaceTypes',
				Header: t('checkListsPage.table.columns.typeUnits'),
				Cell: CheckListSpaceTypesCell,
				width: 80,
				minWidth: 80
			},
			{
				id: 'createdBy',
				Header: t('checkListsPage.table.columns.author'),
				accessor: 'createdBy',
				width: 80,
				minWidth: 80
			},
			{
				id: 'createdAt',
				accessor: 'createdAt',
				Header: t('checkListsPage.table.columns.created'),
				width: 80,
				minWidth: 80
			},
			{
				id: 'modifiedAt',
				accessor: 'modifiedAt',
				Header: t('checkListsPage.table.columns.modified'),
				width: 80,
				minWidth: 80
			},
			{
				id: '_menu',
				Cell: DesktopMenuCell,
				width: 'auto',
				defaultCanSort: false,
				disableResizing: true,
				isNonClickable: false,
				className: 'manage-check-lists-page__d-table-menu'
			}
		],
		[]
	);
};
