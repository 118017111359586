import {IconMenu, MenuItem, ButtonContainer, IconButton} from '@tehzor/ui-components';
import {memo, useMemo} from 'react';
import {useAddingCheckListProblemDialog} from '@src/pages/CheckListPage/hooks/useAddingCheckListProblemDialog';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {transformLocations} from '../../utils/transformLocations';
import {extractUserRoles} from '@src/store/modules/auth/profile/selectors';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import {useAddingCheckListTaskDialog} from '@src/pages/CheckListPage/hooks/useAddingCheckListTaskDialog';
import {useShape} from '@src/core/hooks/queries/plans/hooks';
import {useSpace} from '@src/core/hooks/queries/space';
import {useAddingCheckListInspectionDialog} from '../../hooks/useAddingCheckListInspectionDialog';
import {extractCheckListsPageSettings} from '@src/store/modules/settings/pages/checkLists/selectors';
import {LastEntityAddedFromCheckList} from '@src/interfaces/LastEntityAddedFromCheckList';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useAddingWorkAcceptanceCheckListProblemDialog} from '@src/pages/WorkAcceptancePage/hooks/useAddingWorkAcceptanceCheckListProblemDialog';
import {useAddingWorkAcceptanceCheckListInspectionDialog} from '@src/pages/WorkAcceptancePage/hooks/useAddingWorkAcceptanceCheckListInspectionDialog';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import {useExtractCheckListById} from '@src/core/hooks/queries/checkLists/hooks';
import {getTaskType} from '../../utils/getTaskType';

interface IItemHeaderActionsProps {
	checkItemId: string;
	stage: ObjectStageIds;
	checkListId?: string;
	canAddEntity?: boolean;
	disabled?: boolean;
}

const inspectionAddIcon = <i className="tz-inspection-add-24" />;
const taskAddIcon = <i className="tz-task-add-24" />;
const problemAddIcon = <i className="tz-problem-add-24" />;

const expandIcon = (
	<i className="tz-expand-arrow-heavy check-list-items__item-header-actions-expand-menu-icon" />
);

export const ItemHeaderActions = memo(
	({checkItemId, stage, checkListId, canAddEntity, disabled}: IItemHeaderActionsProps) => {
		const {objectId, spaceId, workAcceptanceId} = useStrictParams<{
			objectId: string;
			spaceId: string;
			workAcceptanceId: string;
		}>();
		const {t} = useTranslation();
		const {lastAddedEntity} = useAppSelector(extractCheckListsPageSettings);
		const {data: space} = useSpace(spaceId, objectId);
		const roles = useAppSelector(extractUserRoles);
		const [planId, sectorId] = transformLocations(space?.locations);
		const [spaceProblemDialog, handleOpenAddSpaceProblemDialog] =
			useAddingCheckListProblemDialog(checkItemId);
		const [workAcceptanceProblemDialog, handleOpenAddWorkAcceptanceProblemDialog] =
			useAddingWorkAcceptanceCheckListProblemDialog(checkListId, checkItemId);
		const hideInspectionAdding =
			stage === ObjectStageIds.WARRANTY || stage === ObjectStageIds.TRANSFER;
		const [spaceInspectionDialog, handleOpenAddSpaceInspectionDialog] =
			useAddingCheckListInspectionDialog(checkItemId);
		const [workAcceptanceInspectionDialog, handleOpenAddWorkAcceptanceInspectionDialog] =
			useAddingWorkAcceptanceCheckListInspectionDialog(checkListId, checkItemId);

		const permissions = {
			canAddTask: canAddEntity && hasPermission(roles, 'tasks-add'),
			canAddProblem: canAddEntity && hasPermission(roles, 'problems-add'),
			canAddInspection: canAddEntity && hasPermission(roles, 'inspections-add'),
			canAddEntity
		};

		const {data: sector} = useShape(objectId, planId, sectorId);

		const location = useMemo(
			() =>
				sector
					? {
							sectors: [
								{
									sector: sector.id,
									name: sector.name
								}
							]
					  }
					: undefined,
			[sector]
		);
		const {data: checkList} = useExtractCheckListById(checkListId);
		const taskType = getTaskType(checkList?.processId);
		const [taskDialog, handleOpenAddTaskDialog] = useAddingCheckListTaskDialog({
			objectId,
			taskType,
			floor: space?.floor,
			planId,
			location,
			spaceId,
			checkItemId,
			checkListId
		});

		const handleOpenAddProblemDialog = spaceId
			? handleOpenAddSpaceProblemDialog
			: workAcceptanceId
			? handleOpenAddWorkAcceptanceProblemDialog
			: undefined;
		const handleOpenAddInspectionDialog = spaceId
			? handleOpenAddSpaceInspectionDialog
			: workAcceptanceId
			? handleOpenAddWorkAcceptanceInspectionDialog
			: undefined;
		const problemDialog = spaceId
			? spaceProblemDialog
			: workAcceptanceId
			? workAcceptanceProblemDialog
			: null;
		const inspectionDialog = spaceId
			? spaceInspectionDialog
			: workAcceptanceId
			? workAcceptanceInspectionDialog
			: null;

		const variableIcon = hideInspectionAdding ? problemAddIcon : inspectionAddIcon;
		const variableHandler = hideInspectionAdding
			? handleOpenAddProblemDialog
			: handleOpenAddInspectionDialog;

		const icons = {
			[LastEntityAddedFromCheckList.PROBLEM]: {
				icon: problemAddIcon,
				handler: handleOpenAddProblemDialog
			},
			[LastEntityAddedFromCheckList.INSPECTION]: {
				icon: variableIcon,
				handler: variableHandler
			},
			[LastEntityAddedFromCheckList.TASK]: {
				icon: taskAddIcon,
				handler: handleOpenAddTaskDialog
			}
		};

		const menuItems = [];

		if (permissions.canAddTask) {
			menuItems.push(
				<MenuItem
					icon={taskAddIcon}
					onClick={handleOpenAddTaskDialog}
					key={`task-${checkItemId}`}
				>
					{t('checkListPage.actions.menuItem.addTask')}
				</MenuItem>
			);
		}
		if (permissions.canAddInspection && !hideInspectionAdding) {
			menuItems.unshift(
				<MenuItem
					icon={inspectionAddIcon}
					onClick={handleOpenAddInspectionDialog}
					key={`inspection-${checkItemId}`}
				>
					{t('checkListPage.actions.menuItem.addInspection')}
				</MenuItem>
			);
		}
		if (permissions.canAddProblem) {
			menuItems.unshift(
				<MenuItem
					icon={problemAddIcon}
					onClick={handleOpenAddProblemDialog}
					key={`problem-${checkItemId}`}
				>
					{t('checkListPage.actions.menuItem.problem')}
				</MenuItem>
			);
		}

		return menuItems.length ? (
			<div>
				<ButtonContainer className="check-list-items__item-header-actions-container">
					<IconButton
						className={classNames(
							'check-list-items__item-header-actions-menu-button',
							disabled && 'check-list-items__item-header-actions-menu-button_disabled'
						)}
						onClick={icons[lastAddedEntity].handler}
						disabled={disabled}
					>
						{icons[lastAddedEntity].icon}
					</IconButton>
					<IconMenu
						className={classNames(
							'check-list-items__item-header-actions-menu-button',
							'check-list-items__item-header-actions-menu-button_small',
							disabled && 'check-list-items__item-header-actions-menu-button_disabled'
						)}
						icon={expandIcon}
						disabled={disabled}
					>
						{menuItems}
					</IconMenu>
				</ButtonContainer>

				{permissions.canAddProblem && problemDialog}
				{permissions.canAddTask && taskDialog}
				{permissions.canAddInspection && !hideInspectionAdding && inspectionDialog}
			</div>
		) : null;
	}
);
