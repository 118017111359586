export const getSelectionTitle = <
	T extends Record<string, unknown>,
	K extends keyof T[string] & string
>(
	entityMap: T | undefined,
	selectedEntities: string[],
	nameField: K
): string | undefined => {
	if (!entityMap || selectedEntities.length === 0) return undefined;

	const firstEntity = entityMap[selectedEntities[0]];
	if (!firstEntity) return undefined;

	const firstName = (firstEntity as Record<string, unknown>)[nameField];
	return typeof firstName === 'string'
		? selectedEntities.length > 1
			? `${firstName} + ${selectedEntities.length - 1}`
			: firstName
		: undefined;
};
