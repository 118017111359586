import {QueryFunctionContext, useQueryClient} from '@tanstack/react-query';
import {IScheduledPlansPaginateQueryKey, scheduledPlansQueryKeys} from '../keys';
import {getScheduledPlansPaginateRequest} from '@/shared/api/scheduledPlans/getScheduledPlansPaginate';
import {useScheduledPlansPermissions} from '@/shared/hooks/permissions/useScheduledPlansPermissions';

export const useScheduledPlansQueryDefaults = () => {
	const queryClient = useQueryClient();
	const {canViewScheduledPlans} = useScheduledPlansPermissions();
	queryClient.setQueryDefaults(scheduledPlansQueryKeys.paginate(), {
		queryFn: ({queryKey}: QueryFunctionContext<IScheduledPlansPaginateQueryKey>) => {
			const [, , , filters, sort, offset, limit] = queryKey;
			return getScheduledPlansPaginateRequest(filters, sort, offset, limit);
		},
		enabled: canViewScheduledPlans,
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 60 * 24 // 1 День
	});
};
