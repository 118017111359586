import {IHistoryData} from '@tehzor/tools/interfaces/history/IHistoryData';
import {HistoryTypeId} from '@tehzor/tools/interfaces/history/IHistoryType';
import {longDateTimeFormat} from '@tehzor/tools/utils/dateFormats';
import {format, Locale} from 'date-fns';
import {ru} from 'date-fns/locale';
import {TFunction} from 'i18next';

const formatDate = (date?: number, locale: Locale = ru) =>
	date ? format(date, longDateTimeFormat, {locale}) : '';

interface IDifferenceArgs {
	t: TFunction<'translation', undefined>;
	locale?: Locale;
	prevFrom?: number;
	nextFrom?: number;
	prevTo?: number;
	nextTo?: number;
	label?: string;
}
export const createDateIntervalDifference = ({
	prevFrom,
	nextFrom,
	prevTo,
	nextTo,
	label,
	t,
	locale
}: IDifferenceArgs): IHistoryData => ({
	name: label || t('createDifference.createDateDifference'),
	type: HistoryTypeId.DATE_INTERVAL,
	prev: {
		from: formatDate(prevFrom, locale),
		to: formatDate(prevTo, locale)
	},
	next: {
		from: formatDate(nextFrom, locale),
		to: formatDate(nextTo, locale)
	}
});
