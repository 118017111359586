import {addScheduleRequest} from '@/shared/api/schedule/addSchedule';
import {useMutation} from '@tanstack/react-query';
import {schedulesQueryKeys} from '../cache/keys';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {queryClient} from '@src/api/QueryClient';
import {ISavingSchedule} from '@tehzor/tools/interfaces/schedules';

export const useAddSchedule = () =>
	useMutation({
		mutationFn: (fields: ISavingSchedule) => addScheduleRequest(fields),
		mutationKey: schedulesQueryKeys.add(),
		onSuccess: () => {
			addSuccessToast({title: 'Расписание успешно создано'});
			void queryClient.invalidateQueries({queryKey: schedulesQueryKeys.list()});
		},
		onError: () => addErrorToast({title: 'Ошибка при создании расписания'})
	});
