import {format} from 'date-fns';
import {canSelectTime} from './canSelectTime';
import {isTimeValueAfterFrom} from './isTimeValueAfterFrom';

export const getTimeData = (
	el: number,
	value: Date,
	hidePastTimes?: boolean,
	optionalValue?: Date
) => {
	const hours = Math.floor(el / 3600000);
	const minutes = Math.floor((el - hours * 3600000) / 60000);
	const timeValue = new Date(0, 0, 0, hours, minutes);
	const formattedTimeValue = format(timeValue, 'HH:mm');
	const isValueAfterToday = (value.getDay() - new Date().getDay() + 7) % 7 > 0;
	const active = value.getHours() === hours && value.getMinutes() === minutes;

	const isTimeSelectable = optionalValue
		? isTimeValueAfterFrom(timeValue, optionalValue)
		: canSelectTime(timeValue, hidePastTimes) || isValueAfterToday;

	return {hours, minutes, formattedTimeValue, isTimeSelectable, active};
};
