export type IIntervalsPaginateQueryKey = [] & {
	0: string; // intervals
	1: string; // list
	2: string; // paginate
	3: string; // scheduledId
};

export const intervalsQueryKeys = {
	all: () => ['intervals'],
	one: () => ['interval'],
	list: () => [...intervalsQueryKeys.all(), 'list'],
	paginate: () => [...intervalsQueryKeys.list(), 'paginate'],
	byScheduleId: (scheduleId?: string) => [...intervalsQueryKeys.paginate(), scheduleId]
};
