import {DeleteSelectedScheduledPlans} from '@/features/ScheduledPlans';
import {IPreparedScheduledPlan} from '@tehzor/tools/interfaces/scheduledPlans';
import {Button, ISelectionRowProps} from '@tehzor/ui-components';
import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import styles from './SelectionRow.module.less';

type ICustomSelectionRowProps = ISelectionRowProps<IPreparedScheduledPlan>;

export const SelectionRow = (props: ICustomSelectionRowProps) => {
	const {toggleAllRowsSelected, selectedFlatRows} = props;
	const {t} = useTranslation();

	const clearSelection = useCallback(() => {
		toggleAllRowsSelected(false);
	}, [toggleAllRowsSelected]);

	return (
		<div className={styles.selectionRowWrapper}>
			<div className={styles.selectedEntityCounter}> {selectedFlatRows.length}</div>
			<DeleteSelectedScheduledPlans
				selectedEntities={selectedFlatRows.map(item => item.original)}
				clearSelection={clearSelection}
			/>
			<Button
				label={t('selectionRow.cancelButton.label')}
				type="cancel"
				onClick={clearSelection}
			/>
		</div>
	);
};
