import {FrequencyIds, ITimeInterval} from '@tehzor/tools/interfaces/schedules';
import {IIntervalFieldData} from '../../types/IIntervalFieldData';
import {msToTimeStr} from './msToTimeStr';

const RepeatTypeValues = {
	[FrequencyIds.EVERY_WEEK]: 'Каждую неделю',
	[FrequencyIds.EVERY_MONTH]: 'Каждый месяц'
};

export const convertIntervalsApiData = (data: ITimeInterval[]): IIntervalFieldData[] =>
	data.map(item => ({
		id: item.id,
		timeRangeFrom: msToTimeStr(item.timeRangeFrom),
		timeRangeTo: msToTimeStr(item.timeRangeTo),
		dateRangeFrom: item.dateRangeFrom ? new Date(item.dateRangeFrom) : undefined,
		dateRangeTo: item.dateRangeTo ? new Date(item.dateRangeTo) : undefined,
		repeatType: {id: item.frequency.id, name: RepeatTypeValues[item.frequency.id]},
		selectedDays: item.frequency.values.map(day => String(day)),
		simultaneousSlots: item.simultaneousSlotsCount
	}));
