import {FieldLabel} from '@/shared/ui/FieldLabel/FieldLabel';
import {TextField} from '@tehzor/ui-components';
import {IScheduleFieldProps} from '../../types/IScheduleFieldProps';

const arrowIcon = <i className="tz-simple-arrow-20" />;

// TODO Доработать компонент после того, как появится Справочник типов

export const Calendar = ({className}: IScheduleFieldProps) => (
	<div className={className}>
		<FieldLabel label="Календарь" />
		<TextField
			value=""
			icon={arrowIcon}
			disabled
		/>
	</div>
);
