import {httpRequests} from '@tehzor/tools/api/httpRequests';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {ISchedule} from '@tehzor/tools/interfaces/schedules/ISchedule';
import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';

export interface IGetSchedulesResponse extends INormalizedData<ISchedule> {
	offset?: number;
	limit?: number;
	total?: number;
}

/**
 * Возвращает список расписаний
 */
export const getSchedulesPaginateRequest = async (
	filters?: Record<string, unknown>,
	sort?: Record<string, boolean>,
	offset?: number,
	limit?: number
) => {
	const response = await httpRequests.withToken.post<IGetSchedulesResponse>(
		'schedules/get-paginate',

		{
			sort: convertSortParam(sort),
			filters,
			offset,
			limit
		}
	);
	return response.data;
};
