import {ITreeDataItem} from '@tehzor/ui-components/src/components/Tree';
import {useTranslatedDictionaryArray} from '@src/core/hooks/translations/useTranslatedDictionaryArray';
import {IWorkAcceptanceFrontType} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceFrontType';
import {ISpaceType} from '@tehzor/tools/interfaces/spaces/ISpaceType';
import {IStructureType} from '@tehzor/tools/interfaces/structures/IStructureType';

/**
 * Формирует дерево процессов из переведенных стадий
 *
 * @param translatedStages - переведенные стадии
 */
export function makeSpacesAndStructuresTreeData(
	types:
		| ReturnType<typeof useTranslatedDictionaryArray<ISpaceType>>
		| ReturnType<typeof useTranslatedDictionaryArray<IStructureType>>,
	frontType?: IWorkAcceptanceFrontType | undefined
): ITreeDataItem[] {
	const result: ITreeDataItem[] = [];

	if (!types || !frontType) return result;

	for (const type of types) {
		result.push({
			id: `${frontType?.id}:${type.id}`,
			content: type.name,
			parentId: frontType?.id
		});
	}

	result.push({
		id: frontType?.id,
		content: frontType?.name
	});

	return result;
}
