import {LoginPage} from '@src/pages/LoginPage';
import {LogoutPage} from '@src/pages/LogoutPage';
import {objectsRoutes} from './objectsRoutes';
import {PrivateRoute} from '@src/containers/PrivateRoute';
import NotFoundPage from '@src/pages/NotFoundPage';
import {Navigate, RouteObject} from 'react-router-dom';
import StatisticsPage from '@src/pages/StatisticsPage';
import {profileRoutes} from './profileRoutes';
import {checksRoutes} from './checksRoutes';
import {internalAcceptancesRoutes} from './internalAcceptancesRoutes';
import {workAcceptancesRoutes} from './workAcceptancesRoutes';
import {problemsRoutes} from './problemsRoutes';
import {inspectionsRoutes} from './inspectionsRoutes';
import {warrantyClaimsRoutes} from './warrantyClaimsRoutes';
import {ownerAcceptancesRoutes} from './ownerAcceptancesRoutes';
import {tasksRoutes} from './tasksRoutes';
import {manageRoutes} from './manageRoutes';
import {statisticsRoutes} from './statisticsRoutes';
import {contractsRoutes} from './contractsRoutes';
import {documentsRoutes} from '@src/core/routes/documentsRoutes';
import {ObjectsPageRouteWrapper} from '@src/pages/ObjectsPage/ObjectsPageRouteWrapper';
import {legalsRoutes} from './legalsRoutes';
import {scheduledPlansRoutes} from './scheduledPlansRoutes';

export const rootRoutes: RouteObject[] = [
	{
		path: '/',
		element: <PrivateRoute element={<Navigate to="/objects" />} />
	},
	{
		path: 'profile',
		children: profileRoutes
	},
	{
		path: 'objects',
		element: <PrivateRoute element={<ObjectsPageRouteWrapper />} />,
		children: objectsRoutes
	},
	{
		path: 'problems',
		children: problemsRoutes
	},
	{
		path: 'inspections',
		children: inspectionsRoutes
	},
	{
		path: 'checks',
		children: checksRoutes
	},
	{
		path: 'internal-acceptances',
		children: internalAcceptancesRoutes
	},
	{
		path: 'owner-acceptances',
		children: ownerAcceptancesRoutes
	},
	{
		path: 'warranty-claims',
		children: warrantyClaimsRoutes
	},
	{
		path: 'work-acceptances',
		children: workAcceptancesRoutes
	},
	{
		path: 'contracts',
		children: contractsRoutes
	},
	{
		path: 'tasks',
		children: tasksRoutes
	},
	{
		path: 'scheduled-plans',
		children: scheduledPlansRoutes
	},
	{
		path: 'documents',
		children: documentsRoutes
	},
	{
		path: 'legals',
		children: legalsRoutes
	},
	{
		path: 'manage',
		children: manageRoutes
	},
	{
		path: 'statistics',
		element: <PrivateRoute element={<StatisticsPage />} />,
		children: statisticsRoutes
	},
	{
		path: 'login',
		element: <LoginPage />
	},
	{
		path: 'logout',
		element: <LogoutPage />
	},
	{
		path: '*',
		element: <PrivateRoute element={<NotFoundPage />} />
	}
];
