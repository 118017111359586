import {useMemo} from 'react';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useAsync} from 'react-use';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {Desktop} from './components/Desktop';
import {Mobile} from './components/Mobile';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import {useManyWorkAcceptancesAsArray} from '@src/core/hooks/queries/workAcceptances';
import './SpacePage.less';
import {useSpaceStage} from '@src/core/hooks/spaces/useSpaceStage';
import {getCheckRecords} from '@src/store/modules/pages/checkLists/actions';
import {useHasUnfinished} from './hooks/useHasUnfinished';
import {useSpace} from '@src/core/hooks/queries/space';
import {LoadingPanel} from '@tehzor/ui-components';
import {useSpacePageIsLoading} from './hooks/useSpaceIsLoading';
import {SpacesPageVariants} from '@src/interfaces/SpacesPageVariants';
import {useNotCheckedCheckLists} from './hooks/useNotCheckedCheckLists';
import {useSpaceCheckLists} from '../CheckListPage/hooks/useSpaceCheckLists';
import {useSpaceSettings} from '@src/core/hooks/settings/useSpaceSettings';
import {useObjectSettings} from '@src/core/hooks/settings/useObjectSettings';

export const SpacePage = ({spacesPageVariants}: {spacesPageVariants: SpacesPageVariants}) => {
	useScrollRestoration();

	const {objectId, spaceId} = useStrictParams<{
		objectId: string;
		spaceId: string;
	}>();
	const dispatch = useAppDispatch();
	const isDesktop = useIsDesktop();
	const {data: space} = useSpace(spaceId, objectId);
	const settings = useSpaceSettings(objectId);
	const {data: workAcceptances} = useManyWorkAcceptancesAsArray(objectId);
	const stage = useSpaceStage(objectId, space);

	const checkLists = useSpaceCheckLists(objectId, spaceId, stage, 'all');
	const checkListsIds = useMemo(() => checkLists?.map(cl => cl.id), [checkLists]);
	const [hasUnfixedProblems, hasHandlingOrWaitingProblems, hasUnacceptedCheckItem] =
		useHasUnfinished(checkListsIds, objectId, spaceId);
	const hasNotCheckedCheckLists = useNotCheckedCheckLists(objectId, spaceId);

	useAsync(async () => {
		await dispatch(
			getCheckRecords(undefined, [objectId], spaceId, workAcceptances?.map(({id}) => id))
		);
	}, [objectId, spaceId, workAcceptances, dispatch]);

	const loading = useSpacePageIsLoading();

	const {restrictedAddProblemsAfterWarrantyExpiration} = useObjectSettings(objectId);

	if (!space) {
		return (
			<LoadingPanel
				className="space-page__loading-panel"
				active={!space}
			/>
		);
	}

	return (
		<LoadingPanel
			className="space-page__loading-panel"
			active={loading}
		>
			{isDesktop ? (
				<Desktop
					objectId={objectId}
					spaceId={spaceId}
					space={space}
					stage={stage}
					hasUnfixedProblems={hasUnfixedProblems}
					hasNotCheckedCheckLists={hasNotCheckedCheckLists}
					hasHandlingOrWaitingProblems={hasHandlingOrWaitingProblems}
					hasUnacceptedCheckItem={hasUnacceptedCheckItem}
					spacesPageVariants={spacesPageVariants}
					settings={settings}
				/>
			) : (
				<Mobile
					objectId={objectId}
					spaceId={spaceId}
					space={space}
					hasUnfixedProblems={hasUnfixedProblems}
					hasNotCheckedCheckLists={hasNotCheckedCheckLists}
					hasHandlingOrWaitingProblems={hasHandlingOrWaitingProblems}
					hasUnacceptedCheckItem={hasUnacceptedCheckItem}
					spacesPageVariants={spacesPageVariants}
					settings={settings}
					restrictedAddProblemsAfterWarrantyExpiration={
						restrictedAddProblemsAfterWarrantyExpiration
					}
				/>
			)}
		</LoadingPanel>
	);
};
