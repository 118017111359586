import styles from './TimeRange.module.less';
import {useFormContext, useWatch} from 'react-hook-form';
import {useCallback, useState} from 'react';
import {TimeInput} from '../../ui/TimeInput/TimeInput';
import {ErrorMessages} from '../../constants/ErrorMessages';
import {IScheduleForm} from '../../types/IScheduleForm';
import {getIntervalField} from '../../utils/intervals/getIntervalField';
import {IntervalFields} from '../../types/IntervalFields';
import {getTimeRangeValidationState} from '../../utils/intervals/getTimeValidationState';
import {editableScheduleFields} from '../../constants/editableScheduleFields';
import {FieldLabel} from '@/shared/ui/FieldLabel/FieldLabel';

interface ITimeRangeProps {
	index: number;
}

export const TimeRange = ({index}: ITimeRangeProps) => {
	const {control, formState} = useFormContext<IScheduleForm>();
	const {timeRangeFrom, timeRangeTo} = useWatch({control, name: `intervals.${index}`});
	const slotDuration = useWatch({
		control,
		name: editableScheduleFields.SLOT_DURATION
	});

	const [focusStatus, setFocusStatus] = useState({
		timeRangeFrom: false,
		timeRangeTo: false
	});

	const handleFocusTimeRangeFrom = useCallback((isFocused: boolean) => {
		setFocusStatus(prev => ({...prev, timeRangefrom: isFocused}));
	}, []);

	const handleFocusTimeRangeTo = useCallback((isFocused: boolean) => {
		setFocusStatus(prev => ({...prev, timeRangeTo: isFocused}));
	}, []);

	// TODO Возвращать одну ошибку вместо isStartBeforeEnd и isTimeRangeLessThanSlot
	const {
		errorRangeFrom,
		errorRangeTo,
		canShowError,
		isStartBeforeEnd,
		isTimeRangeLessThanSlot,
		errorSlotsDurationFrom,
		errorSlotsDurationTo
	} = getTimeRangeValidationState({
		timeRangeFrom,
		timeRangeTo,
		slotDuration,
		formState,
		index,
		focusStatus
	});

	return (
		<div>
			<FieldLabel
				className={styles.inputsHeader}
				label="Диапазон времени"
				required
			/>
			<div className={styles.inputWrapper}>
				<TimeInput
					field={getIntervalField(index, IntervalFields.TIME_RANGE_FROM)}
					onFocusBlur={handleFocusTimeRangeFrom}
					errorWithoutMessage={errorRangeFrom || errorSlotsDurationFrom}
					required
				/>
				<span className={styles.rangeDash} />
				<TimeInput
					field={getIntervalField(index, IntervalFields.TIME_RANGE_TO)}
					onFocusBlur={handleFocusTimeRangeTo}
					errorWithoutMessage={errorRangeTo || errorSlotsDurationTo}
					required
				/>
			</div>
			{canShowError && (
				<>
					{isStartBeforeEnd && (
						<span className={styles.intervalErrorMessage}>
							{ErrorMessages.startTimeBeforeEnd}
						</span>
					)}
					{isTimeRangeLessThanSlot && !isStartBeforeEnd && (
						<span className={styles.intervalErrorMessage}>
							{ErrorMessages.rangeTooSmall}
						</span>
					)}
				</>
			)}
		</div>
	);
};
