import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractUserRoles} from '@src/store/modules/auth/profile/selectors';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import {useMemo} from 'react';

interface IScheduledPlansPermissions {
	canViewScheduledPlans?: boolean;
	canDeleteScheduledPlans?: boolean;
	canEditScheduledPlans?: boolean;
	canAddScheduledPlans?: boolean;
}
export const useScheduledPlansPermissions = (): IScheduledPlansPermissions => {
	const roles = useAppSelector(extractUserRoles);

	return useMemo(() => {
		if (!roles.length) {
			return {};
		}

		return {
			canViewScheduledPlans: hasPermission(roles, 'scheduled-plans-view'),
			canDeleteScheduledPlans: hasPermission(roles, 'scheduled-plans-delete'),
			canEditScheduledPlans: hasPermission(roles, 'scheduled-plans-edit'),
			canAddScheduledPlans: hasPermission(roles, 'scheduled-plans-add')
		};
	}, [roles]);
};
