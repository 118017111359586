import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';
import {useCallback} from 'react';
import {IconButton} from '@tehzor/ui-components';
import {IPreparedScheduledPlan} from '@tehzor/tools/interfaces/scheduledPlans';
import styles from './DeleteSelectedScheduledPlans.module.less';
import {useScheduledPlansPermissions} from '@/shared/hooks/permissions/useScheduledPlansPermissions';

interface ISelectedEntitiesDeletionProps {
	clearSelection?: () => void;
	selectedEntities: IPreparedScheduledPlan[];
}

const deleteIcon = <i className="tz-delete" />;

export const DeleteSelectedScheduledPlans = ({
	clearSelection,
	selectedEntities
}: ISelectedEntitiesDeletionProps) => {
	const {canDeleteScheduledPlans} = useScheduledPlansPermissions();
	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: 'Удалить план-графики',
		message: 'Вы действительно хотите удалить выбранные план-графики?',
		props: {
			className: {root: styles.dialogRoot}
		},
		description: 'Все плановые работы этих план-графиков будут также удалены'
	});
	const handleClick = useCallback(async () => {
		if (await getDeleteConfirmation()) {
			selectedEntities.map(entity => console.log('deleted ', entity.name));
			if (clearSelection) {
				clearSelection();
			}
		}
	}, [clearSelection, selectedEntities, getDeleteConfirmation]);

	if (!canDeleteScheduledPlans) return null;
	return (
		<>
			<IconButton onClick={handleClick}>{deleteIcon}</IconButton>

			{deleteDialog}
		</>
	);
};
