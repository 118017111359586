import {useQueryClient} from '@tanstack/react-query';
import {restDictionariesQueryKeys} from '../keys';
import {requestPaginateEmployees} from '@src/api/backend/users/getPaginateEmployees';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';

export type IUseUsersResult = INormalizedData<IBriefUser>;

export const useUsersQueryDefaults = () => {
	const queryClient = useQueryClient();
	queryClient.setQueryDefaults(restDictionariesQueryKeys.users(), {
		networkMode: 'offlineFirst',
		queryFn: async () => {
			let offset = 0;
			let isLastPage = false;
			const limit = 1000;
			const result: IUseUsersResult = {
				byId: {},
				allIds: []
			};
			while (!isLastPage) {
				const response = await requestPaginateEmployees(offset, limit);
				if (!response) {
					break;
				}
				result.byId = {...result.byId, ...response.byId};
				result.allIds = [...result.allIds, ...response.allIds];
				offset += limit;
				if (result.allIds.length >= response.total) {
					isLastPage = true;
				}
			}
			return result;
		},
		staleTime: 1000 * 60 * 60 * 24, // 1 день
		gcTime: 1000 * 60 * 60 * 24 * 7 // 1 неделя
	});
};
