import {getDesktopColumns} from '../utils/getDesktopColumns';
import {useMemo} from 'react';
import {useUsersAsMap} from '@src/core/hooks/queries/users/hooks';

export const useTableColumns = (deleteCellClassName?: string) => {
	const {data: allUsers} = useUsersAsMap();
	return useMemo(
		() => getDesktopColumns(deleteCellClassName, allUsers),
		[allUsers, deleteCellClassName]
	);
};
