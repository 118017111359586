import {QueryFunctionContext, useQueryClient} from '@tanstack/react-query';
import {IIntervalsPaginateQueryKey, intervalsQueryKeys} from '../keys/index';
import {getPaginateTimeIntervalsRequest} from '@/shared/api/timeIntervals/getTimeIntervals';

export const useIntervalsQueryDefaults = () => {
	const queryClient = useQueryClient();

	queryClient.setQueryDefaults(intervalsQueryKeys.paginate(), {
		queryFn: ({queryKey}: QueryFunctionContext<IIntervalsPaginateQueryKey>) => {
			const [, , , scheduleId] = queryKey;
			return getPaginateTimeIntervalsRequest(scheduleId);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 60 * 24 // 1 День
	});
};
