import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {FixDateTag} from '@src/components/FixDateTag';
import {format} from 'date-fns';
import {useTranslation} from 'react-i18next';
import {DeadlineIntervalMobile} from '@src/components/DeadlineIntervalMobile';
import {compareFixDate} from '@tehzor/tools/utils/compareFixDate';

interface IWorkAcceptanceDeadlineProps {
	workAcceptance: IWorkAcceptance;
}

export const WorkAcceptanceDeadlineMobile = ({workAcceptance}: IWorkAcceptanceDeadlineProps) => {
	const {acceptanceIntervalStart, acceptanceIntervalEnd, actualAcceptanceDate} = workAcceptance;
	const {t} = useTranslation();
	if (!acceptanceIntervalStart || !acceptanceIntervalEnd) {
		return null;
	}
	const formattedDateFrom = format(acceptanceIntervalStart, dateTimeCommaSeparatedFormat);
	const formattedDateTo = format(acceptanceIntervalEnd, dateTimeCommaSeparatedFormat);
	return (
		<div className="structure-page__m-table-plannedFixDate">
			<p className="structure-page__m-table-plannedFixDate-title">
				{t('structurePage.workAcceptanceTableColumns.date.header')}
			</p>

			<DeadlineIntervalMobile
				dateStart={formattedDateFrom}
				dateEnd={formattedDateTo}
			/>
			{compareFixDate(actualAcceptanceDate, acceptanceIntervalEnd) &&
				acceptanceIntervalEnd && (
					<FixDateTag
						className="fix-date-view-field__tag"
						plannedFixDate={acceptanceIntervalEnd}
						actualFixDate={actualAcceptanceDate}
					/>
				)}
		</div>
	);
};
