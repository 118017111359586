import {ObjectStageSelect} from '@/shared/ui/Form/ObjectStageSelect/ObjectStageSelect';
import {
	useObjectStagesAsArray,
	useObjectStagesMap
} from '@src/core/hooks/queries/objectStages/hooks';
import {useController, useFormContext} from 'react-hook-form';
import {IScheduleFieldProps} from '../types/IScheduleFieldProps';
import {IScheduleForm} from '../types/IScheduleForm';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';

export const StageSelect = ({
	onSetValue,
	field,
	required,
	className,
	errorMessage
}: IScheduleFieldProps) => {
	const {data: types} = useObjectStagesAsArray();
	const {data: typesMap} = useObjectStagesMap();
	const {control} = useFormContext<IScheduleForm>();
	const {
		field: {value},
		fieldState: {invalid}
	} = useController({
		name: field,
		control,
		rules: {required}
	});

	const handleChange = (v: string) => onSetValue(field, v);
	return (
		<ObjectStageSelect
			className={className}
			types={types}
			typesMap={typesMap}
			onChange={handleChange}
			value={value as ObjectStageIds}
			required={required}
			hasError={invalid}
			errorMessage={errorMessage}
		/>
	);
};
