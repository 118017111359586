export enum TaskTypeId {
	AUDIT = 'audit',
	CHECK = 'check',
	ACCEPTANCE_CONTROL = 'acceptance-control',
	TASK = 'task',
	CHECKLIST_CALL = 'checklist-call',
	SPACE_CALL = 'space-call',
	WORK_ACCEPTANCE_CALL = 'work-acceptance-call'
}

export interface ITaskType {
	id: TaskTypeId;
	name: string;
	color: string;
}
