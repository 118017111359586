import {useTasksCacheDefaults} from '@src/api/cache/tasks/defaults/useTasksCacheDefaults';
import {useUnitsCacheDefaults} from '@src/api/cache/units/defaults/useUnitsCacheDefaults';
import {useAuthorizedPersonsCacheDefaults} from './authorizedPersons/defaults/useAuthorizedPersonsCacheDefaults';
import {useBankAccountsCacheDefaults} from './bankAccounts/defaults/useBankAccountsCacheDefaults';
import {useCategoriesCacheDefaults} from './categories/defaults/useCategoriesCacheDefaults';
import {useCategoriesSetsCacheDefaults} from './categoriesSets/defaults/useCategoriesSetsCacheDefaults';
import {useCheckItemsCacheDefaults} from './checkItems/defaults/useCheckItemsCacheDefaults';
import {useCheckListsCacheDefaults} from './checkLists/defaults/useCheckListsCacheDefaults';
import {useCheckRecordsCacheDefaults} from './checkRecords/defaults/useCheckRecordsCacheDefaults';
import {useChecksCacheDefaults} from './checks/defaults/useChecksCacheDefaults';
import {useChecksDocumentsStatsCacheDefaults} from './checksDocumentsStats/defaults/useChecksDocumentsStatsCacheDefaults';
import {useCompaniesCacheDefaults} from './companies/defaults/useCompaniesCacheDefaults';
import {useContractsQueryCacheDefaults} from './contracts/defaults/useContractsQueryCacheDefaults';
import {useDocumentsCacheDefaults} from './documents/defaults/useDocumentsCacheDefaults';
import {useFieldSettingsQueryCacheDefaults} from './fieldSettings/defaults/useFieldSettingsQueryCache';
import {useFieldSettingsSetsQueryCacheDefaults} from './fieldSettingsSets/defaults/useFieldSettingsSetsQueryCache';
import {useFieldsQueryCacheDefaults} from '@src/api/cache/fields/defaults/useFieldsQueryCacheDefaults';
import {useInspectionsCacheDefaults} from './inspections/defaults/useInspectionsCacheDefaults';
import {useInternalAcceptancesCacheDefaults} from './internalAcceptances/defaults/useInternalAcceptancesCacheDefaults';
import {useLegalsCacheDefaults} from './legals/defaults/useLegalsCacheDefaults';
import {useObjectsCacheDefaults} from './objects/defaults/useObjectsCacheDefaults';
import {useOwnerAcceptancesCacheDefaults} from './ownerAcceptances/defaults/useOwnerAcceptancesCacheDefaults';
import {usePlansCacheDefaults} from './plans/defaults/usePlansCacheDefaults';
import {useProblemViewsCacheDefaults} from './problemViews/defaults/useProblemViewsCacheDefaults';
import {useProblemsCacheDefaults} from './problems/defaults/useProblemsCacheDefaults';
import {useProblemsDocumentsStatsCacheDefaults} from './problemsDocumentsStats/defaults/useProblemsDocumentsStatsCacheDefaults';
import {useRegulatoryStandardsCacheDefaults} from './regulatoryStandards/defaults/useRegulatoryStandardsCacheDefaults';
import {useResponsibilityRulesCacheDefaults} from './responsibilityRules/defaults/useResponsibilityRulesCacheDefaults';
import {useRestDictionariesCacheDefaults} from './restDictionaries/defaults';
import {useSpaceOwnersCacheDefaults} from './spaceOwners/defaults/useSpaceOwnersCacheDefaults';
import {useSpaceStatusesCacheDefaults} from './spaceStatuses/defaults/useSpaceStatusesCacheDefaults';
import {useSpaceStatusesSetsCacheDefaults} from './spaceStatusesSets/defaults/useSpaceStatusesSetsCacheDefaults';
import {useSpacesCacheDefaults} from './spaces/defaults/useSpacesCacheDefaults';
import {useWarrantyClaimsCacheDefaults} from './warrantyClaims/defaults/useWarrantyClaimsCacheDefaults';
import {useWorkingGroupsCacheDefaults} from './workingGroups/defaults/useWorkingGroupsCacheDefaults';
import {useWorkAcceptancesTasksDataCacheDefaults} from './workAcceptancesTasksData/defaults/useWorkAcceptancesTasksDataCacheDefaults';
import {useWorkAcceptancesCacheDefaults} from './workAcceptances/defaults/useWorkAcceptancesCacheDefaults';
import {useCheckListStoriesCacheDefaults} from './checkListStories/defaults/useCheckListStoriesCacheDefaults';
import {useWorkAcceptanceStoriesCacheDefaults} from './workAcceptanceStories/defaults/useWorkAcceptanceStoriesCacheDefaults';
import {useContractFormsCacheDefaults} from '@src/api/cache/сontractForms/defaults/useСontractFormsCacheDefaults';
import {useMarkersCacheDefaults} from './markers/defaults/useMarkersCacheDefaults';
import {useProblemTagsCacheDefaults} from './problemTags/defaults/useProblemTagsCacheDefaults';
import {useMetersCacheDefaults} from './meters/defaults/useMetersCacheDefaults';
import {useMeterConsumptionsCacheDefaults} from './meterConsumptions/defaults/useMeterConsumptionsCacheDefaults';
import {useExportTemplatesCacheDefaults} from './exportTemplates/defaults/useExportTemplatesCacheDefaults';
import {useProblemTagsSetsQueryCacheDefaults} from '@src/api/cache/problemTagsSets/defaults/useProblemTagsSetsQueryCache';
import {useSpaceTypeDecorationsCacheDefaults} from './spaceTypeDecorations/defaults/useSpaceTypeDecorationsCacheDefaults';
import {useStructuresCacheDefaults} from '@/entities/Structures';
import {useSpaceTypeDecorationsSetsCacheDefaults} from './spaceTypeDecorationsSets/defaults/useSpaceTypeDecorationsSetsCacheDefaults';
import {useDelegationHistoryCacheDefaults} from '@src/api/cache/delegationHistory/defaults/useDelegationHistoryCacheDefaults';
import {usePresaleChecksCacheDefaults} from '@/entities/PresaleCheck';
import {useUnitIndicatorsSetsCacheDefaults} from './unitIndicatorsSets/defaults/useUnitIndicatorsSetsCacheDefaults';
import {useUnitIndicatorsCacheDefaults} from './unitIndicators/defaults/useUnitIndicatorsCacheDefaults';
import {useScheduledPlansCacheDefaults} from './scheduledPlans/useScheduledPlansCacheDefaults';
import {useSchedulesQueryDefaults} from '@/entities/Schedules';
import {useIntervalsQueryDefaults} from '@/entities/TimeIntervals';

export const useCacheDefaults = () => {
	useCompaniesCacheDefaults();
	usePlansCacheDefaults();
	useObjectsCacheDefaults();
	useCategoriesCacheDefaults();
	useCategoriesSetsCacheDefaults();
	useSpaceStatusesSetsCacheDefaults();
	useSpaceStatusesCacheDefaults();
	useCheckListsCacheDefaults();
	useCheckListStoriesCacheDefaults();
	useCheckItemsCacheDefaults();
	useRegulatoryStandardsCacheDefaults();
	useWorkingGroupsCacheDefaults();
	useResponsibilityRulesCacheDefaults();
	useChecksCacheDefaults();
	useProblemsCacheDefaults();
	useTasksCacheDefaults();
	useInspectionsCacheDefaults();
	useDelegationHistoryCacheDefaults();
	useInternalAcceptancesCacheDefaults();
	useOwnerAcceptancesCacheDefaults();
	useWorkAcceptancesCacheDefaults();
	useWarrantyClaimsCacheDefaults();
	useSpacesCacheDefaults();
	useRestDictionariesCacheDefaults();
	useCheckRecordsCacheDefaults();
	useUnitsCacheDefaults();
	useProblemViewsCacheDefaults();
	useSpaceOwnersCacheDefaults();
	useContractsQueryCacheDefaults();
	useLegalsCacheDefaults();
	useBankAccountsCacheDefaults();
	useAuthorizedPersonsCacheDefaults();
	useChecksDocumentsStatsCacheDefaults();
	useProblemsDocumentsStatsCacheDefaults();
	useDocumentsCacheDefaults();
	useFieldSettingsQueryCacheDefaults();
	useFieldSettingsSetsQueryCacheDefaults();
	useProblemTagsSetsQueryCacheDefaults();
	useFieldsQueryCacheDefaults();
	useWorkAcceptancesTasksDataCacheDefaults();
	useWorkAcceptanceStoriesCacheDefaults();
	useContractFormsCacheDefaults();
	useMarkersCacheDefaults();
	useProblemTagsCacheDefaults();
	useMetersCacheDefaults();
	useMeterConsumptionsCacheDefaults();
	useExportTemplatesCacheDefaults();
	useStructuresCacheDefaults();
	useSpaceTypeDecorationsCacheDefaults();
	useSpaceTypeDecorationsSetsCacheDefaults();
	usePresaleChecksCacheDefaults();
	useUnitIndicatorsSetsCacheDefaults();
	useUnitIndicatorsCacheDefaults();
	useScheduledPlansCacheDefaults();
	useSchedulesQueryDefaults();
	useIntervalsQueryDefaults();
};
