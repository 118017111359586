import {cloneElement, CSSProperties, ReactElement, useCallback, useState} from 'react';
import {SelectMenuItemCtx} from './utils/SelectMenuItemCtx';
import {IMenuItemProps} from '../MenuItem';

export interface IMenuProps {
	className?: string;
	style?: CSSProperties;
	value?: string;
	defaultValue?: string;
	children?: ReactElement<IMenuItemProps> | Array<ReactElement<IMenuItemProps>>;

	onChange?: (key: string) => void;
}

const Menu = (props: IMenuProps) => {
	const {className, style, value, defaultValue, children, onChange} = props;
	const [localValue, setLocalValue] = useState<string | undefined>(defaultValue);

	const currentValue = value || localValue;

	const handleSelect = useCallback(
		(key: string) => {
			if (currentValue === key) {
				return;
			}
			if (value === undefined) {
				setLocalValue(key);
			}
			if (onChange) {
				onChange(key);
			}
		},
		[value, currentValue, onChange]
	);

	let items = null;
	if (children) {
		if (Array.isArray(children)) {
			items = children.map((item, index) =>
				cloneElement(item, {
					key: index,
					selectable: true,
					selected:
						item.props.itemKey !== undefined && item.props.itemKey === currentValue
				})
			);
		} else {
			items = cloneElement(children, {
				key: 0,
				selectable: true,
				selected:
					children.props.itemKey !== undefined && children.props.itemKey === currentValue
			});
		}
	}

	return (
		<div className={className} style={style}>
			<SelectMenuItemCtx.Provider value={handleSelect}>{items}</SelectMenuItemCtx.Provider>
		</div>
	);
};

Menu.displayName = 'Menu';

export default Menu;
