import {IPreparedScheduledPlan} from '@tehzor/tools/interfaces/scheduledPlans';
import {CellProps} from 'react-table';
import styles from './ScheduledPlanObjectCell.module.less';

export const ScheduledPlanObjectCell = ({row}: CellProps<IPreparedScheduledPlan>) => {
	const {company, object} = row.original;

	if (!company?.name && !object?.name) {
		return null;
	}

	return (
		<>
			{company?.name && <div className={styles.cellLabel}>{company?.name},</div>}
			{object?.name && <div className={styles.cellLabel}>{object?.name}</div>}
		</>
	);
};
