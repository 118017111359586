import {CSSProperties, Dispatch, useCallback, useMemo} from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {EditableFieldLabel, TextFieldWithForwardedRef} from '@tehzor/ui-components';
import {format} from 'date-fns';
import {useTranslation} from 'react-i18next';
import {TranslatedDateTimeRangePicker} from '@src/components/TranslatedDateTimeRangePicker';

const formatString = 'dd.MM.yyyy HH:mm';

const defaultDisabledDate = (date: Date) => {
	const today = new Date();
	today.setHours(0, 0, 0, 0);
	return date < today;
};

interface IDateTimeSelectProps<S, E> {
	className?: string;
	style?: CSSProperties;
	label?: string;
	valueFrom?: number | null;
	valueTo?: number | null;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasErrorFrom?: boolean;
	hasErrorTo?: boolean;
	fieldFrom?: keyof S;
	fieldTo?: keyof S;
	disabledDate?: (value: Date) => boolean;
	shouldDisableDate?: boolean;
	hidePastTimes?: boolean;
}

export const DateTimeSelect = <S extends Record<string, unknown>, E>({
	className,
	style,
	label,
	valueFrom,
	valueTo,
	editingDispatch,
	required,
	disabled = false,
	hasErrorFrom = false,
	hasErrorTo = false,
	fieldFrom = 'taskIntervalStart',
	fieldTo = 'taskIntervalEnd',
	shouldDisableDate,
	disabledDate,
	hidePastTimes = true
}: IDateTimeSelectProps<S, E>) => {
	const handleDisabledDate = useCallback(
		(date: Date) => {
			if (disabledDate) {
				return disabledDate(date);
			}
			if (shouldDisableDate) {
				return defaultDisabledDate(date);
			}
			return false;
		},
		[shouldDisableDate, disabledDate]
	);
	const {t} = useTranslation();
	const fieldLabel = label || t('components.editableFields.dateTimeSelect.label');
	const handleChange = useCallback(
		(start: Date | null, end: Date | null) => {
			editingDispatch({
				type: 'update',
				field: fieldFrom,
				value: start ? start.getTime() : null
			});
			editingDispatch({
				type: 'update',
				field: fieldTo,
				value: end ? end.getTime() : null
			});

			if (required) {
				editingDispatch({type: 'update-error', field: fieldFrom});
				editingDispatch({type: 'update-error', field: fieldTo});
			}
		},
		[editingDispatch, fieldFrom, fieldTo, required]
	);

	const formatValue = useCallback(
		(start?: Date | null, end?: Date | null) =>
			start && end
				? `${format(start, formatString)} - ${format(end, formatString)}`
				: t('tasksPage.dateTimeSelect.select'),
		[]
	);

	const dateFrom = useMemo(() => (valueFrom ? new Date(valueFrom) : undefined), [valueFrom]);

	const dateTo = useMemo(() => (valueTo ? new Date(valueTo) : undefined), [valueTo]);

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{fieldLabel}</EditableFieldLabel>

			<TranslatedDateTimeRangePicker
				trigger={props => (
					<TextFieldWithForwardedRef
						elementType="div"
						value={formatValue(props.valueFrom, props.valueTo)}
						icon={<i className="tz-calendar-20" />}
						error={
							required && (hasErrorFrom || hasErrorTo)
								? t('tasksPage.dateTimeSelect.error')
								: undefined
						}
						disabled={disabled}
						ref={props.ref}
						onClick={props.toggle}
					/>
				)}
				valueFrom={dateFrom}
				valueTo={dateTo}
				onChange={handleChange}
				disabledDate={handleDisabledDate}
				hidePastTimes={hidePastTimes}
			/>
		</div>
	);
};
