import {useCompaniesById, useMainCompaniesAsArray} from '@src/core/hooks/queries/companies/hooks';
import {IScheduleFieldProps} from '../types/IScheduleFieldProps';
import {CompanySelect as CompanySelectField} from '@/shared/ui/Form/CompanySelect/CompanySelect';
import {useController, useFormContext} from 'react-hook-form';
import {editableScheduleFields} from '../constants/editableScheduleFields';
import {IScheduleForm} from '../types/IScheduleForm';

export const CompanySelect = ({
	field,
	onSetValue,
	required,
	className,
	errorMessage = 'Выберите компанию'
}: IScheduleFieldProps) => {
	const {control} = useFormContext<IScheduleForm>();
	const {
		field: {value},
		fieldState: {invalid}
	} = useController({
		name: field,
		control,
		rules: {
			required
		}
	});
	const {data: companiesMap} = useCompaniesById();
	const {data: mainCompaniesArray} = useMainCompaniesAsArray();

	const handleChange = (v: string) => {
		onSetValue(editableScheduleFields.OBJECT_IDS, []);
		onSetValue(editableScheduleFields.PARTICIPANT_IDS, [], {shouldDirty: false});
		onSetValue(field, v);
	};

	return (
		<CompanySelectField
			className={className}
			companiesArray={mainCompaniesArray}
			required
			handleChange={handleChange}
			value={String(value)}
			companiesMap={companiesMap}
			errorMessage={invalid ? errorMessage : undefined}
		/>
	);
};
