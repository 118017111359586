import {memo} from 'react';
import {DateRangePicker, FilterButton} from '@tehzor/ui-components';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {makeDateFilterLabel} from '@src/components/EntitiesFilters/utils/makeFilterLabel';
import {useDateFnsLocale} from '@src/core/hooks/translations/useDateFnsLocales';
import {useTranslation} from 'react-i18next';

interface IAcceptancesIntervalFilterProps {
	label?: string;
	from?: Date;
	to?: Date;
	filterNameFrom: string;
	filterNameTo: string;
}

export const AcceptancesIntervalFilter = memo((props: IAcceptancesIntervalFilterProps) => {
	const {label = 'Срок устранения', from, to, filterNameFrom, filterNameTo} = props;
	const {t: translate} = useTranslation();
	const {dispatch} = useEntitiesFiltersCtx();

	const handleChange = (f: Date | null, t: Date | null) => {
		const intervalFrom = f ? new Date(f.getFullYear(), f.getMonth(), f.getDate()) : undefined;
		const intervalTo = t
			? new Date(t.getFullYear(), t.getMonth(), t.getDate(), 23, 59, 59, 999)
			: undefined;

		dispatch({[filterNameFrom]: intervalFrom, [filterNameTo]: intervalTo});
	};

	const handleClear = () => {
		dispatch({[filterNameFrom]: undefined, [filterNameTo]: undefined});
	};

	const dateFnsLocale = useDateFnsLocale();
	const datesOptions = {locale: dateFnsLocale};

	return (
		<DateRangePicker
			trigger={triggerProps => (
				<FilterButton
					className="entities-filters__item"
					label={makeDateFilterLabel(label, from, to)}
					active={from !== undefined || to !== undefined}
					onClick={triggerProps.toggle}
					onClear={handleClear}
					ref={triggerProps.ref}
				/>
			)}
			datesOptions={datesOptions}
			valueFrom={from}
			valueTo={to}
			dateFormat="dd MMMM yyyy"
			useApplyButton
			onChange={handleChange}
			selectApplyBtnLabel={translate('applyBtn.label')}
			selectCancelBtnLabel={translate('cancelBtn.label')}
		/>
	);
});
