import {TextField} from '@tehzor/ui-components';
import {useController, useFormContext} from 'react-hook-form';
import {FieldLabel} from '@/shared/ui/FieldLabel/FieldLabel';
import {ISimultaneousSlotsKeys} from '../types/IntervalFieldsKeys';
import {ErrorMessages} from '../constants/ErrorMessages';
import {IScheduleForm} from '../types/IScheduleForm';

interface ISimultaneousSlots {
	field: ISimultaneousSlotsKeys;
	required: boolean;
	className: string;
}

export const IntervalSimultaneousSlots = ({field, required, className}: ISimultaneousSlots) => {
	const {control} = useFormContext<IScheduleForm>();

	const {
		field: {value, onChange},
		fieldState: {invalid}
	} = useController({
		name: field,
		control,
		rules: {
			required,
			validate: v => Number(v) >= 0 && Number(v) <= 1000
		}
	});

	const handleChange = (v: string) => {
		const parsedValue = !isNaN(Number(v)) && v.trim() !== '' ? Number(v) : v;
		onChange(parsedValue);
	};

	return (
		<div className={className}>
			<FieldLabel
				label="Количество одновременных слотов"
				required={required}
			/>
			<TextField
				value={String(value)}
				elementType="input"
				onChange={handleChange}
				error={invalid ? ErrorMessages.requiredFieldSlots : undefined}
			/>
		</div>
	);
};
