import {ReactNode, useCallback, useState} from 'react';
import {IDurationMeasure, IDurationMeasures} from '@/shared/types/IDurationMeasure';
import {DurationSelectDialog} from '../DurationSelectDialog';

interface IUseDurationSelectDialog {
	duration: number;
	setDuration: (duration: number) => void;
	durationMeasure: IDurationMeasure;
	setDurationMeasure: (measure: IDurationMeasure) => void;
	dialogError: (duration: number, durationMeasure?: IDurationMeasure) => string | undefined;
	title?: string;
	durationMeasures: IDurationMeasures;
	disableDurationMeasure?: boolean;
}

export const useDurationSelectDialog = ({
	duration,
	setDuration,
	durationMeasure,
	setDurationMeasure,
	dialogError,
	title,
	durationMeasures,
	disableDurationMeasure = false
}: IUseDurationSelectDialog): [ReactNode, () => void] => {
	const [isOpen, setOpen] = useState<boolean>(false);

	const open = useCallback(() => {
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);
	const dialog = (
		<DurationSelectDialog
			onClose={close}
			isOpen={isOpen}
			duration={duration}
			setDuration={setDuration}
			durationMeasure={durationMeasure}
			setDurationMeasure={setDurationMeasure}
			dialogError={dialogError}
			title={title}
			durationMeasures={durationMeasures}
			disableDurationMeasure={disableDurationMeasure}
		/>
	);

	return [dialog, open];
};
