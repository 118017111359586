import {useController, useFormContext} from 'react-hook-form';
import {TextField} from '@tehzor/ui-components';
import {IScheduleFieldProps} from '../../types/IScheduleFieldProps';
import {FieldLabel} from '@/shared/ui/FieldLabel/FieldLabel';
import styles from './MaxOccupiedSlots.module.less';
import {IScheduleForm} from '../../types/IScheduleForm';

export const MaxOccupiedSlots = ({
	field,
	onSetValue,
	className,
	errorMessage = 'Введите значение от 1 до 96'
}: IScheduleFieldProps) => {
	const {control} = useFormContext<IScheduleForm>();

	const {
		field: {value},
		fieldState: {invalid}
	} = useController({
		name: field,
		control,
		rules: {
			validate: v => Number(v) >= 1 && Number(v) <= 96
		}
	});

	const handleChange = (v: string) => {
		const parsedValue = !isNaN(Number(v)) && v.trim() !== '' ? Number(v) : v;
		onSetValue(field, parsedValue);
	};

	return (
		<div className={className}>
			<FieldLabel
				label="Максимальное число занятых слотов в день"
				className={styles.fieldLabel}
			/>
			<TextField
				value={String(value)}
				elementType="input"
				onChange={handleChange}
				error={invalid ? errorMessage : undefined}
			/>
		</div>
	);
};
