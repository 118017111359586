import IObjectStage, {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {Select2, SelectOption, SelectPopup, TextFieldWithForwardedRef} from '@tehzor/ui-components';
import {CSSProperties} from 'react';
import {FieldLabel} from '../../FieldLabel/FieldLabel';

interface IObjectStageSelectProps<> {
	onChange: (v: string) => void;
	value?: ObjectStageIds;
	className?: string;
	style?: CSSProperties;
	label?: string;
	errorMessage?: string;
	types?: IObjectStage[];
	typesMap?: Record<string, IObjectStage>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
}

const getInputLabel = (
	value: ObjectStageIds | undefined,
	typesMap: Record<string, IObjectStage>
) => {
	if (!value) {
		return undefined;
	}

	if (typesMap[value]) {
		return typesMap[value].name;
	}

	return undefined;
};

export const ObjectStageSelect = ({
	className,
	style,
	label = 'Стадия объекта',
	errorMessage = 'Выберите стадию объекта',
	value,
	onChange,
	required,
	disabled,
	hasError,
	types,
	typesMap
}: IObjectStageSelectProps) => {
	const handleChange = (v: string) => {
		onChange(v);
	};

	return (
		<div
			className={className}
			style={style}
		>
			<FieldLabel
				label={label}
				required={required}
			/>
			{types && typesMap && (
				<SelectPopup
					noHeader
					trigger={
						<TextFieldWithForwardedRef
							elementType="div"
							value={getInputLabel(value, typesMap)}
							icon={<i className="tz-simple-arrow-20" />}
							error={required && hasError ? errorMessage : undefined}
							disabled={disabled}
						/>
					}
				>
					<Select2
						value={value}
						onChange={handleChange}
					>
						{types &&
							types.map(type => (
								<SelectOption
									key={type.id}
									itemKey={type.id}
									content={type.name}
									inputType="radio"
								/>
							))}
					</Select2>
				</SelectPopup>
			)}
		</div>
	);
};
