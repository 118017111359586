import {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {FullTextSearch} from '@tehzor/ui-components';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {IProblemsFiltersState} from '@src/store/modules/settings/pages/problems/interfaces';
import {extractProblemsPageSettings} from '@src/store/modules/settings/pages/problems/selectors';
import {problemsActions} from '@src/store/modules/settings/pages/problems/slice';
import {IEntitySettings} from '@src/core/hooks/settings/useEntitySettings';
import {ICustomFieldSetting, ValueTypeId} from '@tehzor/tools/interfaces/fields';
import {ProblemSearchField} from '@src/constants/filters/searchFields';

interface IProblemsFiltersProps {
	objectId?: string;
	settings?: IEntitySettings;
}

interface IProblemField {
	id: string;
	name: string;
	iconName: string;
	color?: string;
	backgroundColor?: string;
	isCustom?: boolean;
	inputType?: 'text' | 'number';
}

const problemFields: IProblemField[] = [
	{
		id: ProblemSearchField.Number,
		name: '№ нарушения',
		iconName: 'tz-number-20',
		inputType: 'number'
	},
	{
		id: ProblemSearchField.Description,
		name: 'Описание',
		iconName: 'tz-name-20'
	},
	{
		id: ProblemSearchField.Reason,
		name: 'Основание',
		iconName: 'tz-external-link-20'
	},
	{
		id: ProblemSearchField.Prescription,
		name: 'Предписание',
		iconName: 'tz-prescription-20'
	}
];

const getCustomFields = (
	custom: Record<string, ICustomFieldSetting> | undefined
): IProblemField[] => {
	if (!custom) {
		return [];
	}

	const textFields: IProblemField[] = [];
	Object.values(custom).forEach(customItem => {
		if (customItem.valueTypeId === ValueTypeId.TEXT) {
			textFields.push({
				id: customItem.fieldKey,
				name: customItem.fieldName,
				iconName: customItem.iconName || 'tz-custom-snowflake-20',
				isCustom: true,
				inputType: ValueTypeId.TEXT
			});
		}
	});

	return textFields;
};

export const ProblemsSearch = ({objectId = 'all', settings}: IProblemsFiltersProps) => {
	const {state, dispatch} = useEntitiesFiltersCtx<IProblemsFiltersState>();

	const appDispatch = useAppDispatch();
	const {filters, searchBy} = useAppSelector(s => extractProblemsPageSettings(s, objectId));
	const {changeSearchBy} = problemsActions;

	const isDesktop = useIsDesktop();
	const {t} = useTranslation();

	const customFields = useMemo(() => getCustomFields(settings?.custom), [settings?.custom]);

	const handleApplySearch = useCallback(
		(searchString: string, searchField: string) => {
			dispatch({searchString, searchField});
		},
		[dispatch]
	);

	const handleClearSearch = useCallback(
		(searchField: string | undefined) => {
			dispatch({searchString: undefined, searchField: undefined});
			if (searchField) {
				appDispatch(changeSearchBy({objectId, searchBy: searchField}));
			}
		},
		[dispatch, appDispatch, changeSearchBy, objectId]
	);

	const handleFieldChange = useCallback(
		(searchField: string) => {
			appDispatch(changeSearchBy({objectId, searchBy: searchField}));
		},
		[appDispatch, changeSearchBy, objectId]
	);

	return (
		<FullTextSearch
			isMobile={!isDesktop}
			placeholder={
				isDesktop
					? t('fullTextSearch.placeholder.desktop')
					: t('fullTextSearch.placeholder.mobile')
			}
			header={t('fullTextSearch.header')}
			fields={[...problemFields, ...customFields]}
			value={state.searchString}
			field={filters.searchField || searchBy}
			onSearch={handleApplySearch}
			onClear={handleClearSearch}
			onFieldChange={handleFieldChange}
		/>
	);
};
