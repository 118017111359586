import {CSSProperties, forwardRef, ReactNode, Ref} from 'react';
import ButtonBase from '../ButtonBase';
import './MobileFilterButton.less';
import classNames from 'classnames';
import {convertClassNames} from '../../../utils/convertClassNames';

export interface IMobileFilterButtonProps {
	className?:
		| string
		| {
				customDisabled?: string;
		  };
	style?: CSSProperties;
	label?: ReactNode;
	disabled?: boolean;
	active?: boolean;

	onClick: () => void;
}

const MobileFilterButton = (
	props: IMobileFilterButtonProps,
	ref?: Ref<HTMLDivElement | HTMLAnchorElement | HTMLButtonElement>
) => {
	const {className, style, label, disabled, onClick, active} = props;
	const classes = convertClassNames(className);

	return (
		<ButtonBase
			className={classNames(
				active
					? 'mobile-filter-button_active'
					: classNames(
							disabled && classes.customDisabled
								? classes.customDisabled
								: 'mobile-filter-button_inactive'
					  ),
				className
			)}
			style={style}
			label={label}
			rightIcon={
				active ? (
					<i className={classNames('tz-close-16', 'mobile-filter-button__close-icon')} />
				) : null
			}
			disabled={disabled}
			classNamePrefix="mobile-filter-button"
			outerTagType="button"
			onClick={onClick}
			ref={ref}
		/>
	);
};

MobileFilterButton.displayName = 'MobileFilterButton';

export default forwardRef(MobileFilterButton);
