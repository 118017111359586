import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useCallback} from 'react';
import {
	checkListsActions,
	IChecklistsFiltersState
} from '@src/store/modules/settings/pages/manage/checkLists/slice';
import {extractCheckListsPageFilters} from '@src/store/modules/settings/pages/manage/checkLists/selectors';

export const useCheckListsFilters = () => {
	const dispatch = useAppDispatch();
	const {setFilter, clearFilters} = checkListsActions;
	const filters = useAppSelector(extractCheckListsPageFilters);

	const applyFilters = useCallback(
		(value: IChecklistsFiltersState) => {
			dispatch(setFilter(value));
		},
		[dispatch, setFilter]
	);

	const onClear = useCallback(() => {
		dispatch(clearFilters());
	}, [clearFilters, dispatch]);

	return {filters, applyFilters, onClear};
};
