import EntitiesFilters, {ObjectsFilter, StatusesFilter} from '@src/components/EntitiesFilters';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {TranslatedSelectSearch} from '@src/components/TranslatedSelectSearch';
import {IChecklistsFiltersState} from '@src/store/modules/settings/pages/manage/checkLists/slice';
import {ProcessesFilter} from '@src/components/EntitiesFilters/components/ProcessesFilter';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useTranslation} from 'react-i18next';
import {useExtractWorkAcceptanceFrontTypesAsArray} from '@src/core/hooks/queries/workAcceptanceFrontTypes/hooks';
import {SpacesAndStructuresFilter} from '@src/components/EntitiesFilters/components/SpacesAndStructuresFilter';

export const CheckListsFilters = () => {
	const {t} = useTranslation();
	const {state, dispatch} = useEntitiesFiltersCtx<IChecklistsFiltersState>();
	const {frontTypes, processes, objects, spacesAndStructures, name} = state;

	const {data: allFrontTypes} = useExtractWorkAcceptanceFrontTypesAsArray();
	const showFrontTypes = processes?.includes('acceptance-control');

	const handleSearchClear = () => {
		dispatch({name: undefined});
	};

	const handleChange = (val: string) => {
		dispatch({name: val});
	};

	return (
		<EntitiesFilters
			entity="manage/check-lists"
			desktopOnly
		>
			<TranslatedSelectSearch
				className="manage-working-groups-page__filters-search"
				type="filter"
				value={name}
				onSearch={handleChange}
				onClear={handleSearchClear}
				placeholder={t('tasksPage.filters.tasksSearch.placeholder')}
			/>

			<ProcessesFilter
				placeholder="fullTextSearch.placeholder.desktop"
				value={processes}
			/>

			{allFrontTypes && showFrontTypes && (
				<StatusesFilter
					label={t('entitiesFilters.statusesFilter.workAcceptanceFrontTypes.label')}
					filterName="frontTypes"
					statuses={frontTypes}
					allStatuses={allFrontTypes}
					translationDictionaryKey={dictionaryKeys.workAcceptanceFrontTypes}
				/>
			)}

			<ObjectsFilter
				value={objects}
				placeholder="fullTextSearch.placeholder.desktop"
			/>

			<SpacesAndStructuresFilter value={spacesAndStructures} />
		</EntitiesFilters>
	);
};
