import {ISchedule} from '@tehzor/tools/interfaces/schedules/ISchedule';
import {LimitedItemsList} from '@/shared/ui/LimitedItemsList/LimitedItemsList';
import {useObjectsAsArrayByIds} from '@src/core/hooks/queries/objects/hooks';
import {CellProps} from 'react-table';

export const ObjectsCell = ({row}: CellProps<ISchedule>) => {
	const {objectIds} = row.original;
	const objects = useObjectsAsArrayByIds(objectIds);
	return objects ? (
		<LimitedItemsList
			items={objects.map(object => object.name)}
			maxVisibleItems={3}
		/>
	) : null;
};
