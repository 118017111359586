import useAppSelector from '@src/core/hooks/useAppSelector';
import {useCallback, useMemo, useState} from 'react';
import {EntitiesTable, LoadingPanel, Plate} from '@tehzor/ui-components';
import {convertChecklists} from '../../utils/convertCheckLists';
import {IPreparedCheckList} from '../../interfaces/IPreparedCheckList';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {extractCheckListsPageSettings} from '@src/store/modules/settings/pages/manage/checkLists/selectors';
import {useDesktopColumns} from './columns.desktop';
import {MenuActionCtxProvider} from '../DispatchActionCtx';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useFilteredCheckListsAsArray} from '@src/core/hooks/queries/checkLists/hooks';
import {useQueryClient} from '@tanstack/react-query';
import {checkItemsQueryKeys} from '@src/api/cache/checkItems/keys';
import {checkListsActions} from '@src/store/modules/settings/pages/manage/checkLists/slice';

interface ITableProps {
	loading?: boolean;
}

export const Table = ({loading}: ITableProps) => {
	const {pushPath} = useChangePath();
	const queryClient = useQueryClient();
	const pageSettings = useAppSelector(extractCheckListsPageSettings);
	const {data, refetch: refetchCheckLists} = useFilteredCheckListsAsArray(pageSettings);

	const updateButtonLoading = useAppSelector(s => s.app.updateData.status);
	const dispatch = useAppDispatch();
	const translatedDesktopColumns = useDesktopColumns();
	const {changeSort} = checkListsActions;

	const loadCheckLists = async () => {
		await refetchCheckLists();
		await queryClient.refetchQueries({queryKey: checkItemsQueryKeys.list()});
	};

	const handleSortChange = useCallback((value: {[key: string]: boolean}) => {
		dispatch(changeSort({sort: value}));
		void loadCheckLists();
	}, []);

	const preparedData = useMemo(() => convertChecklists(data), [data]);
	const [selectedRows, setSelectedRows] = useState<string[]>([]);

	const handleRowClick = useCallback((item: IPreparedCheckList) => {
		pushPath(`/manage/check-lists/${item.id}`);
	}, []);

	return (
		<Plate withoutPadding>
			<MenuActionCtxProvider reloadList={loadCheckLists}>
				<LoadingPanel active={loading || updateButtonLoading}>
					{preparedData && (
						<EntitiesTable
							columns={translatedDesktopColumns}
							data={preparedData}
							selectedRows={selectedRows}
							sort={pageSettings.sort}
							selectable
							hideLastHeaderCell
							onRowClick={handleRowClick}
							onSelectedRowsChange={setSelectedRows}
							onSortChange={handleSortChange}
						/>
					)}
				</LoadingPanel>
			</MenuActionCtxProvider>
		</Plate>
	);
};
