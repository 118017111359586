import classNames from 'classnames';
import styles from './DeadlineIntervalMobile.module.less';

interface IDeadlineIntervalMobile {
	className?: {
		container?: string;
		chainOuterItem?: string;
		chainInnerItem?: string;
		item?: string;
		label?: string;
	};
	dateStart?: string;
	dateEnd?: string;
	label?: string;
}

export const DeadlineIntervalMobile = ({
	className,
	dateStart,
	dateEnd,
	label
}: IDeadlineIntervalMobile) => (
	<>
		{label ? <p className={classNames(styles.label, className?.label)}>{label}</p> : null}
		<div className={classNames(styles.container, className?.container)}>
			<div className={styles.wrapper}>
				<span className={styles.chainOuterItem} />
				<span className={classNames(styles.chainInnerItem, className?.chainInnerItem)} />
				<span className={classNames(styles.chainInnerItem, className?.chainInnerItem)} />
				<span className={classNames(styles.chainInnerItem, className?.chainInnerItem)} />
				<span className={classNames(styles.chainOuterItem, className?.chainOuterItem)} />
			</div>

			<div className={styles.itemsContainer}>
				<span className={classNames(styles.item, className?.item)}>{dateStart}</span>
				<span className={classNames(styles.item, className?.item)}>{dateEnd}</span>
			</div>
		</div>
	</>
);
