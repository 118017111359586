import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {SpaceTypeId} from '@tehzor/tools/interfaces/spaces/ISpaceType';

export interface IChecklistsFiltersState extends Record<string, unknown> {
	spaceTypes?: SpaceTypeId[];
	name?: string;
	objects?: string[];
	stages?: string[];
	processes?: string[];
	frontTypes?: string[];
	spacesAndStructures?: Array<string | SpaceTypeId>;
}
export interface ICheckListsPageSettingsState {
	filters: IChecklistsFiltersState;
	sort: Record<string, boolean>;
}

const initialState: ICheckListsPageSettingsState = {
	filters: {},
	sort: {}
};

const checkListsSlice = createSlice({
	name: 'checkLists',
	initialState,
	reducers: {
		setFilter(state, action: PayloadAction<IChecklistsFiltersState>) {
			state.filters = action.payload;
		},
		clearFilters(state) {
			state.filters = {};
		},
		changeSort: (state, action: PayloadAction<{sort: Record<string, boolean>}>) => {
			state.sort = action.payload.sort;
		}
	}
});

export const {actions: checkListsActions, reducer: checkListsReducer} = checkListsSlice;
