import {EntityGridItem} from '@tehzor/ui-components';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import {useTranslation} from 'react-i18next';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useTranslatedDictionaryById} from '@src/core/hooks/translations/useTranslatedDictionaryById';

interface IDescriptionProps {
	className?: string;
	task: IEnrichedTask;
}

export const TaskType = ({className, task}: IDescriptionProps) => {
	const {t} = useTranslation();
	const taskName = useTranslatedDictionaryById(dictionaryKeys.taskTypes, task.taskType?.id);
	return (
		<EntityGridItem
			className={className}
			label={t('checkListPage.detail.taskInfoMobileCell.taskType.label')}
			inline
		>
			<div>{taskName}</div>
		</EntityGridItem>
	);
};
