import {PrivateRoute} from '@src/containers/PrivateRoute';
import {ScheduledPlansPage} from '@/pages/ScheduledPlansPage';

export const objectScheduledPlansRoutes = [
	{index: true, element: <PrivateRoute element={<ScheduledPlansPage />} />},
	{
		path: ':scheduledPlanId',
		element: <PrivateRoute element={<div>'one plan page'</div>} />
	}
];
