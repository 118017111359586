import {get} from 'lodash';
import {timeStrToMinutes} from './timeStrToMinutes';
import {FormState} from 'react-hook-form';
import {IScheduleForm} from '../../types/IScheduleForm';

interface IGetTimeRangeValidationState {
	timeRangeFrom: string;
	timeRangeTo: string;
	slotDuration?: number;
	formState: FormState<IScheduleForm>;
	index: number;
	focusStatus: {timeRangeFrom: boolean; timeRangeTo: boolean};
}

export const getTimeRangeValidationState = ({
	timeRangeFrom,
	timeRangeTo,
	slotDuration,
	formState,
	index,
	focusStatus
}: IGetTimeRangeValidationState) => {
	const timeFromMinutesValue = timeStrToMinutes(timeRangeFrom);
	const timeToMinutesValue = timeStrToMinutes(timeRangeTo);
	const hasSlotDuration = !!slotDuration;

	const isTimeRangeLessThanSlot =
		hasSlotDuration && timeToMinutesValue - timeFromMinutesValue < slotDuration;

	const isTimeInputsFull = timeRangeFrom.length > 2 && timeRangeTo.length > 2;

	const isTimeInputsValid =
		!get(formState.errors, `intervals.${index}.timeRangeFrom`) &&
		!get(formState.errors, `intervals.${index}.timeRangeTo`);

	const isStartBeforeEnd = timeFromMinutesValue > timeToMinutesValue;

	// TODO Подумать над переделкой логики связанной с фокусом
	const isFocusOnFrom = focusStatus.timeRangeFrom;
	const isFocusOnTo = focusStatus.timeRangeTo;
	const isNoFocus = !isFocusOnFrom && !isFocusOnTo;
	const canShowError = isTimeInputsValid && isTimeInputsFull;

	const errorRangeFrom =
		canShowError && isStartBeforeEnd && (isFocusOnFrom || isNoFocus) ? '' : undefined;

	const errorRangeTo =
		canShowError && isStartBeforeEnd && (isFocusOnTo || isNoFocus) ? '' : undefined;

	const errorSlotsDurationFrom =
		canShowError && isTimeRangeLessThanSlot && (isFocusOnFrom || isNoFocus) ? '' : undefined;

	const errorSlotsDurationTo =
		canShowError && isTimeRangeLessThanSlot && (isFocusOnTo || isNoFocus) ? '' : undefined;

	return {
		timeFromMinutesValue,
		timeToMinutesValue,
		isTimeInputsFull,
		isTimeInputsValid,
		isFocusOnFrom,
		isFocusOnTo,
		isNoFocus,
		canShowError,
		isTimeRangeLessThanSlot,
		isStartBeforeEnd,
		errorSlotsDurationFrom,
		errorSlotsDurationTo,
		errorRangeFrom,
		errorRangeTo
	};
};
