import classNames from 'classnames';
import styles from './DateIntervalChange.module.less';

interface IDateIntervalChange {
	className?: string;
	value: {
		from?: string;
		to?: string;
	};
}

export const DateIntervalChange = ({value, className}: IDateIntervalChange) => (
	<div className={classNames(styles.container, className)}>
		{value.from && value.to && (
			<div className={styles.wrapper}>
				<span className={styles.chainOuterItem} />
				<span className={styles.chainInnerItem} />
				<span className={styles.chainInnerItem} />
				<span className={styles.chainInnerItem} />
				<span className={styles.chainOuterItem} />
			</div>
		)}

		<div className={styles.itemsContainer}>
			<span className={styles.item}>{value.from}</span>
			<span className={styles.item}>{value.to}</span>
		</div>
	</div>
);
