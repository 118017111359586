import {IGetSchedulesResponse} from '@/shared/api/schedules/getSchedules';
import {useQuery} from '@tanstack/react-query';
import {schedulesQueryKeys} from '../../keys/index';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSchedulesPageSettings} from '../../../slice/selector';

export const useSchedulesPaginateList = <T = IGetSchedulesResponse>(
	select?: (data: IGetSchedulesResponse) => T
) => {
	const {filters, sort, offset, pageSize} = useAppSelector(s => extractSchedulesPageSettings(s));

	return useQuery({
		queryKey: [...schedulesQueryKeys.paginate(), filters, sort, offset, pageSize],
		select,
		meta: {
			error: 'при загрузке расписаний'
		}
	});
};
