import './FilterMenu.less';
import {IMenuComponentProps, IMenuItemProps} from '../MenuItem';
import classNames from 'classnames';
import {convertClassNames} from '../../../utils/convertClassNames';
import {IPopupBasicProps} from '../../containers/Popup';
import MenuLayer from '../MenuLayer';
import {FilterButton} from '../../buttons';
import {CSSProperties, ReactElement, ReactNode} from 'react';
import {IPopupMenuTriggerFnProps} from '../PopupMenu';
import {IDialogMenuTriggerFnProps} from '../DialogMenu';

interface IFilterMenuProps<V, M extends IMenuComponentProps<V>> {
	className?:
		| string
		| {
				root?: string;
				button?: string;
				wrap?: string;
				label?: string;
				icon?: string;
				buttonOpen?: string;
		  };
	style?: CSSProperties;
	children?: ReactElement<M> | Array<ReactElement<IMenuItemProps>>;
	label?: ReactNode;
	active?: boolean;
	disabled?: boolean;
	popupArrowVisible?: boolean;
	popupProps?: IPopupBasicProps;
	isCustomFilterButton?: boolean;
}

const FilterMenu = <V, M extends IMenuComponentProps<V>>(props: IFilterMenuProps<V, M>) => {
	const {
		className,
		style,
		children,
		label,
		active,
		disabled,
		popupArrowVisible,
		popupProps,
		isCustomFilterButton
	} = props;

	const classes = convertClassNames(className);

	const trigger = ({
		isOpen,
		toggle,
		ref,
		disabled: triggerDisabled
	}: IDialogMenuTriggerFnProps | IPopupMenuTriggerFnProps) => {
		const btnIcon = classNames(
			'filter-menu__button-icon',
			{'filter-menu__button-icon_open': isOpen},
			classes.icon
		);
		// TODO Прибрать классы и флаг isCustomFilterButton
		const buttonClasses = isCustomFilterButton
			? {
					root: classNames(classes.root),
					button: classNames(classes.button, isOpen && classes.buttonOpen),
					bntWrap: classes.wrap,
					btnLabel: classNames(classes.label),
					btnIcon
			  }
			: {
					root: classNames('filter-menu', classes.root),
					button: classNames('filter-menu__button', classes.button),
					bntWrap: classes.wrap,
					btnLabel: classNames('filter-menu__button-label', classes.label),
					btnIcon
			  };

		return (
			<FilterButton
				className={buttonClasses}
				style={style}
				label={label}
				icon={!disabled && <i className="tz-simple-arrow-16" />}
				active={active || isOpen}
				disabled={disabled || triggerDisabled || !children}
				onClick={toggle}
				ref={ref}
			/>
		);
	};

	return (
		<MenuLayer
			trigger={trigger}
			popupArrowVisible={popupArrowVisible}
			popupProps={{placement: 'bottom-start', preferX: 'left', ...popupProps}}
		>
			{children}
		</MenuLayer>
	);
};

FilterMenu.displayName = 'FilterMenu';

export default FilterMenu;
