import {CSSProperties, ReactNode} from 'react';
import './SidebarMenu.less';
import classNames from 'classnames';

interface ISidebarMenuProps {
	className?: string;
	style?: CSSProperties;
	children?: ReactNode;
}

const SidebarMenu = (props: ISidebarMenuProps) => {
	const {className, style, children} = props;

	return (
		<div className={classNames('sidebar-menu', className)} style={style}>
			{children}
		</div>
	);
};

export default SidebarMenu;
