import {TabLink, Tabs, TextFieldWithForwardedRef} from '@tehzor/ui-components';
import {TranslatedSelectPopup} from '@src/components/TranslatedSelectPopup';
import {TranslatedSelectSearch} from '@src/components/TranslatedSelectSearch';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {debounce} from 'lodash';
import {useUsersAsMap} from '@src/core/hooks/queries/users/hooks';
import {useController, useFormContext} from 'react-hook-form';
import {editableScheduleFields} from '../constants/editableScheduleFields';
import {useWorkingGroupsByCompany} from '@src/core/hooks/queries/workingGroups/hooks';
import {WorkingGroupsTabContent} from '../ui/WorkingGroupsTabContent/WorkingGroupsTabContent';
import {UsersTabContent} from '../ui/UsersTabContent';
import {IScheduleFieldProps} from '../types/IScheduleFieldProps';
import {FieldLabel} from '@/shared/ui/FieldLabel/FieldLabel';
import {getSelectionTitle} from '@/shared/utils/getSelectionTitle';
import {useCompanyWithContractorsUsersAsArray} from '@src/core/hooks/queries/companies/hooks';
import {IScheduleForm} from '../types/IScheduleForm';

const userIcon = <i className="tz-user-24" />;

export const ParticipantsSelect = ({
	field,
	onSetValue,
	required,
	className,
	errorMessage = 'Обязательно должны быть выбраны участники'
}: IScheduleFieldProps) => {
	const {control, watch} = useFormContext<IScheduleForm>();
	const {
		field: {value},
		fieldState: {invalid, error}
	} = useController({
		name: field,
		control,
		rules: {
			required
		}
	});
	const companyId = watch(editableScheduleFields.COMPANY_ID) as string;
	const {data: workingGroups} = useWorkingGroupsByCompany(companyId);
	const {data: usersMap} = useUsersAsMap();
	const {data: users} = useCompanyWithContractorsUsersAsArray(usersMap, companyId);

	const [selectedUsers, setSelectedUsers] = useState<string[]>(value as string[]);
	const [selectedGroup, setSelectedGroup] = useState<string | undefined>();
	const [tabIndex, setTabIndex] = useState(0);
	const links = [<TabLink label="По пользователю" />, <TabLink label="По рабочим группам" />];

	const [search, setSearch] = useState('');

	// необходимо для установки начальных значений после получения с бэка
	useEffect(() => {
		if (value !== undefined) {
			setSelectedUsers(value as string[]);
		}
	}, [value]);

	const changeSearch = useMemo(() => debounce(setSearch, 300), []);
	// Отмена отложенного вызова при размонтировании
	useEffect(
		() => () => {
			changeSearch.cancel();
		},
		[changeSearch]
	);

	const clearSearch = useCallback(() => setSearch(''), []);

	const handleApply = useCallback(() => {
		onSetValue(field, selectedUsers);
		onSetValue(editableScheduleFields.WORKING_GROUP_ID, selectedGroup);
		onSetValue(editableScheduleFields.SIMULTANEOUS_SLOTS_COUNT, selectedUsers.length);
		clearSearch();
	}, [clearSearch, field, selectedUsers, onSetValue, selectedGroup]);

	const handleSelectGroup = (v?: string) => setSelectedGroup(v);

	const handleSelectUsers = (v: string[]) => {
		setSelectedUsers(v);
	};

	const handleSelectUsersResetGroup = (v?: string[]) => {
		setSelectedUsers(v ?? []);
		setSelectedGroup(undefined);
	};
	const handleClear = () => {
		setSelectedUsers([]);
		setSelectedGroup(undefined);
		clearSearch();
	};

	const handleClearButton = () => {
		setSelectedUsers([]);
		setSelectedGroup(undefined);
		onSetValue(field, []);
		onSetValue(editableScheduleFields.WORKING_GROUP_ID, undefined);
		clearSearch();
	};

	const handleCancel = () => {
		setSelectedUsers(value as string[]);
		clearSearch();
	};

	const handleChangeTab = (index: number) => {
		clearSearch();
		setTabIndex(index);
	};

	const errorText = error?.message || errorMessage;

	return (
		<div className={className}>
			<FieldLabel
				label="Участники"
				required={required}
			/>
			<TranslatedSelectPopup
				className="entities-filters-with-tabs"
				onCancel={handleCancel}
				onApply={handleApply}
				onClear={handleClear}
				clearButton={!!selectedUsers?.length || !!selectedGroup}
				count={selectedUsers?.length}
				footer
				tabs={
					<Tabs
						links={links}
						activeTab={tabIndex}
						onActiveTabChange={handleChangeTab}
					/>
				}
				trigger={
					<TextFieldWithForwardedRef
						elementType="div"
						value={getSelectionTitle(usersMap, selectedUsers, 'fullName')}
						cleanable={!!selectedUsers.length}
						onClearClick={handleClearButton}
						icon={userIcon}
						error={invalid ? errorText : undefined}
					/>
				}
				search={
					<TranslatedSelectSearch
						value={search}
						onChange={changeSearch}
					/>
				}
			>
				<>
					{tabIndex === 0 ? (
						<UsersTabContent
							tabIndex={tabIndex}
							users={users}
							search={search}
							selectedUsers={selectedUsers}
							handleSelectUsersResetGroup={handleSelectUsersResetGroup}
						/>
					) : null}
					{tabIndex === 1 ? (
						<WorkingGroupsTabContent
							tabIndex={tabIndex}
							selectedUsers={selectedUsers}
							workingGroups={workingGroups}
							selectedGroup={selectedGroup}
							handleSelectGroup={handleSelectGroup}
							handleSelectUsers={handleSelectUsers}
							search={search}
							usersMap={usersMap}
						/>
					) : null}
				</>
			</TranslatedSelectPopup>
		</div>
	);
};
