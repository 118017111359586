import {SortingRule} from 'react-table';

export function convertSortToInternal(sort: Record<string, boolean>): Array<SortingRule<string>> {
	const result = [];
	for (const key in sort) {
		if (sort.hasOwnProperty(key)) {
			result.push({
				id: key,
				desc: !sort[key]
			});
		}
	}
	return result;
}
