import {httpRequests} from '@tehzor/tools/api/httpRequests';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';

export interface IGetPaginateEmployeesResponse extends INormalizedData<IBriefUser> {
	offset: number;
	limit: number;
	total: number;
}

/**
 * Отправляет запрос на получение списка пользователей
 */
export const requestPaginateEmployees = async (offset?: number, limit?: number) => {
	const params = {
		offset,
		limit
	};
	const response = await httpRequests.withToken.post<IGetPaginateEmployeesResponse>(
		'users/get-employees-paginate',
		params
	);
	return response.data;
};
