import {ISchedulesFiltersState, ISchedulesSortState} from '../../slice/interfaces';

export type ISchedulesPaginateQueryKey = [] & {
	0: string; // schedules
	1: string; // list
	2: string; // paginate
	3: ISchedulesFiltersState;
	4: ISchedulesSortState;
	5?: number; // offset
	6?: number; // limit
};

export type IScheduleQueryKey = [] & {
	0: string; // schedule
	1: string; // one
	2: string; // details
	3: string; // scheduleId
};

export const schedulesQueryKeys = {
	all: () => ['schedules'],
	one: () => ['schedule'],
	list: () => [...schedulesQueryKeys.all(), 'list'],
	paginate: () => [...schedulesQueryKeys.list(), 'paginate'],
	details: () => [...schedulesQueryKeys.one(), 'details'],
	detail: (scheduleId?: string) => [...schedulesQueryKeys.one(), 'details', scheduleId],
	add: () => [...schedulesQueryKeys.one(), 'add'],
	edit: () => [...schedulesQueryKeys.one(), 'edit']
};
