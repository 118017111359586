import {IDurationMeasure, durationMeasureKeys} from '@/shared/types/IDurationMeasure';
import {useMemo} from 'react';

interface IUseDurationMeasures {
	availableOptions: durationMeasureKeys[] | 'all';
}
export const useDurationMeasures = ({availableOptions}: IUseDurationMeasures) =>
	useMemo(() => {
		const allMeasures: Record<durationMeasureKeys, IDurationMeasure> = {
			[durationMeasureKeys.MINUTE]: {value: 'мин.', key: durationMeasureKeys.MINUTE},
			[durationMeasureKeys.HOUR]: {value: 'ч.', key: durationMeasureKeys.HOUR},
			[durationMeasureKeys.DAY]: {value: 'д.', key: durationMeasureKeys.DAY}
		};

		if (availableOptions === 'all') {
			return allMeasures;
		}
		return availableOptions.reduce<Record<durationMeasureKeys, IDurationMeasure>>(
			(acc, key) => {
				if (allMeasures[key]) {
					acc[key] = allMeasures[key];
				}
				return acc;
			},
			{} as Record<durationMeasureKeys, IDurationMeasure>
		);
	}, [availableOptions]);
