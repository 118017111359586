import {createContext, useContext} from 'react';

export interface TreeCtxType {
	isChildSelected: (childId: string) => boolean;
	isParentSelected: (parentId: string) => boolean;
	isParentExpanded: (parentId: string) => boolean;
	changeChildSelected: (childId: string, parentId: string | undefined, value: boolean) => void;
	changeParentSelected: (parentId: string, value: boolean) => void;
	changeParentExpanded: (parentId: string, value: boolean) => void;
}

export const TreeCtx = createContext<TreeCtxType>({
	isChildSelected: () => false,
	isParentSelected: () => false,
	isParentExpanded: () => false,
	changeChildSelected: () => null,
	changeParentSelected: () => null,
	changeParentExpanded: () => null
});

export const useTreeCtx = () => useContext(TreeCtx);
