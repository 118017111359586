import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractUserRoles} from '@src/store/modules/auth/profile/selectors';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import {useMemo} from 'react';

interface ISchedulesPermissions {
	canViewSchedules?: boolean;
	canDeleteSchedules?: boolean;
	canEditSchedules?: boolean;
	canAddSchedules?: boolean;
}
export const useSchedulesPermissions = (): ISchedulesPermissions => {
	const roles = useAppSelector(extractUserRoles);

	return useMemo(() => {
		if (!roles.length) {
			return {};
		}

		return {
			canViewSchedules: hasPermission(roles, 'schedules-view'),
			canDeleteSchedules: hasPermission(roles, 'schedules-delete'),
			canEditSchedules: hasPermission(roles, 'schedules-edit'),
			canAddSchedules: hasPermission(roles, 'schedules-add')
		};
	}, [roles]);
};
