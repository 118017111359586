import {dateFormat} from '@tehzor/tools/utils/dateFormats';
import {DatePicker, TextFieldWithForwardedRef} from '@tehzor/ui-components';
import {IDatePickerTriggerProps} from '@tehzor/ui-components/src/components/dates/DatePicker/DatePicker';
import {format} from 'date-fns';
import {useDateFnsLocale} from '@src/core/hooks/translations/useDateFnsLocales';
import {useController, useFormContext, useWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {IDateRangeKeys} from '../types/IntervalFieldsKeys';
import {editableScheduleFields} from '../constants/editableScheduleFields';
import {IScheduleForm} from '../types/IScheduleForm';

interface IDateRangeFrom {
	field: IDateRangeKeys;
	className?: string;
	generalDateTo: Date | undefined;
}

export const IntervalDateRangeFrom = ({field, className, generalDateTo}: IDateRangeFrom) => {
	const {t} = useTranslation();
	const dateFnsLocale = useDateFnsLocale();
	const datesOptions = {locale: dateFnsLocale};
	const {control, setValue} = useFormContext<IScheduleForm>();
	const {
		field: {value}
	} = useController({
		name: field,
		control
	});

	const generalDateFrom = useWatch({
		control,
		name: editableScheduleFields.DATE_RANGE_FROM
	});

	const handleChange = (v: Date) => {
		setValue(field, v);
	};

	const disabledDate = (date: Date) =>
		(generalDateFrom && date < generalDateFrom) || (!!generalDateTo && date > generalDateTo);

	const getTrigger = 
		(triggerProps: IDatePickerTriggerProps) => (
			<TextFieldWithForwardedRef
				elementType="div"
				className={className}
				value={
					triggerProps?.value !== undefined ? format(triggerProps.value, dateFormat) : ''
				}
				onClick={triggerProps?.toggle}
				ref={triggerProps?.ref}
			/>
		);

	return (
		<DatePicker
			value={value}
			datesOptions={datesOptions}
			onChange={handleChange}
			disabledDate={disabledDate}
			trigger={getTrigger}
			selectCancelBtnLabel={t('cancelBtn.label')}
			selectApplyBtnLabel={t('applyBtn.label')}
			useApplyButton
		/>
	);
};
