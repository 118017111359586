import {CircleLoader, IconButton} from '@tehzor/ui-components';
import styles from './LoadingIndicator.module.less';
import {ListLoader} from '@tehzor/ui-components/src/components/loaders/skeletons';

const reloadIcon = <i className="tz-reload-20" />;

interface ILoadingIndicatorProps {
	text: string;
	isFetching: boolean;
	totalEntities?: number;
	onClick: () => void;
}

export const LoadingIndicator = ({
	text,
	isFetching,
	totalEntities,
	onClick
}: ILoadingIndicatorProps) => (
	<div className={styles.indicator}>
		<span className={styles.title}>{text}</span>
		{!isFetching ? (
			<>
				<span className={styles.title}>{totalEntities}</span>
				<IconButton
					type="inline-blue-accent"
					onClick={onClick}
				>
					{reloadIcon}
				</IconButton>
			</>
		) : (
			<>
				<ListLoader />
				<div className={styles.loaderContainer}>
					<CircleLoader className={styles.circleLoader} />
				</div>
			</>
		)}
	</div>
);
